import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { Autocomplete, Checkbox, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Table, TablePagination, TextField, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getAmountListForManualAPIURL, getEmployeeListForManualAPIURL, getEndowmentIdAPIURL, getPrizeDetailsData, getSchoolStudentListForManualAPIURL, getStudentApprovalAllocationData, getStudentListForAutomaticAPIURL, getStudentListForManualAPIURL, getStudentListForSantshaPrizeAllocForAutomaticAPIURL, saveSansthaStudentListForManualAPIURL, saveStudentListForManualAPIURL } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faBullseye, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConditionDetailsTable from './ConditionDetailsTable';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AddStudentSrNoTextBox from './AddStudentSrNoTextBox';
import { toast } from 'react-toastify';
import { GetYearAPIURL } from '../../../CommonServices/LoginAPIURL';
import AddStudentAmountTextBox from './AddStudentAmountTextBox';
import { ClassMasterGetMapping } from '../../../CommonServices/ClassMasterAPIURL';
import { getSubjectData } from '../../../Exam/Services/ExamReportAPIURL';
import { getClassesBySectionAndBranch } from '../../../Exam/Services/ExamMarkAllocationAPIURL';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const AddStudentConditionTransferList = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);
    const { prId, etran, prFor, ssForm } = useParams();

    const titleId = (prFor == 1) ? "Add Students" : "Add Employee"

    const location = useLocation();

    // Access paritoshikName from location state
    const prName = location.state?.paritoshikName;
    const praizeId = location.state?.pId
    const yName = location.state?.yojanaInfo;

    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    // console.log(JSON.stringify(data));
    const [option, setOption] = useState(1);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [date, setDate] = useState(new Date().setFullYear(new Date().getFullYear() - 1));
    const [checkValue, setCheckValue] = useState([]);
    const [Val, setVal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [chkRegNum, setChkRegNum] = useState('');
    const [srNo, setSrNo] = useState('');

    const [tempData, setTempData] = useState([]);
    const [year, setYear] = useState([]);
    const [yearIndex, setYearIndex] = useState("");
    const [yearLabel, setYearLabel] = useState("");
    const [amtData, setAmtData] = useState([]);
    const [values, setValues] = useState('');
    const [newFilterData, setNewFilterData] = useState([]);
    const [automaticData, setAutomaticData] = useState([]);
    const [automaticFilterData, setAutomaticFilterData] = useState([]);
    const [automaticNewFilterData, setAutomaticNewFilterData] = useState([]);
    const [tempAutomaticData, setTempAutomaticData] = useState([]);
    const [amount, setAmount] = useState('');

    const [regNum, setRegNum] = useState('');
    const [updatedSrNo, setUpdatedSrNo] = useState(null);
    const [updatedAmount, setUpdatedAmount] = useState(null);
    const [updatedIsAllocated, setUpdatedIsAllocated] = useState(0);
    const [updatedObtainedMarks, setUpdatedObtainedMarks] = useState(null);

    const [autoRegNum, setAutoRegNum] = useState('');
    const [updatedAutoSrNo, setUpdatedAutoSrNo] = useState(null);
    const [updatedAutoAmount, setUpdatedAutoAmount] = useState(null);
    const [updatedAutoIsAllocated, setUpdatedAutoIsAllocated] = useState(0);

    const [srNoVal, setSrNoVal] = useState(0)

    const [endowId, setEndowId] = useState('');
    // console.log(endowId)

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [leftFilteredData, setLeftFilteredData] = useState([]);
    const [leftSearchTerm, setLeftSearchTerm] = useState([]);
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    const [rightSearchTerm, setRightSearchTerm] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const [autochecked, setAutoChecked] = useState([]);
    const [autoLeft, setAutoLeft] = useState([]);
    const [autoLeftFilteredData, setAutoLeftFilteredData] = useState([]);
    const [autoLeftSearchTerm, setAutoLeftSearchTerm] = useState([]);
    const [autoRight, setAutoRight] = useState([]);
    const [autoRightFilteredData, setAutoRightFilteredData] = useState([]);
    const [autoRightSearchTerm, setAutoRightSearchTerm] = useState([]);

    const autoLeftChecked = intersection(autochecked, autoLeft);
    const autoRightChecked = intersection(autochecked, autoRight);

    useEffect(() => {
        const updatedData = data.map(item => ({
            ...item,
            // Set amount from amtData only if it has not been manually set
            amount: amtData[item.srNo] !== undefined && item.amount === 0 ? amtData[item.srNo] : item.amount,
        }));
        setFilteredData(updatedData);
        setData(updatedData);

        const updatedAutoData = automaticData.map(item => ({
            ...item,
            // Set amount from amtData only if it has not been manually set
            amount: amtData[item.srNo] !== undefined && item.amount === 0 ? amtData[item.srNo] : item.amount,
        }));
        setAutomaticFilterData(updatedAutoData);
        setAutomaticData(updatedAutoData);
    }, []);

    // console.log(JSON.stringify(newFilterData))

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `योजनेची माहिती`, key: 'approvalStatus' },
        { label: `Action`, key: 'action', isAction: true },
    ];

    // useEffect(() => {
    //     if (searchTerm == "") {
    //         console.log("dat=======" + JSON.stringify(tempData))
    //         setFilteredData([...tempData]);
    //     }
    // }, [searchTerm, tempData]);

    useEffect(() => {
        getData();
    }, [authUser, prFor, option, ssForm])

    const getData = async () => {
        setLoaderOption(true);
        if (authUser.sessionYear != "" && authUser.branchId != null && authUser.deptId != null) {
            console.log(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setAmtData(res.data);
                    // setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })

            await axios(`${getEndowmentIdAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setEndowId(res.data);
                });
        }


        if (prFor == 1) {
            if (option == 1) {
                if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {

                    if (ssForm == 722) {
                        console.log(`${getSchoolStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)

                        await axios.get(`${getSchoolStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then(res => {
                                // Separate the data based on the `isAllocated` field
                                const leftList = res.data.filter(student => student.isAllocated == 0);
                                const rightList = res.data.filter(student => student.isAllocated == 1);

                                // Set state for the left list with students who are not allocated
                                setLeft(leftList);
                                setLeftFilteredData(leftList);

                                // Set state for the right list with students who are allocated
                                setRight(rightList);
                                setRightFilteredData(rightList);

                                // Turn off the loader
                                setLoaderOption(false);
                            })
                            .catch(err => {
                                setLoaderOption(false);
                                console.log("Student approval get error: " + err);
                            });

                    }
                    else if (ssForm == 723) {
                        await axios.get(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then(res => {
                                // console.log(JSON.stringify(newData)); // Log newData for debugging
                                setData(res.data);
                                setFilteredData(res.data);
                                setTempData(res.data);
                                // setNewCombineData(res.data);
                                setLoaderOption(false);
                            }).catch(err => {
                                setLoaderOption(false);
                                console.log("Student approval get err" + err);
                            })
                    }

                }
            }


            await axios.get(GetYearAPIURL)
                .then((response) => {
                    // console.log(JSON.stringify(response.data))
                    setYear(response.data);
                    setYearIndex(0);

                    const matchingIndex = response.data.findIndex(
                        (item) => item.yearLabel == authUser.yearLabel
                    );

                    if (matchingIndex !== -1) {
                        setYearIndex(matchingIndex); // Set the index to the matching year
                        // console.log(matchingIndex)
                        setYearLabel(response.data[matchingIndex].id);
                    } else {
                        setYearIndex(0); // Default to first item if no match is found
                        setYearLabel(response.data[0].id);
                    }
                    setLoaderOption(false);
                })

            if (authUser.branchId != null && authUser.deptId != null) {
                await axios(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setClassData(res.data);
                    });
            }

            await axios.get(`${getSubjectData}`)
                .then((res) => {
                    setSubjectData(res.data);
                }).catch(error => {
                    console.log(error);
                })
        }
        else if (prFor == 2) {
            if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
                await axios.get(`${getEmployeeListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        // Separate the data based on the `isAllocated` field
                        const leftList = res.data.filter(student => student.isAllocated == 0);
                        const rightList = res.data.filter(student => student.isAllocated == 1);

                        // Set state for the left list with students who are not allocated
                        setLeft(leftList);
                        setLeftFilteredData(leftList);

                        // Set state for the right list with students who are allocated
                        setRight(rightList);
                        setRightFilteredData(rightList);

                        // Turn off the loader
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })

            }
        }



    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        // Remove selected students from the left list
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        setRightSearchTerm("");
        setLeftSearchTerm("");

        // Normalize srNoVal to an integer for consistent comparison
        const normalizedSrNo = parseInt(srNoVal, 10);

        // Get the total amount for the selected srNo
        const totalAmount = amtData[normalizedSrNo] ?? 0;
        const numberOfStudentsToAdd = leftChecked.length;

        if (numberOfStudentsToAdd === 0) {
            alert("Please select at least one student from the left list.");
            return;
        }

        // Check if the srNo is already in the right list
        const existingStudentsWithSrNo = right.filter(student => student.srNo === normalizedSrNo);
        const totalStudentsWithSrNo = existingStudentsWithSrNo.length + numberOfStudentsToAdd;

        // Calculate the equal amount per student and the remainder
        const equalAmountPerStudent = Math.floor(totalAmount / totalStudentsWithSrNo);
        const remainder = totalAmount % totalStudentsWithSrNo;

        // Calculate how to distribute the remainder
        const distributedAmounts = Array(totalStudentsWithSrNo).fill(equalAmountPerStudent);

        // Distribute the remainder to the first few students
        for (let i = 0; i < remainder; i++) {
            distributedAmounts[i] += 1; // Distribute the extra 1 unit to the first `remainder` students
        }

        // Update existing students with the new calculated amount
        const updatedRightData = right.map((student, index) => {
            if (student.srNo === normalizedSrNo) {
                student.amount = distributedAmounts[index];
            }
            return student;
        });

        // Add new students to the right list with the calculated amount
        leftChecked.forEach((student, index) => {
            updatedRightData.push({
                ...student,
                srNo: normalizedSrNo,
                isAllocated: 1,
                amount: distributedAmounts[existingStudentsWithSrNo.length + index]
            });
        });

        setRight(updatedRightData);
        setRightFilteredData(updatedRightData);
        setSrNoVal(0);
    };

    const handleCheckedLeft = () => {
        setRightSearchTerm("");
        setLeftSearchTerm("");
        // Move checked students from the right to the left
        setLeft(left.concat(rightChecked));
        setLeftFilteredData(left.concat(rightChecked));

        // Remove checked students from the right list
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));

        // Update checked students state
        setChecked(not(checked, rightChecked));

        // Handle removal and amount recalculation
        handleRemove(rightChecked);
        console.log(rightChecked)
    };

    const handleRemove = (removedStudents) => {
        // Remove the selected students from the right list
        // console.log(JSON.stringify(right))

        if (prFor == 1) {
            const updatedRightData = right.filter(student => !removedStudents.some(removed => removed.regNo === student.regNo));
            // console.log(JSON.stringify(updatedRightData))

            // Get the srNo from rightChecked (assuming all have the same srNo)
            const srNoToRemove = rightChecked.length > 0 ? rightChecked[0].srNo : null;

            if (!srNoToRemove) {
                console.error("No srNo found in rightChecked.");
                return;
            }

            // Find all remaining students with the same srNo
            const remainingStudents = updatedRightData.filter(student => student.srNo === srNoToRemove);

            // Recalculate the total amount for the srNo, defaulting to 0 if not found
            const totalAmount = amtData[srNoToRemove] ?? 0;

            if (remainingStudents.length > 0) {
                // Calculate the base amount per student and the remainder
                const baseAmountPerStudent = Math.floor(totalAmount / remainingStudents.length);
                const remainder = totalAmount % remainingStudents.length;

                // Update the amount for each remaining student with the distributed remainder
                const updatedRightDataWithNewAmounts = updatedRightData.map((student, index) => {
                    if (student.srNo === srNoToRemove) {
                        student.amount = baseAmountPerStudent + (index < remainder ? 1 : 0);
                    }
                    return student;
                });

                // Update the right list state with the updated amounts
                setRight(updatedRightDataWithNewAmounts);
                setRightFilteredData(updatedRightDataWithNewAmounts);
            } else {
                // If no students remain with this srNo, just update the right list without recalculating amounts
                setRight(updatedRightData);
                setRightFilteredData(updatedRightData);
            }
        }
        else if (prFor == 2) {
            const updatedRightData = right.filter(student => !removedStudents.some(removed => removed.empId === student.empId));
            // console.log(JSON.stringify(updatedRightData))

            // Get the srNo from rightChecked (assuming all have the same srNo)
            const srNoToRemove = rightChecked.length > 0 ? rightChecked[0].srNo : null;

            if (!srNoToRemove) {
                console.error("No srNo found in rightChecked.");
                return;
            }

            // Find all remaining students with the same srNo
            const remainingStudents = updatedRightData.filter(student => student.srNo === srNoToRemove);

            // Recalculate the total amount for the srNo, defaulting to 0 if not found
            const totalAmount = amtData[srNoToRemove] ?? 0;

            if (remainingStudents.length > 0) {
                // Calculate the base amount per student and the remainder
                const baseAmountPerStudent = Math.floor(totalAmount / remainingStudents.length);
                const remainder = totalAmount % remainingStudents.length;

                // Update the amount for each remaining student with the distributed remainder
                const updatedRightDataWithNewAmounts = updatedRightData.map((student, index) => {
                    if (student.srNo === srNoToRemove) {
                        student.amount = baseAmountPerStudent + (index < remainder ? 1 : 0);
                    }
                    return student;
                });

                // Update the right list state with the updated amounts
                setRight(updatedRightDataWithNewAmounts);
                setRightFilteredData(updatedRightDataWithNewAmounts);
            } else {
                // If no students remain with this srNo, just update the right list without recalculating amounts
                setRight(updatedRightData);
                setRightFilteredData(updatedRightData);
            }
        }
    };

    const leftListData = (leftData) => (
        <Paper sx={{ width: { xs: '100%', sm: 450 }, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D", borderRadius: "8px" }}>
            <div style={{ backgroundColor: "#f5f5f5", position: "sticky", zIndex: "2", top: "0", padding: "10px", borderRadius: "8px" }}>
                <input
                    className="form-control form-control-sm my-2"
                    type="text"
                    style={{
                        border: "1px solid #AFAFAE",
                        float: "right",
                        width: "90%",
                        maxWidth: "300px",
                        fontSize: "14px",
                        backgroundColor: "#F0F3F4",
                        padding: "5px 8px",
                        borderRadius: "5px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"
                    }}
                    placeholder="Search Here"
                    value={leftSearchTerm}
                    onChange={e => setLeftSearchTerm(e.target.value)}
                />
            </div>

            <Table size="small" stickyHeader>
                <thead style={{ backgroundColor: "#007bff", color: "white", fontWeight: "600" }}>
                    <tr>
                        <th style={{ fontSize: "14px" }}></th>
                        <th style={{ fontSize: "14px" }}>{(prFor == 2) ? "emp.Id" : "Reg No"}</th>
                        <th style={{ fontSize: "14px" }}>{(prFor == 2) ? "Employee Name" : "Student Name"}</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (prFor == 1) {
                            return leftData.sort((a, b) => a.regNo > b.regNo ? 1 : -1).map((value) => (
                                <tr key={value.regNo} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            onChange={handleToggle(value)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': value.regNo,
                                            }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                        />
                                    </td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.regNo}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{(authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn}</td>
                                </tr>
                            ));
                        } else if (prFor == 2) {
                            return leftData.sort((a, b) => a.empId > b.empId ? 1 : -1).map((value) => (
                                <tr key={value.empId} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            onChange={handleToggle(value)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': value.empId,
                                            }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                        />
                                    </td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.empId}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{(authUser.branchMedium == 1) ? value.empNameMr : value.empNameEn}</td>
                                </tr>
                            ));
                        }
                    })()}
                </tbody>
            </Table>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: { xs: '100%', sm: 450 }, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D", borderRadius: "8px" }}>
            <div style={{ backgroundColor: "#f5f5f5", position: "sticky", zIndex: "2", top: "0", padding: "10px", borderRadius: "8px" }}>
                <input
                    className="form-control form-control-sm my-2"
                    type="text"
                    style={{
                        border: "1px solid #AFAFAE",
                        float: "right",
                        width: "90%",
                        maxWidth: "300px",
                        fontSize: "14px",
                        backgroundColor: "#F0F3F4",
                        padding: "5px 8px",
                        borderRadius: "5px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"
                    }}
                    placeholder="Search Here"
                    value={rightSearchTerm}
                    onChange={e => setRightSearchTerm(e.target.value)}
                />
            </div>

            <Table size="small" stickyHeader>
                <thead style={{ backgroundColor: "#007bff", color: "white", fontWeight: "600" }}>
                    <tr>
                        <th style={{ fontSize: "14px" }}></th>
                        <th style={{ fontSize: "14px" }}>{(prFor == 2) ? "emp.Id" : "Reg No"}</th>
                        <th style={{ fontSize: "14px" }}>{(prFor == 2) ? "Employee Name" : "Student Name"}</th>
                        <th style={{ fontSize: "14px" }}>Sr No</th>
                        <th style={{ fontSize: "14px" }}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (prFor == 1) {
                            return rightData.map((value) => (
                                <tr key={value.regNo} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            onChange={handleToggle(value)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': value.regNo,
                                            }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                        />
                                    </td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.regNo}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{(authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.srNo}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.amount}</td>
                                </tr>
                            ));
                        } else if (prFor == 2) {
                            return rightData.map((value) => (
                                <tr key={value.empId} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            onChange={handleToggle(value)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': value.empId,
                                            }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                        />
                                    </td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.empId}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{(authUser.branchMedium == 1) ? value.empNameMr : value.empNameEn}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.srNo}</td>
                                    <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.amount}</td>
                                </tr>
                            ));
                        }
                    })()}
                </tbody>
            </Table>
        </Paper>
    );

    useEffect(() => {
        if (prFor == 1) {
            setLeftFilteredData(
                left.filter((item) =>
                    item.regNo.toString().toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                    item.studentNameMr.toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(leftSearchTerm.toLowerCase())
                ))
        }
        else if (prFor == 2) {
            setLeftFilteredData(
                left.filter((item) =>
                    item.empId.toString().toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                    item.empNameMr.toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                    item.empNameEn.toLowerCase().includes(leftSearchTerm.toLowerCase())
                ))
        }
    }, [leftSearchTerm, prFor])

    useEffect(() => {
        if (prFor == 1) {
            setRightFilteredData(
                right.filter((item) =>
                    item.srNo.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.regNo.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.amount.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.studentNameMr.toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(rightSearchTerm.toLowerCase())
                ))
        }
        else if (prFor == 2) {
            setRightFilteredData(
                right.filter((item) =>
                    item.srNo.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.empId.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.amount.toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.empNameMr.toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    item.empNameEn.toLowerCase().includes(rightSearchTerm.toLowerCase())
                ))
        }
    }, [rightSearchTerm, prFor])

    //Automatic school data get
    const getAutomaticData = async () => {
        setLoaderOption(true);
        if (ssForm == 723) {
            console.log(`${getStudentListForSantshaPrizeAllocForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getStudentListForSantshaPrizeAllocForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    // console.log(JSON.stringify(newData)); // Log newData for debugging
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setAutomaticData(res.data);
                        setAutomaticFilterData(res.data);
                        setTempAutomaticData(res.data);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
        else {
            console.log(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    // console.log(JSON.stringify(newData)); // Log newData for debugging
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        // Separate the data based on the `isAllocated` field
                        const leftList = res.data.filter(student => student.isAllocated == 0);
                        const rightList = res.data.filter(student => student.isAllocated == 1);

                        // Set state for the left list with students who are not allocated
                        setAutoLeft(leftList);
                        setAutoLeftFilteredData(leftList);

                        // Set state for the right list with students who are allocated
                        setAutoRight(rightList);
                        setAutoRightFilteredData(rightList);

                        // Turn off the loader
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
    }

    const handleAutoToggle = (value) => () => {
        const currentIndex = autochecked.indexOf(value);
        const newChecked = [...autochecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setAutoChecked(newChecked);
    };

    const handleAutoCheckedRight = () => {
        setAutoRightSearchTerm("");
        setAutoLeftSearchTerm("");
        // Remove selected students from the left list
        setAutoLeft(not(autoLeft, autoLeftChecked));
        setAutoLeftFilteredData(not(autoLeft, autoLeftChecked));
        setAutoChecked(not(autochecked, autoLeftChecked));

        // Normalize srNoVal to an integer for consistent comparison
        const normalizedSrNo = parseInt(srNoVal, 10);

        // Get the total amount for the selected srNo
        const totalAmount = amtData[normalizedSrNo] ?? 0;
        const numberOfStudentsToAdd = autoLeftChecked.length;

        if (numberOfStudentsToAdd === 0) {
            alert("Please select at least one student from the left list.");
            return;
        }

        // Check if the srNo is already in the right list
        const existingStudentsWithSrNo = autoRight.filter(student => student.srNo === normalizedSrNo);
        const totalStudentsWithSrNo = existingStudentsWithSrNo.length + numberOfStudentsToAdd;

        // Calculate the equal amount per student and the remainder
        const equalAmountPerStudent = Math.floor(totalAmount / totalStudentsWithSrNo);
        const remainder = totalAmount % totalStudentsWithSrNo;

        // Calculate how to distribute the remainder
        const distributedAmounts = Array(totalStudentsWithSrNo).fill(equalAmountPerStudent);

        // Distribute the remainder to the first few students
        for (let i = 0; i < remainder; i++) {
            distributedAmounts[i] += 1; // Distribute the extra 1 unit to the first `remainder` students
        }

        // Update existing students with the new calculated amount
        const updatedRightData = autoRight.map((student, index) => {
            if (student.srNo === normalizedSrNo) {
                student.amount = distributedAmounts[index];
            }
            return student;
        });

        // Add new students to the right list with the calculated amount
        autoLeftChecked.forEach((student, index) => {
            updatedRightData.push({
                ...student,
                srNo: normalizedSrNo,
                isAllocated: 1,
                amount: distributedAmounts[existingStudentsWithSrNo.length + index]
            });
        });

        setAutoRight(updatedRightData);
        setAutoRightFilteredData(updatedRightData);
        setSrNoVal(0);
    };

    const handleAutoCheckedLeft = () => {
        setAutoRightSearchTerm("");
        setAutoLeftSearchTerm("");
        // Move checked students from the right to the left
        setAutoLeft(autoLeft.concat(autoRightChecked));
        setAutoLeftFilteredData(autoLeft.concat(autoRightChecked));

        // Remove checked students from the right list
        setAutoRight(not(autoRight, autoRightChecked));
        setAutoRightFilteredData(not(autoRight, autoRightChecked));

        // Update checked students state
        setAutoChecked(not(autochecked, autoRightChecked));

        // Handle removal and amount recalculation
        handleAutoRemove(autoRightChecked);
    };

    // Function to handle removal of student and recalculate amount in right list
    const handleAutoRemove = (removedStudents) => {
        // Remove the selected students from the right list
        // console.log(JSON.stringify(right))

        const updatedRightData = autoRight.filter(student => !removedStudents.some(removed => removed.regNo === student.regNo));
        // console.log(JSON.stringify(updatedRightData))

        // Get the srNo from rightChecked (assuming all have the same srNo)
        const srNoToRemove = autoRightChecked.length > 0 ? autoRightChecked[0].srNo : null;

        if (!srNoToRemove) {
            console.error("No srNo found in rightChecked.");
            return;
        }

        // Find all remaining students with the same srNo
        const remainingStudents = updatedRightData.filter(student => student.srNo === srNoToRemove);

        // Recalculate the total amount for the srNo, defaulting to 0 if not found
        const totalAmount = amtData[srNoToRemove] ?? 0;

        if (remainingStudents.length > 0) {
            // Calculate the base amount per student and the remainder
            const baseAmountPerStudent = Math.floor(totalAmount / remainingStudents.length);
            const remainder = totalAmount % remainingStudents.length;

            // Update the amount for each remaining student with the distributed remainder
            const updatedRightDataWithNewAmounts = updatedRightData.map((student, index) => {
                if (student.srNo === srNoToRemove) {
                    student.amount = baseAmountPerStudent + (index < remainder ? 1 : 0);
                }
                return student;
            });

            // Update the right list state with the updated amounts
            setAutoRight(updatedRightDataWithNewAmounts);
            setAutoRightFilteredData(updatedRightDataWithNewAmounts);
        } else {
            // If no students remain with this srNo, just update the right list without recalculating amounts
            setAutoRight(updatedRightData);
            setRightFilteredData(updatedRightData);
        }
    };

    const leftAutoListData = (leftData) => (

        <Paper sx={{ width: { xs: '100%', sm: 450 }, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D", borderRadius: "8px" }}>
            <div style={{ backgroundColor: "#f5f5f5", position: "sticky", zIndex: "2", top: "0", padding: "10px", borderRadius: "8px" }}>
                <input
                    className="form-control form-control-sm my-2"
                    type="text"
                    style={{
                        border: "1px solid #AFAFAE",
                        float: "right",
                        width: "90%",
                        maxWidth: "300px",
                        fontSize: "14px",
                        backgroundColor: "#F0F3F4",
                        padding: "5px 8px",
                        borderRadius: "5px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"
                    }}
                    placeholder="Search Here"
                    value={autoLeftSearchTerm}
                    onChange={e => setAutoLeftSearchTerm(e.target.value)}
                />
            </div>

            <Table size="small" stickyHeader>
                <thead style={{ backgroundColor: "#007bff", color: "white", fontWeight: "600" }}>
                    <tr>
                        <th style={{ fontSize: "14px" }}></th>
                        <th style={{ fontSize: "14px" }}>Reg No</th>
                        <th style={{ fontSize: "14px" }}>Student Name</th>
                        <th style={{ fontSize: "14px", width: '80px' }}>Obtained Marks</th>
                        <th style={{ fontSize: "14px", width: '50px' }}>Max Marks</th>
                    </tr>
                </thead>
                <tbody>
                    {leftData.sort((a, b) => a.regNo > b.regNo ? 1 : -1).map((value) => (
                        <tr key={value.regNo} style={{ borderBottom: "1px solid #ddd" }}>
                            <td>
                                <Checkbox
                                    checked={autochecked.indexOf(value) !== -1}
                                    onChange={handleAutoToggle(value)}  // Only triggered when checkbox is clicked
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': value.regNo,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </td>
                            <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.regNo}</td>
                            <td style={{ fontSize: '14px', fontWeight: '500', lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn}</td>
                            <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.obtainMarks}</td>
                            <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.maxMark}</td>

                            {/* <td>
                                <input
                                    type="number"
                                    value={value.srNo}
                                    onChange={(e) => handleTableInputChange(e, value.regNo, 'srNo', true)}
                                    style={{ width: '60px', fontSize: '14px', fontWeight: '500' }}
                                />
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Paper>
    );

    const rightAutoListData = (rightData) => (
        <Paper sx={{ width: { xs: '100%', sm: 450 }, height: 320, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D", borderRadius: "8px" }}>
            <div style={{ backgroundColor: "#f5f5f5", position: "sticky", zIndex: "2", top: "0", padding: "10px", borderRadius: "8px" }}>
                <input
                    className='form-control form-control-sm my-2'
                    type="text"
                    style={{
                        border: "1px solid #AFAFAE",
                        float: "right",
                        width: "90%",
                        maxWidth: "300px",
                        fontSize: "14px",
                        backgroundColor: "#F0F3F4",
                        padding: "5px 8px",
                        borderRadius: "5px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"
                    }}
                    placeholder="Search Here"
                    value={autoRightSearchTerm}
                    onChange={e => setAutoRightSearchTerm(e.target.value)}
                />
            </div>

            <Table size="small" stickyHeader>
                <thead style={{ backgroundColor: "#007bff", color: "white", fontWeight: "600" }}>
                    <tr>
                        <th style={{ fontSize: "14px" }}></th>
                        {/* <th style={{ fontSize: "14px" }}>Reg No</th> */}
                        <th style={{ fontSize: "14px" }}>Student Name</th>
                        <th style={{ fontSize: "14px", width: '80px' }}>Obtained Marks</th>
                        <th style={{ fontSize: "14px", width: '50px' }}>Max Marks</th>
                        <th style={{ fontSize: "14px", width: '50px' }}>Sr No</th>
                        <th style={{ fontSize: "14px", width: '50px' }}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {rightData.map((value) => (
                        <tr key={value.regNo} style={{ borderBottom: "1px solid #ddd" }}>
                            <td>
                                <Checkbox
                                    checked={autochecked.indexOf(value) !== -1}
                                    onChange={handleAutoToggle(value)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': value.regNo,
                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            </td>
                            {/* <td style={{ fontSize: '14px', fontWeight: '500' }}>{value.regNo}</td> */}
                            <td style={{ fontSize: '14px', fontWeight: '500', lineHeight: '1.5' }}>{value.regNo + " - " + ((authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn)}</td>
                            <td style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center' }}>{value.obtainMarks}</td>
                            <td style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center' }}>{value.maxMark}</td>
                            <td style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center' }}>{value.srNo}</td>
                            <td style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center' }}>{value.amount}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Paper>
    );

    useEffect(() => {
        setAutoLeftFilteredData(
            autoLeft.filter((item) =>
                item.regNo.toString().toLowerCase().includes(autoLeftSearchTerm.toLowerCase()) ||
                item.studentNameMr.toLowerCase().includes(autoLeftSearchTerm.toLowerCase()) ||
                item.obtainMarks.toString().toLowerCase().includes(autoLeftSearchTerm.toLowerCase()) ||
                item.maxMark.toString().toLowerCase().includes(autoLeftSearchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(autoLeftSearchTerm.toLowerCase())
            ))
    }, [autoLeftSearchTerm])

    useEffect(() => {
        setAutoRightFilteredData(
            autoRight.filter((item) =>
                item.srNo.toString().toLowerCase().includes(autoRightSearchTerm.toLowerCase()) ||
                item.regNo.toString().toLowerCase().includes(autoRightSearchTerm.toLowerCase()) ||
                item.amount.toString().toLowerCase().includes(autoRightSearchTerm.toLowerCase()) ||
                item.studentNameMr.toLowerCase().includes(autoRightSearchTerm.toLowerCase()) ||
                item.obtainMarks.toString().toLowerCase().includes(autoRightSearchTerm.toLowerCase()) ||
                item.maxMark.toString().toLowerCase().includes(autoRightSearchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(autoRightSearchTerm.toLowerCase())
            ))
    }, [autoRightSearchTerm])

    const handleYear = (index) => {
        setAutomaticData([]);
        setAutomaticFilterData([]);
        // console.log(year[index].id);
        setYearIndex(index);
        setYearLabel(year[index].id); // Use yearId instead of index
    };

    const getSTudentListForOptions = async (val) => {
        setLoaderOption(true);
        if (val == 1) {
            // console.log(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
                await axios.get(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
        }
        else if (val == 2) {
            if (classObj != null && subjectObj != null) {
                console.log(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setAutomaticData(res.data);
                        setAutomaticFilterData(res.data);
                        setTempAutomaticData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
        }
        else {
            setData([]);
            setFilteredData([]);
            setAutomaticData([]);
            setAutomaticFilterData([]);
        }
    }

    const handleCheckChange = (index) => {
        const updatedFilteredData = [...filteredData];
        updatedFilteredData[index].isAllocated = updatedFilteredData[index].isAllocated === 1 ? 0 : 1;
        setData(updatedFilteredData);
        // setFilteredData(updatedFilteredData);
        setRegNum(updatedFilteredData[index].regNo)

        if (prFor == 1) {
            const originalDataIndex = data.findIndex(item => item.regNo === updatedFilteredData[index].regNo);
            if (originalDataIndex !== -1) {
                const updatedData = [...data];
                updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
                setUpdatedIsAllocated(updatedFilteredData[index].isAllocated);
                setData(updatedData);
                console.log(JSON.stringify(updatedData))

                const sortedFilteredData = updatedFilteredData.sort((a, b) => b.isAllocated - a.isAllocated);
                if (searchTerm == "") {

                    setFilteredData(sortedFilteredData);
                }
            }
        }
        else if (prFor == 2) {
            const originalDataIndex = data.findIndex(item => item.empId === updatedFilteredData[index].empId);
            if (originalDataIndex !== -1) {
                const updatedData = [...data];
                updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
                setUpdatedIsAllocated(updatedFilteredData[index].isAllocated);
                setData(updatedData);
                const sortedFilteredData = updatedFilteredData.sort((a, b) => b.isAllocated - a.isAllocated);
                if (searchTerm == "") {

                    setFilteredData(sortedFilteredData);
                }
            }
        }
    };

    const handleSansthaDataInputChange = (index, field, value) => {

        if (prFor == 1) {
            const updatedFilteredData = [...filteredData];
            // Check if the field being updated is 'srNo'
            // const updatedFilteredData = [...filteredData];
            updatedFilteredData[index] = { ...updatedFilteredData[index], [field]: value };

            // Step 2: Update the original data with the new value for the matching regNo
            const updatedData = data.map((item) =>
                item.regNo === updatedFilteredData[index].regNo
                    ? { ...item, [field]: value }
                    : item
            );

            const sortedFilteredData = updatedFilteredData.sort((a, b) => b.isAllocated - a.isAllocated);

            // Step 3: Update the states
            setFilteredData(sortedFilteredData);
            setData(updatedData);

            setUpdatedObtainedMarks(updatedFilteredData[index].obtainMarks)

            // console.log(JSON.stringify(updatedData))
        }
        else if (prFor == 2) {
            const updatedFilteredData = [...filteredData];
            // Check if the field being updated is 'srNo'
            // const updatedFilteredData = [...filteredData];
            updatedFilteredData[index] = { ...updatedFilteredData[index], [field]: value };

            // Step 2: Update the original data with the new value for the matching regNo
            const updatedData = data.map((item) =>
                item.empId === updatedFilteredData[index].empId
                    ? { ...item, [field]: value }
                    : item
            );

            const sortedFilteredData = updatedFilteredData.sort((a, b) => b.isAllocated - a.isAllocated);

            // Step 3: Update the states
            setFilteredData(sortedFilteredData);
            setData(updatedData);

            // console.log(JSON.stringify(updatedData))
            setUpdatedObtainedMarks(updatedFilteredData[index].obtainMarks)
        }
    };

    useEffect(() => {
        if (ssForm == 723) {
            if (option == 1) {
                if (prFor == 1) {

                    if (updatedObtainedMarks !== null) {
                        const updatedData = [...tempData];

                        const itemIndex = updatedData.findIndex(item => item.regNo === regNum);
                        if (itemIndex !== -1) {
                            updatedData[itemIndex].obtainMarks = updatedObtainedMarks;
                            updatedData[itemIndex].isAllocated = updatedIsAllocated;
                        }

                        setData(updatedData);
                        // setTempData(updatedData);
                        console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                        if (searchTerm == "") {
                            setFilteredData(updatedData);
                            console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                        }

                        // Reset state values after updating
                        setUpdatedObtainedMarks(null);
                        setUpdatedIsAllocated(0);
                    }

                }
                else if (prFor == 2) {
                    if (updatedObtainedMarks !== null) {
                        const updatedData = [...tempData];

                        const itemIndex = updatedData.findIndex(item => item.empId === regNum);
                        if (itemIndex !== -1) {
                            updatedData[itemIndex].obtainMarks = updatedObtainedMarks;
                            updatedData[itemIndex].isAllocated = updatedIsAllocated;
                        }

                        setData(updatedData);
                        // setTempData(updatedData);
                        // console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                        if (searchTerm == "") {
                            setFilteredData(updatedData);
                            // console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                        }

                        // Reset state values after updating
                        setUpdatedObtainedMarks(null);
                        setUpdatedIsAllocated(0);
                    }
                }
            }
            else if (option == 2) {
                if (updatedAutoIsAllocated != 0) {
                    const updatedData = [...tempAutomaticData];


                    const itemIndex = updatedData.findIndex(item => item.regNo === autoRegNum);
                    if (itemIndex !== -1) {
                        updatedData[itemIndex].isAllocated = updatedAutoIsAllocated;
                    }

                    setAutomaticData(updatedData);
                    // console.log(JSON.stringify(updatedData))
                    if (searchTerm == "") {
                        setAutomaticFilterData(updatedData);
                        // console.log(JSON.stringify(updatedData))
                    }

                    // Reset state values after updating
                    setUpdatedAutoSrNo(null);
                    setUpdatedAutoAmount(null);
                    setUpdatedAutoIsAllocated(0);
                }
            }
        }

    }, [searchTerm, ssForm]);

    useEffect(() => {
        if (option == 1) {
            setFilteredData([])
            let tempFilteredData = data.filter((item) =>
                (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredData(tempFilteredData)
        }
        else if (option == 2) {
            setAutomaticFilterData([])
            let tempFilteredData = automaticData.filter((item) =>
                (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.maxMark || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.obtainMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
            setAutomaticFilterData(tempFilteredData)
        }
    }, [searchTerm, option])


    const handleCheckAutoChange = (index) => {
        const updatedFilteredData = [...automaticFilterData];
        updatedFilteredData[index].isAllocated = updatedFilteredData[index].isAllocated === 1 ? 0 : 1;
        setAutomaticData(updatedFilteredData);
        // setAutomaticFilterData(updatedFilteredData);

        setAutoRegNum(updatedFilteredData[index].regNo)

        const originalDataIndex = automaticData.findIndex(item => item.regNo === updatedFilteredData[index].regNo);
        if (originalDataIndex !== -1) {
            const updatedData = [...automaticData];
            updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
            setUpdatedAutoIsAllocated(updatedFilteredData[index].isAllocated);
            setAutomaticData(updatedData);
            const sortedFilteredData = updatedFilteredData.sort((a, b) => b.isAllocated - a.isAllocated);
            if (searchTerm == "") {
                setAutomaticFilterData(sortedFilteredData);
            }
        }
    };

    const handleInputChange = (e, key) => {
        const updatedValue = e.target.value;
        setAmtData({
            ...amtData,
            [key]: updatedValue, // Update the specific key's value
        });
    };

    // console.log(JSON.stringify(leftFilteredData)); // Log combined data for debugging

    const saveManualStudentList = (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const newData = right.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: (prFor == 1) ? e.regNo : e.empId,
            srNo: parseInt(e.srNo),
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount
        }));

        // const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(newData));
        if (newData.length != 0) {

            axios.post(`${saveStudentListForManualAPIURL}`, newData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setRightFilteredData([]);
                        setRight([]);
                        setLeft([]);
                        setLeftFilteredData([]);
                        // setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setRightFilteredData([]);
                        setRight([]);
                        setLeft([]);
                        setLeftFilteredData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })


        }


    }

    const saveAutomaticStudentList = (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const newData = autoRight.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: e.regNo,
            srNo: e.srNo,
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(newData));
        if (newData.length != 0) {

            axios.post(`${saveStudentListForManualAPIURL}`, newData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount for ${srNo}st rank.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutoRightFilteredData([]);
                        setAutoRight([]);
                        setAutoLeft([]);
                        setAutoLeftFilteredData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutoRightFilteredData([]);
                        setAutoRight([]);
                        setAutoLeft([]);
                        setAutoLeftFilteredData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
    }

    const saveManualStudentForSanstha = (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const newData = data.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: (prFor == 1) ? e.regNo : e.empId,
            srNo: e.srNo,
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            obtainedMarks: e.obtainMarks
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveSansthaStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo amount does not match with prize amount.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                obtainedMarks: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveSansthaStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })


        }
    }

    const saveSansthaAutomaticStudentList = (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const newData = automaticData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: e.regNo,
            srNo: e.srNo,
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            obtainedMarks: e.obtainMarks,
            maxMark: e.maxMark
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveSansthaStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount for ${srNo}st rank.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                obtainedMarks: 0,
                maxMark: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveSansthaStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
    }

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        <div className=''>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>खाते क्र. : <span style={{ color: '#fe3627', fontWeight: '600' }}>{prId}</span></span>
                        </div>
                        <div className=''>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>पारितोषिकाचे नाव : <span style={{ color: '#fe3627', fontWeight: '600' }}>{prName}</span></span>
                        </div>
                        <div className='mt-1'>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>योजनेची माहिती : <span style={{ color: '#fe3627', fontWeight: '600' }}>{yName}</span></span>
                        </div>
                        <hr />

                        {(() => {
                            if (prFor == 1) {
                                return (
                                    <>
                                        <div className="row my-2">
                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                            <div className="col-sm-3">
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Option</InputLabel>
                                                    <Select
                                                        MenuProps={{ classes: { paper: classes.select } }}
                                                        size='small'
                                                        fullWidth
                                                        //inputProps={{ readOnly: true }}
                                                        margin="dense"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={option}
                                                        label="Option"
                                                        onChange={(e) => {
                                                            setOption(e.target.value);
                                                            setData([]);
                                                            setFilteredData([]);
                                                            setAutomaticData([]);
                                                            setAutomaticFilterData([]);
                                                            setClassObj(null);
                                                            setSubjectObj(null);
                                                            getSTudentListForOptions(e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value={1}>Manual</MenuItem>
                                                        <MenuItem value={2}>Automatic</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            {/* {(() => {
                                                if (data.length == 0) {
                                                    return (
                                                        <>
                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                        </>
                                                    )
                                                }
                                            })()} */}
                                        </div>



                                        {(() => {
                                            if (option == 2) {
                                                return (
                                                    <>
                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                            <div className='col-sm-3'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={classData}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    value={classObj}
                                                                    onChange={(event, newValue) => {
                                                                        setClassObj(newValue);
                                                                        setSubjectObj(null);
                                                                        setAutomaticData([]);
                                                                        setAutomaticFilterData([]);
                                                                    }}
                                                                    style={{ fontSize: '14px' }}
                                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} margin='dense' label="Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required={(option == 2) ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                            <div className='col-sm-3'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={subjectData}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    value={subjectObj}
                                                                    onChange={(event, newValue) => {
                                                                        setSubjectObj(newValue);
                                                                        setAutomaticData([]);
                                                                        setAutomaticFilterData([]);
                                                                    }}
                                                                    style={{ fontSize: '14px' }}
                                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} margin='dense' label="Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required={(option == 2) ? true : false}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Year :</label>
                                                            <div className='col-sm-3'>
                                                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        views={['year']}
                                                                        label="Year"
                                                                        value={date}
                                                                        onChange={(newValue) => {
                                                                            setDate(newValue);
                                                                        }}
                                                                        onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                                                        inputFormat="yyyy"
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                {...params}
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                required
                                                                                fullWidth
                                                                                autoComplete="off"
                                                                                size="small"

                                                                            />}
                                                                    />
                                                                </LocalizationProvider> */}
                                                                <FormControl fullWidth margin='dense'>
                                                                    <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>Select Option</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"

                                                                        id="demo-simple-select"
                                                                        size='small'
                                                                        value={yearIndex}
                                                                        required={(option == 2) ? true : false}
                                                                        label="Select Option"
                                                                        onChange={(event) => { handleYear(event.target.value); }}
                                                                        onKeyPress={(e) => (e.key == "Enter") ? yearIndex : ""}
                                                                    >
                                                                        {
                                                                            year.map((e, index) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={index}>{e.yearLabel}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-sm-4 mt-3">
                                                                <button type='btn' className='btn btn-sm btn-warning' onClick={() => getAutomaticData()} disabled={(subjectObj == null || classObj == null) ? true : false}>View</button>
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            }
                                        })()}

                                        <hr />


                                        {(() => {
                                            if (option == 1) {
                                                if (ssForm == 723) {
                                                    if (data.length != 0 || filteredData.length != 0) {
                                                        return (
                                                            <>
                                                                <form onSubmit={saveManualStudentForSanstha}>
                                                                    <div className="row mt-3">
                                                                        <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                                                        {Object.entries(amtData).map(([key, value], index) => (
                                                                            <div className='' key={index} style={{ width: '100px' }}>
                                                                                <TextField
                                                                                    label={`Value ${key}`} // Label for each text field
                                                                                    value={value}           // Set the initial value from the object
                                                                                    onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                                                    fullWidth
                                                                                    inputProps={{ readOnly: true }}
                                                                                    size='small'
                                                                                    margin='dense'
                                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    <div>
                                                                        <div className='row col-sm-4 offset-sm-5'>
                                                                            <div>
                                                                                <input
                                                                                    className='form-control form-control-sm'
                                                                                    type="text"
                                                                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                                                    placeholder="Search Here"
                                                                                    onChange={(event) => { setSearchTerm(event.target.value); }}
                                                                                    value={searchTerm}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className='table-responsive col-sm-8 offset-sm-1 mt-4' style={{ maxHeight: "330px" }}>
                                                                                <table className="table table-bordered">
                                                                                    <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                                        <tr>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks</th>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                                        {filteredData.map((item, index) => {
                                                                                            return (
                                                                                                <>

                                                                                                    <tr key={index} className="table-sm">
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                                        <td style={{ width: "150px", height: "70px" }}>
                                                                                                            <input
                                                                                                                onWheel={(e) => e.target.blur}
                                                                                                                style={{ width: "120px", fontSize: "14px" }}
                                                                                                                type="number"
                                                                                                                className="form-control"
                                                                                                                value={item.obtainMarks}
                                                                                                                onChange={(e) => handleSansthaDataInputChange(index, 'obtainMarks', e.target.value)}
                                                                                                            />
                                                                                                        </td>


                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                                checked={item.isAllocated === 1}
                                                                                                                onChange={() => handleCheckChange(index)}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                </>
                                                                                            )

                                                                                        })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <div className="col-sm-4 offset-sm-1">
                                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        )
                                                    }
                                                }
                                                else {
                                                    if (left.length != 0 || leftFilteredData.length != 0) {
                                                        return (
                                                            <>
                                                                <form onSubmit={saveManualStudentList}>
                                                                    <div className="row mt-3">
                                                                        <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                                                        {Object.entries(amtData).map(([key, value], index) => (
                                                                            <div className='' key={index} style={{ width: '100px' }}>
                                                                                <TextField
                                                                                    label={`Value ${key}`} // Label for each text field
                                                                                    value={value}           // Set the initial value from the object
                                                                                    onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                                                    fullWidth
                                                                                    inputProps={{ readOnly: true }}
                                                                                    size='small'
                                                                                    margin='dense'
                                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    <div className="row mt-3">
                                                                        <div className="col-sm-12">
                                                                            <Grid container spacing={2} alignItems="center">
                                                                                <Grid item>{leftListData(leftFilteredData)}</Grid>
                                                                                <Grid item>
                                                                                    <Grid container direction="column" alignItems="center">
                                                                                        {/* Sr No Label and Input on the Same Line */}
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                                                            <label style={{ fontSize: '14px', fontWeight: '600', marginRight: '8px' }}>Sr No. :</label>
                                                                                            <input
                                                                                                className="form-control form-control-sm"
                                                                                                type="text"
                                                                                                style={{
                                                                                                    width: "80px",
                                                                                                    border: "1px solid #C2C1C1",
                                                                                                    fontSize: "14px"
                                                                                                }}
                                                                                                placeholder="Sr No."
                                                                                                onChange={(event) => { setSrNoVal(event.target.value); }}
                                                                                                value={srNoVal}
                                                                                            />
                                                                                        </div>

                                                                                        <button
                                                                                            className="my-1"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            onClick={handleCheckedRight}
                                                                                            disabled={leftChecked.length === 0 || srNoVal <= 0}
                                                                                            aria-label="move selected right"
                                                                                            style={{ width: "30px" }}
                                                                                        >
                                                                                            &gt;
                                                                                        </button>
                                                                                        <button
                                                                                            className="my-1"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            onClick={handleCheckedLeft}
                                                                                            disabled={rightChecked.length === 0}
                                                                                            aria-label="move selected left"
                                                                                            style={{ width: "30px" }}
                                                                                        >
                                                                                            &lt;
                                                                                        </button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </div>


                                                                    <div className="row mt-4">
                                                                        <div className="col-sm-4">
                                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        )
                                                    }
                                                }
                                            }
                                            else if (option == 2) {
                                                if (ssForm == 723) {
                                                    if (automaticData.length != 0 || automaticFilterData.length != 0) {
                                                        return (
                                                            <>
                                                                <div>
                                                                    <div className='row col-sm-4 offset-sm-7'>
                                                                        <div>
                                                                            <input
                                                                                className='form-control form-control-sm'
                                                                                type="text"
                                                                                style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                                                placeholder="Search Here"
                                                                                onChange={(event) => { setSearchTerm(event.target.value) }}
                                                                                value={searchTerm}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <form onSubmit={saveSansthaAutomaticStudentList}>
                                                                        <div className="row">
                                                                            <div className='table-responsive col-sm-10 offset-sm-1 mt-4' style={{ maxHeight: "330px" }}>
                                                                                <table className="table table-bordered">
                                                                                    <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                                        <tr>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks</th>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Max Marks</th>
                                                                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                                        {automaticFilterData.map((item, index) => {
                                                                                            return (
                                                                                                <>

                                                                                                    <tr key={index} className="table-sm">
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.obtainMarks}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.maxMark}</td>

                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                                checked={item.isAllocated === 1}
                                                                                                                onChange={() => handleCheckAutoChange(index)}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                </>
                                                                                            )

                                                                                        })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mt-4">
                                                                            <div className="col-sm-4 offset-sm-1">
                                                                                <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                                <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                }
                                                else {
                                                    if (autoLeft.length != 0 || autoLeftFilteredData.length != 0) {
                                                        return (
                                                            <>
                                                                <form onSubmit={saveAutomaticStudentList}>
                                                                    <div className="row mt-3">
                                                                        <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                                                        {Object.entries(amtData).map(([key, value], index) => (
                                                                            <div className='' key={index} style={{ width: '100px' }}>
                                                                                <TextField
                                                                                    label={`Value ${key}`} // Label for each text field
                                                                                    value={value}           // Set the initial value from the object
                                                                                    onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                                                    fullWidth
                                                                                    inputProps={{ readOnly: true }}
                                                                                    size='small'
                                                                                    margin='dense'
                                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    <div className="row mt-3">
                                                                        <div className="col-sm-12">
                                                                            <Grid container spacing={2} alignItems="center">
                                                                                <Grid item>{leftAutoListData(autoLeftFilteredData)}</Grid>
                                                                                <Grid item>
                                                                                    <Grid container direction="column" alignItems="center">
                                                                                        {/* Sr No Label and Input on the Same Line */}
                                                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                                                            <label style={{ fontSize: '14px', fontWeight: '600', marginRight: '8px' }}>Sr No. :</label>
                                                                                            <input
                                                                                                className="form-control form-control-sm"
                                                                                                type="text"
                                                                                                style={{
                                                                                                    width: "80px",
                                                                                                    border: "1px solid #C2C1C1",
                                                                                                    fontSize: "14px"
                                                                                                }}
                                                                                                placeholder="Sr No."
                                                                                                onChange={(event) => { setSrNoVal(event.target.value); }}
                                                                                                value={srNoVal}
                                                                                            />
                                                                                        </div>

                                                                                        <button
                                                                                            className="my-1"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            onClick={handleAutoCheckedRight}
                                                                                            disabled={autoLeftChecked.length === 0 || srNoVal <= 0}
                                                                                            aria-label="move selected right"
                                                                                            style={{ width: "30px" }}
                                                                                        >
                                                                                            &gt;
                                                                                        </button>
                                                                                        <button
                                                                                            className="my-1"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            onClick={handleAutoCheckedLeft}
                                                                                            disabled={autoRightChecked.length === 0}
                                                                                            aria-label="move selected left"
                                                                                            style={{ width: "30px" }}
                                                                                        >
                                                                                            &lt;
                                                                                        </button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>{rightAutoListData(autoRightFilteredData)}</Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <div className="col-sm-4">
                                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>
                                                        )
                                                    }

                                                }
                                            }
                                        })()}

                                    </>
                                )
                            }
                            else if (prFor == 2) {
                                if (left.length != 0 || leftFilteredData.length != 0) {
                                    return (
                                        <>

                                            <div>
                                                <div className="row mt-3">
                                                    <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                                    {Object.entries(amtData).map(([key, value], index) => (
                                                        <div className='' key={index} style={{ width: '100px' }}>
                                                            <TextField
                                                                label={`Value ${key}`} // Label for each text field
                                                                value={value}           // Set the initial value from the object
                                                                onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                                fullWidth
                                                                inputProps={{ readOnly: true }}
                                                                size='small'
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                <form onSubmit={saveManualStudentList}>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-12">
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item>{leftListData(leftFilteredData)}</Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column" alignItems="center">
                                                                        {/* Sr No Label and Input on the Same Line */}
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                                            <label style={{ fontSize: '14px', fontWeight: '600', marginRight: '8px' }}>Sr No. :</label>
                                                                            <input
                                                                                className="form-control form-control-sm"
                                                                                type="text"
                                                                                style={{
                                                                                    width: "80px",
                                                                                    border: "1px solid #C2C1C1",
                                                                                    fontSize: "14px"
                                                                                }}
                                                                                placeholder="Sr No."
                                                                                onChange={(event) => { setSrNoVal(event.target.value); }}
                                                                                value={srNoVal}
                                                                            />
                                                                        </div>

                                                                        <button
                                                                            className="my-1"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            onClick={handleCheckedRight}
                                                                            disabled={leftChecked.length === 0 || srNoVal <= 0}
                                                                            aria-label="move selected right"
                                                                            style={{ width: "30px" }}
                                                                        >
                                                                            &gt;
                                                                        </button>
                                                                        <button
                                                                            className="my-1"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            onClick={handleCheckedLeft}
                                                                            disabled={rightChecked.length === 0}
                                                                            aria-label="move selected left"
                                                                            style={{ width: "30px" }}
                                                                        >
                                                                            &lt;
                                                                        </button>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                            </Grid>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-4">
                                                        <div className="col-sm-4">
                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        })()}

                    </div>
                </div >
            }
        </>
    )
}

export default AddStudentConditionTransferList
