import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { TablePagination, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getSanthaStudentApprovalAllocationData, getStudentApprovalAllocationData, getStudentApprovalAllocationDataForSanstha } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const SansthaStudentApprovalForm = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student To Prize Allocation"
    const [loaderOption, setLoaderOption] = useState(false);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [filteredData, setFilteredData] = useState([]);

    // console.log(ssForm)



    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `रक्कम`, key: 'amount' },
        { label: `Approval Status`, key: 'prizeStatus' },
        { label: `Action`, key: 'action', isAction: true },
    ];

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        setLoaderOption(true);
        if (authUser.sessionYear != '') {

            // console.log("school==" + `${getStudentApprovalAllocationData}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}`)
            await axios.get(`${getSanthaStudentApprovalAllocationData}?year=${authUser.sessionYear}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })

        }
    }

    const getPrizeDetails = async (pId) => {
        navigate(`/Home/et722pf/${pId}`)
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    (item.prizeCode || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.prizeName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.prizeStatus || '').toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        <div className='row mb-2' style={{ float: 'right' }}>
                            <div className=''>
                                <input
                                    className='form-control form-control-sm'
                                    type="text"
                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                    placeholder="Search Here"
                                    value={searchTerm}
                                    onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                />
                            </div>
                        </div>

                        <div className='table-responsive' style={{ overflowY: "hidden" }}>
                            <table className="table table-bordered">
                                <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                    <tr>
                                        {TableHeading.map((item, index) => {
                                            return (
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>

                                    {
                                        filteredData.map((item, index) => {
                                            return (
                                                <tr key={index} className="">
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.prizeCode}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{item.prizeName}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.amount}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.prizeStatus}</td>
                                                    <td style={{ width: "100px" }}>
                                                        <Tooltip title="View Details">
                                                            <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                onClick={() => getPrizeDetails(item.id)}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                </tbody>
                                <tfoot style={{ padding: "0px" }}>
                                    <tr>
                                        <TablePagination
                                            style={{ padding: "0px" }}
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                            onPageChange={onPageChange}
                                            labelRowsPerPage="Rows Per Page :"
                                            classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default SansthaStudentApprovalForm
