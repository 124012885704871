import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from '../../../CommonComponent/Loader';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { getSchoolListForEndowment } from '../Services/EndowmentMasterAPIURL';
import axios from 'axios';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { getRegionMasterAPI } from '../Services/RegionMasterAPIURL';

import { getRegionToSChoolAPI, saveRegionToSChoolAPI, updateRegionToSChoolAPI } from '../Services/RegionToSchoolAPIURL';
import { clear } from '@testing-library/user-event/dist/clear';
import { toast } from 'react-toastify';
import RegionToSchoolAllocTable from './RegionToSchoolAllocTable';
import { BaseURLSection } from '../../../CommonServices/InstituteMasterAPIURL';
import { getDeptToSChoolAPI, saveDeptToSChoolAPI, updateDeptToSChoolAPI } from '../Services/DeptToSchoolAPIURL';
import DeptToSchoolAllocTable from './DeptToSchoolAllocTable';

const DeptToSchoolAlloc = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Department To School Allocation"
    const [loaderOption, setLoaderOption] = useState(false);



    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "deptToSchoolFormSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "deptToSchoolFormUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `Department`, key: (authUser.branchMedium == 1) ? 'deptNameMr' : 'deptNameEn' }, { label: `School`, key: 'SchoolName' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];


    const [id, setId] = useState("");
    const [schoolDataForEndow, setSchoolDataForEndow] = useState([]);
    const [schoolObjForEndow, setSchoolObjForEndow] = useState([]);
    const [schoolIdForEndow, setSchoolIdForEndow] = useState([]);
    const [deptData, setDeptData] = useState([]);
    const [deptObj, setDeptObj] = useState(null);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        await axios.get(`${BaseURLSection}`)
            .then((response) => {
                setDeptData(response.data);
            })

        await axios.get(getSchoolListForEndowment)
            .then(res => {
                setSchoolDataForEndow(res.data)
            }).catch(error => {
                console.log(error);
            })

        console.log(getDeptToSChoolAPI)
        await axios.get(getDeptToSChoolAPI)
            .then(res => {
                setData(res.data)
                setFilteredData(res.data)
            }).catch(error => {
                console.log(error);
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);

        const saveObject = {
            "departmentId": deptObj.id,
            "schoolEndowmentId": schoolIdForEndow

        };
        console.log(JSON.stringify(saveObject))
        await axios.post(`${saveDeptToSChoolAPI}`, saveObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "SAVED") {
                    getData();
                    ClearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });


    };

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            "id": id,
            "departmentId": deptObj.id,
            "schoolEndowmentId": schoolIdForEndow
        };

        await axios.put(`${updateDeptToSChoolAPI}`, updateObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "UPDATED") {
                    getData();
                    ClearData();
                    setLoaderOption(false);
                    toast.success("Update sucessfully.");
                } else {
                    setLoaderOption(false);
                    toast.danger("Operation failed.");
                }
            });


    };

    const ClearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setDeptObj(null);
        setSchoolObjForEndow([]);
        setSchoolIdForEndow([]);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        {/* Form Data start */}
                        <form onSubmit={onSubmit}>
                            <div className="form-data mt-1">
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row my-2">
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Dept. :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={deptData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={deptObj}
                                            onChange={(event, newValue) => {
                                                setDeptObj(newValue);
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    requird
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>School :</label>
                                    <div className='col-sm-4'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={schoolDataForEndow}
                                            multiple
                                            disableCloseOnSelect
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={schoolObjForEndow}
                                            filterSelectedOptions
                                            onChange={(event, newValue) => {
                                                setSchoolObjForEndow(newValue);

                                                setSchoolIdForEndow(newValue.map(e => e.endowmentId).join(','))

                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    requird={(schoolObjForEndow.length == 0) ? true : false}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-1">
                                        <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                        <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                        <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="tableData">
                            <DeptToSchoolAllocTable
                                TableHeading={TableHeading}
                                setId={setId}
                                deptData={deptData}
                                setDeptObj={setDeptObj}
                                setSchoolIdForEndow={setSchoolIdForEndow}
                                schoolDataForEndow={schoolDataForEndow}
                                setSchoolDataForEndow={setSchoolDataForEndow}
                                setSchoolObjForEndow={setSchoolObjForEndow}
                                getData={getData}
                                data={data}
                                setFilteredData={setFilteredData}
                                filteredData={filteredData}
                                setData={setData}
                                saveBtnStyle={saveBtnStyle}
                                updateBtnStyle={updateBtnStyle}
                                displayNoneBtnStyle={displayNoneBtnStyle}
                                setSaveBtn={setSaveBtn}
                                setUpdateBtn={setUpdateBtn}
                            />
                        </div>
                    </div>


                </div>
            }

        </>
    )
}

export default DeptToSchoolAlloc
