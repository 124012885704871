import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { Autocomplete, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, TablePagination, TextField, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getAmountListForManualAPIURL, getEmployeeListForManualAPIURL, getPrizeDetailsData, getStudentApprovalAllocationData, getStudentListForAutomaticAPIURL, getStudentListForManualAPIURL, saveStudentListForManualAPIURL } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faBullseye, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import ConditionDetailsTable from './ConditionDetailsTable';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AddStudentSrNoTextBox from './AddStudentSrNoTextBox';
import { toast } from 'react-toastify';
import { GetYearAPIURL } from '../../../CommonServices/LoginAPIURL';
import AddStudentAmountTextBox from './AddStudentAmountTextBox';
import { ClassMasterGetMapping } from '../../../CommonServices/ClassMasterAPIURL';
import { getSubjectData } from '../../../Exam/Services/ExamReportAPIURL';
import { getClassesBySectionAndBranch } from '../../../Exam/Services/ExamMarkAllocationAPIURL';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));


const ConditionAddStudentModal = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Add Students"
    const [loaderOption, setLoaderOption] = useState(false);
    const { prId, etran, prFor } = useParams();

    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    // console.log(JSON.stringify(data));
    const [option, setOption] = useState(1);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [date, setDate] = useState(new Date().setFullYear(new Date().getFullYear() - 1));
    const [checkValue, setCheckValue] = useState([]);
    const [Val, setVal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [chkRegNum, setChkRegNum] = useState('');
    const [srNo, setSrNo] = useState('');
    const [regNum, setRegNum] = useState('');
    const [tempData, setTempData] = useState([]);
    const [year, setYear] = useState([]);
    const [yearIndex, setYearIndex] = useState("");
    const [yearLabel, setYearLabel] = useState("");
    const [amtData, setAmtData] = useState([]);
    const [values, setValues] = useState('');
    const [newFilterData, setNewFilterData] = useState([]);
    const [automaticData, setAutomaticData] = useState([]);
    const [automaticFilterData, setAutomaticFilterData] = useState([]);
    const [automaticNewFilterData, setAutomaticNewFilterData] = useState([]);
    const [tempAutomaticData, setTempAutomaticData] = useState([]);
    const [amount, setAmount] = useState('');



    // console.log(JSON.stringify(newFilterData))

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `योजनेची माहिती`, key: 'approvalStatus' },
        { label: `Action`, key: 'action', isAction: true },
    ];

    const combinedData = filteredData.map(item => {
        const filterDataItem = newFilterData.find(f => f.srNo == item.srNo); // Find matching item in newFilterData
        // console.log(JSON.stringify(filterDataItem))
        return {
            ...item,
            ...(filterDataItem ? { srNo: filterDataItem.srNo, amount: (item.amount != filterDataItem.amount && item.amount != 0) ? item.amount : filterDataItem.amount } : {}) // Replace srNo and amount if found
        };
    });

    const [newCombineData, setNewCombineData] = useState(combinedData);

    const combinedAutoData = automaticFilterData.map(item => {
        const filterDataItem = automaticNewFilterData.find(f => f.srNo == item.srNo); // Find matching item in newFilterData
        // console.log(JSON.stringify(filterDataItem))
        return {
            ...item,
            ...(filterDataItem ? { srNo: filterDataItem.srNo, amount: (item.amount != filterDataItem.amount && item.amount != 0) ? item.amount : filterDataItem.amount } : {}) // Replace srNo and amount if found
        };
    });

    const [newCombineAutoData, setNewCombineAutoData] = useState(combinedAutoData);

    const handleCheckChange = (index) => {
        // setVal(val);

        // if (prFor == 1) {
        //     if (option == 1) {
        //         const SaveObjectData = {
        //             "regNo": items.regNo,
        //             "srNo": items.srNo,
        //             "prizeCode": prId,
        //             "etranNo": etran,
        //             "branchId": authUser.branchId,
        //             "sessionYear": authUser.sessionYear
        //         }
        //         if (val == true) {
        //             setCheckValue(checkValue.concat(SaveObjectData));

        //             //if checkbox is checked
        //             let updatedData = data;
        //             data.map((e, index) => {
        //                 if (e.regNo == SaveObjectData.regNo) {
        //                     updatedData[index] = ({ ...e, isAllocated: 1 })
        //                 }

        //             })
        //             let checkdata = updatedData.filter(e => e.isAllocated === 0)
        //             //console.log(checkdata.length);
        //             //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
        //             // if (checkdata.length === 0) {
        //             //     setCheckAllSub(true);
        //             // }

        //             // console.log("updatedData=======" + JSON.stringify(updatedData));
        //             if (searchTerm == "") {
        //                 setData(updatedData);
        //                 setFilteredData(updatedData)
        //             }
        //         }
        //         else if (val == false) {
        //             const removedData = checkValue.filter((item) => item.regNo != items.regNo)
        //             setCheckValue(removedData);
        //             // setCheckAllSub(false);

        //             //if checkbox is unChecked
        //             let updatedData = data;
        //             data.map((e, index) => {
        //                 if (e.regNo == SaveObjectData.regNo) {
        //                     updatedData[index] = ({ ...e, isAllocated: 0 })
        //                 }

        //             })
        //             // console.log("updatedData=======" + JSON.stringify(updatedData));
        //             if (searchTerm == "") {
        //                 setData(updatedData);
        //                 setFilteredData(updatedData);
        //             }

        //         }
        //         setChkRegNum(SaveObjectData.regNo);
        //     }
        //     else if (option == 2) {
        //         const SaveObjectData = {
        //             "regNo": items.regNo,
        //             "srNo": items.srNo,
        //             "prizeCode": prId,
        //             "etranNo": etran,
        //             "branchId": authUser.branchId,
        //             "sessionYear": authUser.sessionYear
        //         }
        //         if (val == true) {
        //             setCheckValue(checkValue.concat(SaveObjectData));

        //             //if checkbox is checked
        //             let updatedData = automaticData;
        //             automaticData.map((e, index) => {
        //                 if (e.regNo == SaveObjectData.regNo) {
        //                     updatedData[index] = ({ ...e, isAllocated: 1 })
        //                 }

        //             })
        //             let checkdata = updatedData.filter(e => e.isAllocated === 0)
        //             //console.log(checkdata.length);
        //             //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
        //             // if (checkdata.length === 0) {
        //             //     setCheckAllSub(true);
        //             // }

        //             // console.log("updatedData=======" + JSON.stringify(updatedData));
        //             if (searchTerm == "") {
        //                 setAutomaticData(updatedData);
        //                 setAutomaticFilterData(updatedData)
        //             }
        //         }
        //         else if (val == false) {
        //             const removedData = checkValue.filter((item) => item.regNo != items.regNo)
        //             setCheckValue(removedData);
        //             // setCheckAllSub(false);

        //             //if checkbox is unChecked
        //             let updatedData = automaticFilterData;
        //             automaticFilterData.map((e, index) => {
        //                 if (e.regNo == SaveObjectData.regNo) {
        //                     updatedData[index] = ({ ...e, isAllocated: 0 })
        //                 }

        //             })
        //             // console.log("updatedData=======" + JSON.stringify(updatedData));
        //             if (searchTerm == "") {
        //                 setAutomaticData(updatedData);
        //                 setAutomaticFilterData(updatedData)
        //             }

        //         }
        //         setChkRegNum(SaveObjectData.regNo);
        //     }
        // }
        // else if (prFor == 2) {
        //     const SaveObjectData = {
        //         "empId": items.empId,
        //         "srNo": items.srNo,
        //         "prizeCode": prId,
        //         "etranNo": etran,
        //         "branchId": authUser.branchId,
        //         "sessionYear": authUser.sessionYear
        //     }
        //     if (val == true) {
        //         setCheckValue(checkValue.concat(SaveObjectData));

        //         //if checkbox is checked
        //         let updatedData = data;
        //         data.map((e, index) => {
        //             if (e.empId == SaveObjectData.empId) {
        //                 updatedData[index] = ({ ...e, isAllocated: 1 })
        //             }

        //         })
        //         let checkdata = updatedData.filter(e => e.isAllocated === 0)
        //         //console.log(checkdata.length);
        //         //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
        //         // if (checkdata.length === 0) {
        //         //     setCheckAllSub(true);
        //         // }

        //         console.log("updatedData=======" + JSON.stringify(updatedData));
        //         if (searchTerm == "") {
        //             setData(updatedData);
        //             setFilteredData(updatedData)
        //         }
        //     }
        //     else if (val == false) {
        //         const removedData = checkValue.filter((item) => item.empId != items.empId)
        //         setCheckValue(removedData);
        //         // setCheckAllSub(false);

        //         //if checkbox is unChecked
        //         let updatedData = data;
        //         data.map((e, index) => {
        //             if (e.empId == SaveObjectData.empId) {
        //                 updatedData[index] = ({ ...e, isAllocated: 0 })
        //             }

        //         })
        //         console.log("updatedData=======" + JSON.stringify(updatedData));
        //         if (searchTerm == "") {
        //             setData(updatedData);
        //             setFilteredData(updatedData);
        //         }

        //     }
        //     setChkRegNum(SaveObjectData.empId);
        // }
        const updatedData = [...data];
        updatedData[index].isAllocated = updatedData[index].isAllocated === 1 ? 0 : 1;
        setData(updatedData);
        setFilteredData(updatedData);
    };

    // useEffect(() => {
    //     if (option == 1) {
    //         if (Val == true) {
    //             let updatedDataTrue = data
    //             data.map((e, index) => {
    //                 if (e.regNo == chkRegNum) {
    //                     updatedDataTrue[index] = ({
    //                         ...e,
    //                         sessionYear: authUser.sessionYear,
    //                         branchId: authUser.branchId,
    //                         isAllocated: 1
    //                     })
    //                 }
    //             })
    //             if (searchTerm == "") {
    //                 setChkRegNum('');
    //                 console.log(updatedDataTrue)
    //                 setData(updatedDataTrue);
    //                 setFilteredData(updatedDataTrue);
    //                 setNewFilterData(updatedDataTrue)
    //             }
    //         }
    //         else if (Val == false) {
    //             //console.log(checkDivId)
    //             let updatedDataFalse = data
    //             data.map((e, index) => {
    //                 if (e.regNo == chkRegNum) {
    //                     updatedDataFalse[index] = ({
    //                         ...e,
    //                         sessionYear: authUser.sessionYear,
    //                         branchId: authUser.branchId,
    //                         isAllocated: 0
    //                     })
    //                 }
    //             })

    //             //console.log(JSON.stringify(updatedDataFalse))
    //             if (searchTerm == "") {
    //                 setChkRegNum('');
    //                 setData(updatedDataFalse);
    //                 setFilteredData(updatedDataFalse);
    //                 setNewFilterData(updatedDataFalse)
    //             }
    //         }
    //     }
    //     else if (option == 2) {
    //         if (Val == true) {
    //             let updatedDataTrue = automaticData
    //             automaticData.map((e, index) => {
    //                 if (e.regNo == chkRegNum) {
    //                     updatedDataTrue[index] = ({
    //                         ...e,
    //                         sessionYear: authUser.sessionYear,
    //                         branchId: authUser.branchId,
    //                         isAllocated: 1
    //                     })
    //                 }
    //             })
    //             if (searchTerm == "") {
    //                 setChkRegNum('');
    //                 setAutomaticData(updatedDataTrue);
    //                 setAutomaticFilterData(updatedDataTrue);
    //                 setAutomaticNewFilterData(updatedDataTrue);
    //             }
    //         }
    //         else if (Val == false) {
    //             //console.log(checkDivId)
    //             let updatedDataFalse = automaticData
    //             automaticData.map((e, index) => {
    //                 if (e.regNo == chkRegNum) {
    //                     updatedDataFalse[index] = ({
    //                         ...e,
    //                         sessionYear: authUser.sessionYear,
    //                         branchId: authUser.branchId,
    //                         isAllocated: 0
    //                     })
    //                 }
    //             })

    //             //console.log(JSON.stringify(updatedDataFalse))
    //             if (searchTerm == "") {
    //                 setChkRegNum('');
    //                 setAutomaticData(updatedDataFalse);
    //                 setAutomaticFilterData(updatedDataFalse);
    //                 setAutomaticNewFilterData(updatedDataFalse);
    //             }
    //         }
    //     }
    // }, [searchTerm, Val, chkRegNum, option])

    useEffect(() => {
        if (option == 1) {
            let newAmtData = {}; // Use an object to hold amounts for each srNo
            data.forEach(e => {
                if (amtData && amtData[e.srNo] !== undefined && e.srNo > 0) {
                    newAmtData[e.srNo] = amtData[e.srNo]; // Set the amount for valid srNo
                } else {
                    newAmtData[e.srNo] = 0; // Default to 0 for invalid srNo
                }
            });



            // Update data array with new value from newAmtData
            const updatedData = data.map(item => ({
                srNo: item.srNo,
                amount: newAmtData[item.srNo] || 0 // Assign value based on srNo, default to 0
            }));

            // Optionally set updated data to state if needed
            // console.log(JSON.stringify(updatedData));
            setNewFilterData(updatedData);
        }
        else if (option == 2) {
            let newAmtData = {}; // Use an object to hold amounts for each srNo
            automaticData.forEach(e => {
                if (amtData && amtData[e.srNo] !== undefined && e.srNo > 0) {
                    newAmtData[e.srNo] = amtData[e.srNo]; // Set the amount for valid srNo
                } else {
                    newAmtData[e.srNo] = 0; // Default to 0 for invalid srNo
                }
            });



            // Update data array with new value from newAmtData
            const updatedData = automaticData.map(item => ({
                srNo: item.srNo,
                amount: newAmtData[item.srNo] || 0 // Assign value based on srNo, default to 0
            }));

            // Optionally set updated data to state if needed
            // console.log(JSON.stringify(updatedData));
            setAutomaticNewFilterData(updatedData);
        }
    }, [data, amtData, option, automaticData]);


    useEffect(() => {
        getData();
    }, [authUser, prFor])

    const getData = async () => {
        setLoaderOption(true);
        console.log(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        if (prFor == 1) {
            if (option == 1) {
                if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
                    await axios.get(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            // console.log(JSON.stringify(newData)); // Log newData for debugging
                            setData(res.data);
                            setFilteredData(res.data);
                            setTempData(res.data);
                            setNewCombineData(res.data);
                            setLoaderOption(false);
                        }).catch(err => {
                            setLoaderOption(false);
                            console.log("Student approval get err" + err);
                        })

                }
            }


            await axios.get(GetYearAPIURL)
                .then((response) => {
                    // console.log(JSON.stringify(response.data))
                    setYear(response.data);
                    setYearIndex(0);

                    const matchingIndex = response.data.findIndex(
                        (item) => item.yearLabel == authUser.yearLabel
                    );

                    if (matchingIndex !== -1) {
                        setYearIndex(matchingIndex); // Set the index to the matching year
                        // console.log(matchingIndex)
                        setYearLabel(response.data[matchingIndex].id);
                    } else {
                        setYearIndex(0); // Default to first item if no match is found
                        setYearLabel(response.data[0].id);
                    }
                    setLoaderOption(false);
                })

            if (authUser.branchId != null && authUser.deptId != null) {
                await axios(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setClassData(res.data);
                    });
            }

            await axios.get(`${getSubjectData}`)
                .then((res) => {
                    setSubjectData(res.data);
                }).catch(error => {
                    console.log(error);
                })
        }
        else if (prFor == 2) {
            if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
                await axios.get(`${getEmployeeListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        setNewCombineData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })

            }
        }

        if (authUser.sessionYear != "") {
            // console.log(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
            await axios.get(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                .then(res => {
                    setAmtData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }

    }

    const getAutomaticData = async () => {
        setLoaderOption(true);
        // console.log(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                // console.log(JSON.stringify(newData)); // Log newData for debugging
                setAutomaticData(res.data);
                setAutomaticFilterData(res.data);
                setTempAutomaticData(res.data);
                setLoaderOption(false);
            }).catch(err => {
                setLoaderOption(false);
                console.log("Student approval get err" + err);
            })
    }

    const handleYear = (index) => {
        setAutomaticData([]);
        setAutomaticFilterData([]);
        // console.log(year[index].id);
        setYearIndex(index);
        setYearLabel(year[index].id); // Use yearId instead of index
    };


    const getSTudentListForOptions = async (val) => {
        if (val == 1) {
            // console.log(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
                await axios.get(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
        }
        else if (val == 2) {
            if (classObj != null && subjectObj != null) {
                console.log(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setAutomaticData(res.data);
                        setAutomaticFilterData(res.data);
                        setTempAutomaticData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
        }
        else {
            setData([]);
            setFilteredData([]);
            setAutomaticData([]);
            setAutomaticFilterData([]);
        }
    }

    useEffect(() => {
        if (prFor == 1) {
            if (option == 1) {
                setFilteredData([])
                let tempFilteredData = data.filter((item) =>
                    item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.amount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()))
                setFilteredData(tempFilteredData)
            }
            else if (option == 2) {
                setAutomaticFilterData([])
                let tempFilteredData = automaticData.filter((item) =>
                    item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.amount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.maxMark.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.obtainMarks.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()))
                setAutomaticFilterData(tempFilteredData)
            }
        }
        else if (prFor == 2) {
            setFilteredData([])
            let tempFilteredData = data.filter((item) =>
                item.empNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.empNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.amount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.empId.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredData(tempFilteredData)
        }
    }, [searchTerm, option, prFor])

    // useEffect(() => {
    //     if (option == 1) {
    //         let updatedData = tempData;

    //         tempData.map((e, index) => {
    //             if (e.regNo == regNum) {
    //                 updatedData[index] = ({ ...e, srNo: srNo, amount: amount })
    //             }

    //         })
    //         // console.log("before=====" + JSON.stringify(updatedData));
    //         setData(updatedData);
    //         if (searchTerm == "") {
    //             // console.log("after=====" + JSON.stringify(updatedData));

    //             setFilteredData(updatedData);
    //             setNewFilterData(updatedData);
    //             // setNewCombineData(updatedData);
    //         }
    //     }
    //     else if (option == 2) {
    //         let updatedData = tempAutomaticData;

    //         tempAutomaticData.map((e, index) => {
    //             if (e.regNo == regNum) {
    //                 updatedData[index] = ({ ...e, srNo: srNo, amount: amount })
    //             }

    //         })
    //         setAutomaticData(updatedData);
    //         if (searchTerm == "") {
    //             setAutomaticFilterData(updatedData);
    //             setAutomaticNewFilterData(updatedData);
    //             setNewCombineAutoData(updatedData);
    //         }
    //     }
    // }, [searchTerm, regNum])


    const handleInputChange = (e, key) => {
        const updatedValue = e.target.value;
        setAmtData({
            ...amtData,
            [key]: updatedValue, // Update the specific key's value
        });
    };

    const saveManualStudentList = (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const newData = newCombineData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: (prFor == 1) ? e.regNo : e.empId,
            srNo: e.srNo,
            branchId: authUser.branchId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722/`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: authUser.branchId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722/`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })


        }


    }

    const saveAutomaticStudentList = (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const newData = newCombineAutoData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: e.regNo,
            srNo: e.srNo,
            branchId: authUser.branchId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount for ${srNo}st rank.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722/`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: authUser.branchId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722/`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
    }

    const handleAmountUpdate = (srNo, newAmount) => {
        if (option == 1) {
            // console.log(newAmount);
            const updatedData = data.map(item => {
                if (item.srNo === srNo) {
                    return { ...item, amount: newAmount };
                }
                return item;
            });
            setData(updatedData); // Update the state with the new data
            setFilteredData(updatedData); // Update filtered data if needed
            setNewCombineData(updatedData);
        }
        else if (option == 2) {
            const updatedData = automaticData.map(item => {
                if (item.srNo === srNo) {
                    return { ...item, amount: newAmount };
                }
                return item;
            });
            setAutomaticData(updatedData); // Update the state with the new data
            setAutomaticFilterData(updatedData); // Update filtered data if needed
            setNewCombineAutoData(updatedData);
        }
    };

    // console.log(JSON.stringify(filteredData)); // Log combined data for debugging

    const handleDataInputChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index][field] = value;
        console.log(JSON.stringify(updatedData))
        setData(updatedData);
        setFilteredData(updatedData);
    };


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>

                        {(() => {
                            if (prFor == 1) {
                                return (
                                    <>
                                        <div className="row my-2">
                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                            <div className="col-sm-3">
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Option</InputLabel>
                                                    <Select
                                                        MenuProps={{ classes: { paper: classes.select } }}
                                                        size='small'
                                                        fullWidth
                                                        //inputProps={{ readOnly: true }}
                                                        margin="dense"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={option}
                                                        label="Option"
                                                        onChange={(e) => {
                                                            setOption(e.target.value);
                                                            setData([]);
                                                            setFilteredData([]);
                                                            setAutomaticData([]);
                                                            setAutomaticFilterData([]);
                                                            setClassObj(null);
                                                            setSubjectObj(null);
                                                            getSTudentListForOptions(e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value={1}>Manual</MenuItem>
                                                        <MenuItem value={2}>Automatic</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        {(() => {
                                            if (option == 2) {
                                                return (
                                                    <>
                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                            <div className='col-sm-3'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={classData}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    value={classObj}
                                                                    onChange={(event, newValue) => {
                                                                        setClassObj(newValue);
                                                                        setSubjectObj(null);
                                                                        setAutomaticData([]);
                                                                        setAutomaticFilterData([]);
                                                                    }}
                                                                    style={{ fontSize: '14px' }}
                                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} margin='dense' label="Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                            <div className='col-sm-3'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={subjectData}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    value={subjectObj}
                                                                    onChange={(event, newValue) => {
                                                                        setSubjectObj(newValue);
                                                                        setAutomaticData([]);
                                                                        setAutomaticFilterData([]);
                                                                    }}
                                                                    style={{ fontSize: '14px' }}
                                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} margin='dense' label="Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Year :</label>
                                                            <div className='col-sm-3'>
                                                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        views={['year']}
                                                        label="Year"
                                                        value={date}
                                                        onChange={(newValue) => {
                                                            setDate(newValue);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                                        inputFormat="yyyy"
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                required
                                                                fullWidth
                                                                autoComplete="off"
                                                                size="small"
                                                           
                                                            />}
                                                    />
                                                </LocalizationProvider> */}
                                                                <FormControl fullWidth margin='dense'>
                                                                    <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>Select Option</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        required
                                                                        id="demo-simple-select"
                                                                        size='small'
                                                                        value={yearIndex}
                                                                        label="Select Option"
                                                                        onChange={(event) => { handleYear(event.target.value); }}
                                                                        onKeyPress={(e) => (e.key == "Enter") ? yearIndex : ""}
                                                                    >
                                                                        {
                                                                            year.map((e, index) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={index}>{e.yearLabel}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-sm-4 mt-3">
                                                                <button type='btn' className='btn btn-sm btn-warning' onClick={() => getAutomaticData()}>View</button>
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            }
                                        })()}

                                        <hr />

                                        <div className="row mt-3">
                                            <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                            {Object.entries(amtData).map(([key, value], index) => (
                                                <div className='' key={index} style={{ width: '100px' }}>
                                                    <TextField
                                                        label={`Value ${key}`} // Label for each text field
                                                        value={value}           // Set the initial value from the object
                                                        onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                        fullWidth
                                                        inputProps={{ readOnly: true }}
                                                        size='small'
                                                        margin='dense'
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        {(() => {
                                            if (option == 1) {
                                                if (data.length != 0 && filteredData.length != 0) {
                                                    return (
                                                        <>

                                                            <div>
                                                                <div className='row col-sm-4 offset-sm-5'>
                                                                    <div>
                                                                        <input
                                                                            className='form-control form-control-sm'
                                                                            type="text"
                                                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                                            placeholder="Search Here"
                                                                            onChange={(event) => { setSearchTerm(event.target.value) }}
                                                                            value={searchTerm}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <form onSubmit={saveManualStudentList}>
                                                                    <div className="row">
                                                                        <div className='table-responsive col-sm-8 offset-sm-1 mt-4' style={{ maxHeight: "330px" }}>
                                                                            <table className="table table-bordered">
                                                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                                    <tr>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                                    {filteredData.map((item, index) => {

                                                                                        // console.log(item.amount)
                                                                                        return (
                                                                                            <tr key={index} className="table-sm">
                                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>


                                                                                                {/* <AddStudentSrNoTextBox
                                                                                                    initialText={item.srNo}
                                                                                                    onChange={(value) => {
                                                                                                        let updatedData = [...filteredData];
                                                                                                        updatedData[index] = { ...item, srNo: value };
                                                                                                        setFilteredData(updatedData);
                                                                                                        setData(updatedData);
                                                                                                        setNewCombineData(updatedData);
                                                                                                        setSrNo(value);
                                                                                                        setAmount(item.amount);
                                                                                                        setRegNum(updatedData[index].regNo)
                                                                                                    }}
                                                                                                    data={filteredData} // Pass the data array to check for matching srNo
                                                                                                    amtData={amtData} // Pass the amount data to get the corresponding amount
                                                                                                    updateAmount={handleAmountUpdate} // Function to update the amount
                                                                                                />


                                                                                                <AddStudentAmountTextBox
                                                                                                    initialText={item.amount} // Ensure item.srNo > 0
                                                                                                    amtData={amtData}
                                                                                                    srNo={item.srNo}
                                                                                                    data={data}
                                                                                                    onChange={(value) => {
                                                                                                        let updatedData = filteredData;
                                                                                                        updatedData[index] = { ...item, amount: value };
                                                                                                        // console.log(JSON.stringify(updatedData));
                                                                                                        setFilteredData(updatedData);
                                                                                                        setNewCombineData(updatedData);
                                                                                                        setData(updatedData);
                                                                                                        setSrNo(item.srNo);
                                                                                                        setAmount(value);
                                                                                                        setRegNum(updatedData[index].regNo)
                                                                                                    }}
                                                                                                /> */}

                                                                                                <td style={{ width: "100px", height: "70px" }}>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="form-control"
                                                                                                        value={item.srNo}
                                                                                                        onChange={(e) => handleDataInputChange(index, 'srNo', e.target.value)}
                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ width: "100px", height: "70px" }}>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="form-control"
                                                                                                        value={amtData[item.srNo] !== undefined ? amtData[item.srNo] : item.amount}
                                                                                                        onChange={(e) => handleDataInputChange(index, 'amount', e.target.value)}
                                                                                                    />
                                                                                                </td>

                                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                    <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                        checked={item.isAllocated === 1}
                                                                                                        onChange={() => handleCheckChange(index)}
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>


                                                                    <div className="row mt-4">
                                                                        <div className="col-sm-4 offset-sm-1">
                                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722/`)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            }
                                            else if (option == 2) {
                                                if (automaticData.length != 0 && automaticFilterData.length != 0) {
                                                    return (
                                                        <>

                                                            <div>
                                                                {/* <div className='row col-sm-4 offset-sm-7'>
                                                    <div>
                                                        <input
                                                            className='form-control form-control-sm'
                                                            type="text"
                                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                            placeholder="Search Here"
                                                            onChange={(event) => { setSearchTerm(event.target.value) }}
                                                            value={searchTerm}
                                                        />
                                                    </div>
                                                </div> */}
                                                                <form onSubmit={saveAutomaticStudentList}>
                                                                    <div className="row">
                                                                        <div className='table-responsive col-sm-10 offset-sm-1 mt-4' style={{ maxHeight: "330px" }}>
                                                                            <table className="table table-bordered">
                                                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                                    <tr>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Max Marks</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                                    {combinedAutoData.map((item, index) => {

                                                                                        // console.log(item.amount)
                                                                                        return (
                                                                                            <tr key={index} className="table-sm">
                                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.obtainMarks}</td>
                                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.maxMark}</td>
                                                                                                {/* 
                                                                                <AddStudentSrNoTextBox initialText={item.srNo}
                                                                                    data={filteredData} // Pass the data array to check for matching srNo
                                                                                    amtData={amtData} // Pass the amount data to get the corresponding amount
                                                                                    updateAmount={handleAmountUpdate}
                                                                                    onChange={(value) => {
                                                                                        let updatedData = filteredData;
                                                                                        updatedData[index] = ({ ...item, srNo: value });
                                                                                        setFilteredData(updatedData);
                                                                                        setData(updatedData);
                                                                                        setSrNo(value);
                                                                                        setRegNum(updatedData[index].regNo)
                                                                                        // console.log(JSON.stringify(data));
                                                                                    }} /> */}

                                                                                                <AddStudentSrNoTextBox
                                                                                                    initialText={item.srNo}
                                                                                                    onChange={(value) => {
                                                                                                        let updatedData = [...automaticFilterData];
                                                                                                        updatedData[index] = { ...item, srNo: value };
                                                                                                        setAutomaticFilterData(updatedData);
                                                                                                        setAutomaticData(updatedData);
                                                                                                        setNewCombineAutoData(updatedData);
                                                                                                        setSrNo(value);
                                                                                                        setRegNum(updatedData[index].regNo)
                                                                                                    }}
                                                                                                    data={automaticFilterData} // Pass the data array to check for matching srNo
                                                                                                    amtData={amtData} // Pass the amount data to get the corresponding amount
                                                                                                    updateAmount={handleAmountUpdate} // Function to update the amount
                                                                                                />


                                                                                                <AddStudentAmountTextBox
                                                                                                    initialText={item.amount} // Ensure item.srNo > 0
                                                                                                    amtData={amtData}
                                                                                                    srNo={item.srNo}
                                                                                                    data={automaticData}
                                                                                                    onChange={(value) => {
                                                                                                        let updatedData = automaticFilterData;
                                                                                                        updatedData[index] = { ...item, amount: value };
                                                                                                        // console.log(JSON.stringify(updatedData));
                                                                                                        setAutomaticFilterData(updatedData);
                                                                                                        setNewCombineAutoData(updatedData);
                                                                                                        setAutomaticData(updatedData);
                                                                                                    }}
                                                                                                />

                                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                    <Checkbox defaultChecked={(item.isAllocated == 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                        onChange={(e) => {
                                                                                                            let val = e.target.checked;
                                                                                                            handleCheckChange(val, item);
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>


                                                                    <div className="row mt-4">
                                                                        <div className="col-sm-4 offset-sm-1">
                                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722/`)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            }
                                        })()}
                                    </>
                                )
                            }
                            else if (prFor == 2) {
                                if (data.length != 0 && filteredData.length != 0) {
                                    return (
                                        <>

                                            <div>
                                                <div className="row mt-3">
                                                    <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                                    {Object.entries(amtData).map(([key, value], index) => (
                                                        <div className='' key={index} style={{ width: '100px' }}>
                                                            <TextField
                                                                label={`Value ${key}`} // Label for each text field
                                                                value={value}           // Set the initial value from the object
                                                                onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                                fullWidth
                                                                inputProps={{ readOnly: true }}
                                                                size='small'
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className='row col-sm-4 offset-sm-4'>
                                                    <div>
                                                        <input
                                                            className='form-control form-control-sm'
                                                            type="text"
                                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                            placeholder="Search Here"
                                                            onChange={(event) => { setSearchTerm(event.target.value) }}
                                                            value={searchTerm}
                                                        />
                                                    </div>
                                                </div>
                                                <form onSubmit={saveManualStudentList}>
                                                    <div className="row">
                                                        <div className='table-responsive col-sm-8 mt-4' style={{ maxHeight: "330px" }}>
                                                            <table className="table table-bordered">
                                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                    <tr>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Emp. Id</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Employee Name</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                    {filteredData.map((item, index) => {

                                                                        // console.log(item.amount)
                                                                        return (
                                                                            <tr key={index} className="table-sm">
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.empId}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.empNameMr : item.empNameEn}</td>

                                                                                {/* <AddStudentSrNoTextBox
                                                                                    initialText={item.srNo}
                                                                                    onChange={(value) => {
                                                                                        let updatedData = [...filteredData];
                                                                                        updatedData[index] = { ...item, srNo: value };
                                                                                        setFilteredData(updatedData);
                                                                                        setData(updatedData);
                                                                                        setNewCombineData(updatedData);
                                                                                        setSrNo(value);
                                                                                        setAmount(item.amount);
                                                                                        setRegNum(updatedData[index].empId)
                                                                                    }}
                                                                                    data={filteredData} // Pass the data array to check for matching srNo
                                                                                    amtData={amtData} // Pass the amount data to get the corresponding amount
                                                                                    updateAmount={handleAmountUpdate} // Function to update the amount
                                                                                /> */}


                                                                                {/* <AddStudentAmountTextBox
                                                                                    initialText={item.amount} // Ensure item.srNo > 0
                                                                                    amtData={amtData}
                                                                                    srNo={item.srNo}
                                                                                    data={data}
                                                                                    onChange={(value) => {
                                                                                        let updatedData = filteredData;
                                                                                        updatedData[index] = { ...item, amount: value };
                                                                                        console.log(JSON.stringify(updatedData));
                                                                                        setFilteredData(updatedData);
                                                                                        setNewCombineData(updatedData);
                                                                                        setData(updatedData);
                                                                                        setSrNo(item.srNo);
                                                                                        setAmount(value);
                                                                                        setRegNum(updatedData[index].empId)
                                                                                    }}
                                                                                /> */}

                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        value={item.srNo}
                                                                                        onChange={(e) => handleDataInputChange(index, 'srNo', e.target.value)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        value={amtData[item.srNo] !== undefined ? amtData[item.srNo] : item.amount}
                                                                                        onChange={(e) => handleDataInputChange(index, 'amount', e.target.value)}
                                                                                    />
                                                                                </td>

                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                    <Checkbox defaultChecked={(item.isAllocated == 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                        onChange={(e) => {
                                                                                            let val = e.target.checked;
                                                                                            handleCheckChange(val, item);
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>


                                                    <div className="row mt-4">
                                                        <div className="col-sm-4">
                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722/`)}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        })()}


                    </div>
                </div >
            }
        </>
    )
}



export default ConditionAddStudentModal
