import { Select, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from "react-redux";

const EndowSubjectTextBox = ({ endowSubList, initialText, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [text, setText] = useState(initialText);

    const handleChange = (event) => {
        setText(event.target.value);
        onChange(event.target.value);
    };

    return (
        <td style={{ fontWeight: "500", fontSize: "14px" }} className='p-2'>
            <Select
                value={text}
                onChange={handleChange}
                onBlur={() => { onChange(text) }}
                size='small'
                sx={{
                    width: "200px",            // Fixed width of 130px
                    backgroundColor: "#fff",    // White background color
                    fontSize: "14px",           // Set font size to 14px
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                            width: 200,   // Sets the dropdown menu height

                        },
                    },
                }}
                autoFocus
            >
                {endowSubList.map((e) => (
                    <MenuItem key={e.id} value={e.id} style={{ fontSize: "14px" }}>
                        {authUser.branchMedium === 1 ? e.nameMr : e.nameEn}
                    </MenuItem>
                ))}
            </Select>
        </td>
    );
};

export default EndowSubjectTextBox;
