import React, { useEffect, useState } from 'react'
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getEndowmentDataForUpdateAPIURL, getEndowmentDetailsForSansthaAPIURL, getEndowmentDetailsForSchoolAPIURL, getSchoolListForEndowment } from '../Services/EndowmentMasterAPIURL';
import axios from 'axios';
import Loader from '../../../CommonComponent/Loader';
import { useSelector } from 'react-redux';
import SchoolEndowmentCondition from './SchoolEndowmentCondition';
import meritData from '../../../CommonComponent/MeritNoData';
import { ClassMasterGetMapping } from '../../../CommonServices/ClassMasterAPIURL';
import { getCityData } from '../../../CommonServices/InstituteMasterAPIURL';

const SansthaEndowSchoolMasterForm = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const { sendId, EditFlag } = useParams();
    const [loaderOption, setLoaderOption] = useState(false);

    //Endowment register states
    const [id, setId] = useState("");
    const [prizeCode, setPrizeCode] = useState('');
    const [paritoshikName, setParitoshikName] = useState("");
    const [donatorName, setDonatorName] = useState("");
    const [donatorAddress, setDonatorAddress] = useState("");
    const [yojanaInfo, setYojanaInfo] = useState("");
    const [cityObj, setCityObj] = useState("");
    const [email, setEmail] = useState('');
    const [mobile, setmobile] = useState();
    const [amount, setAmount] = useState('');
    const [schoolDataForEndow, setSchoolDataForEndow] = useState([]);
    const [data, setData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [deptData, setDeptData] = useState([]);
 

    useEffect(() => {
        if (EditFlag == 1) {
            getEndowmentDetails();
        }
    }, [authUser]);

    const getEndowmentDetails = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getEndowmentDetailsForSansthaAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    //console.log(res.data)
                    res.data.map(e => {
                        if (e.id == sendId) {
                            setId(e.id)
                            setPrizeCode(e.prizeCode)
                            setParitoshikName(e.prizeName)
                            setDonatorName(e.donorName)
                            setDonatorAddress(e.donorAddress)
                            setYojanaInfo(e.schemeDescription)
                            setCityObj(e.city)
                            setEmail(e.email)
                            setmobile(e.mobileNo)
                            setAmount(e.totalAmount)



                            axios.get(`${getEndowmentDataForUpdateAPIURL}` + e.id)
                                .then(res => {
                                    setData(res.data.prizeDistribution);
                                    console.log(JSON.stringify(res.data.prizeDistribution))
                                    setNewData(res.data.schoolData);
                                    setLoaderOption(false);
                                }).catch(error => {
                                    console.log(error);
                                    setLoaderOption(false);
                                })
                        }


                    })
                    setLoaderOption(false);
                }).catch(error => {
                    console.log(error);
                    setLoaderOption(false);
                })


        }

        await axios.get(`${ClassMasterGetMapping}`)
            .then((res) => {
                setClassData(res.data);
            }).catch(error => {
                console.log(error);
            })

        await axios.get(getCityData)
            .then(res => {
                setCityData(res.data)
            }).catch(error => {
                console.log(error);
            })

        await axios.get(getSchoolListForEndowment)
            .then(res => {
                setSchoolDataForEndow(res.data)
            }).catch(error => {
                console.log(error);
            })


    }



    return (

        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId="Sanstha Endowment" />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 0px 2px grey", marginTop: "16px", minHeight: "500px" }}>
                        {/* Endowment form starts */}
                        <form>

                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >खाते क्र. :</label>
                                <div className='col-sm-2'>
                                    <TextField
                                        //required
                                        fullWidth
                                        type="number"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="खाते क्र."
                                        value={prizeCode}
                                        onChange={(e) => setPrizeCode(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>पारितोषिकाचे नाव:</label>
                                <div className='col-sm-6'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="पारितोषिकाचे नाव"
                                        value={paritoshikName}
                                        onChange={(e) => setParitoshikName(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>देणगीदाराचे नाव:</label>
                                <div className='col-sm-6'>
                                    <TextField
                                        //required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="देणगीदाराचे नाव"
                                        value={donatorName}
                                        onChange={(e) => setDonatorName(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>पत्ता :</label>
                                <div className='col-sm-6'>
                                    <TextField
                                        //required
                                        fullWidth
                                        multiline
                                        rows={2}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="पत्ता"
                                        value={donatorAddress}
                                        onChange={(e) => setDonatorAddress(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शहर :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        //required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="शहर"
                                        value={cityObj}
                                        onChange={(e) => setCityObj(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile No :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && mobile.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        type="number"
                                        size='small'
                                        id="outlined-basic"
                                        label="Mobile No"
                                        variant="outlined"
                                        margin='dense'
                                        value={mobile}
                                        onChange={(e) => {
                                            setmobile(e.target.value);
                                        }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                        onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Email Id :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        //required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Email Id"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>


                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >एकूण रक्कम :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        required
                                        fullWidth
                                        //type="number"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="रक्कम"
                                        variant="outlined"
                                        margin='dense'
                                        value={amount}
                                        onChange={(e) => setAmount(parseFloat(e.target.value).toFixed(2))}
                                        inputProps={{ readOnly: true }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>योजनेची माहिती :</label>
                                <div className='col-sm-7'>
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        rows={3}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="योजनेची माहिती"
                                        value={yojanaInfo}
                                        onChange={(e) => setYojanaInfo(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            {(() => {
                                if (data.length != 0) {
                                    return (
                                        <>
                                            {
                                                data.map(item => {
                                                    return (
                                                        <SchoolEndowmentCondition
                                                            initialText1={item.prizeFrom}
                                                            initialText2={item.prizeFor}
                                                            initialText3={item.branchId}
                                                            initialText4={item.amountInPercent}
                                                            initialText5={item.classId}
                                                            initialText6={item.noOfStudent}
                                                            initialText7={item.subjectId}
                                                            initialText8={item.genderId}
                                                            initialText9={item.schoolNameMr}
                                                            initialText10={(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}
                                                            initialText11={(authUser.branchMedium == 1) ? item.subjectNameMr : item.subjectNameEn}
                                                            initialText12={meritData.find(e => e.id == item.noOfStudent)}
                                                            initialText13={item.etranNo}
                                                            initialText14={item.prizeCode}
                                                            initialText15={item.subjectCategory}
                                                            initialText16={item.amountDivideInStudent}
                                                            initialText17={item.prizeDescription}
                                                            initialText18={item.branchType}
                                                            initialText19={item.forBranch}
                                                            initialText20={item.regDeptId}
                                                            initialText21={item.regionName}
                                                            initialText22={(authUser.branchMedium == 1) ? item.deptNameMr : item.deptNameEn}
                                                            newSchooldata={item.schoolDataList}
                                                            schoolDataForEndow={schoolDataForEndow}
                                                            newData={newData}
                                                        />
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                            })()}

                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate('/Home/sansthaEndow')}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </>
    )
}


export default SansthaEndowSchoolMasterForm
