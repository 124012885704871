import { Autocomplete, FormControlLabel, Paper, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'

const prizeFrom = [
    {
        id: 1,
        name: "School",
    },
    {
        id: 2,
        name: "Sanstha",
    }
]

const prizeFor = [
    {
        id: 1,
        name: "Student",
    },
    {
        id: 2,
        name: "Teacher",
    },
    {
        id: 3,
        name: "Institute",
    }
]

const subjectType = [
    {
        id: 1,
        name: "Academic",
    },
    {
        id: 2,
        name: "Non-Academic",
    }
]

const prizeGiving = [
    {
        id: 1,
        name: "All Schools",
    },
    {
        id: 2,
        name: "School And Department Wise",
    },
    {
        id: 3,
        name: "Region Wise",
    },
    {
        id: 4,
        name: "Department Wise",
    },
    
]

const prize = [
    {
        id: 1,
        name: "प्रत्येकी",
    },
    {
        id: 2,
        name: "यापैकी",
    }
]



const SchoolEndowmentCondition = ({ initialText1, initialText2, initialText3, initialText4, initialText5, initialText6, initialText7, initialText8, initialText9,
    initialText10, initialText11, initialText12, initialText13, initialText14, initialText15, initialText16, initialText17,
    initialText18, initialText19, initialText20, initialText21, initialText22, newData, newSchooldata, schoolDataForEndow }) => {

    const selectedPrizeFrom = prizeFrom.find(prize => prize.id === initialText1);

    const selectedPrizeFor = prizeFor.find(prize => prize.id === initialText2);

    const selectedSubType = subjectType.find(prize => prize.id === initialText15);

    const selectedPrizeGiving = prizeGiving.find(prize => prize.id === initialText18);

    const selectedPrize = prize.find(prize => prize.id === initialText19);

    const [text18, setText18] = useState(newSchooldata);
    // console.log(initialText21 + "=======" + initialText22)

    //No of students code
    var result = {};
    //divideStudLength = text14.split(",").length;
    if (initialText16 !== null) {
        initialText16.split(',').forEach((e, i) => {
            result[i] = e;
        });
    }


    return (
        <>
            <div className="row py-2 mb-2 mt-4" style={{ backgroundColor: "#FBFBDB", border: "1px solid #922B21" }}>
                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस कोणाकडे :</label>
                    <div className='col-sm-3'>
                        <TextField
                            required
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="बक्षिस कोणाकडे"
                            variant="outlined"
                            margin='dense'
                            style={{ backgroundColor: "#F8F9F9" }}
                            value={selectedPrizeFrom ? selectedPrizeFrom.name : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ readOnly: true }}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस कोणाला :</label>
                    <div className='col-sm-3'>
                        <TextField
                            required
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="बक्षिस कोणाला"
                            variant="outlined"
                            margin='dense'
                            style={{ backgroundColor: "#F8F9F9" }}
                            value={selectedPrizeFor ? selectedPrizeFor.name : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ readOnly: true }}
                        />
                    </div>
                </div>

                {(() => {
                    if (initialText1 == 2) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस वितरण :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="बक्षिस वितरण"
                                            variant="outlined"
                                            margin='dense'
                                            style={{ backgroundColor: "#F8F9F9" }}
                                            value={selectedPrizeGiving ? selectedPrizeGiving.name : ""}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>

                                    {(() => {
                                        if (initialText18 == 2) {
                                            return (
                                                <>

                                                    {/* <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिसाचे वाटप :</label> */}
                                                    <div className='col-sm-3'>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size='small'
                                                            id="outlined-basic"
                                                            label="बक्षिसाचे वाटप"
                                                            variant="outlined"
                                                            margin='dense'
                                                            style={{ backgroundColor: "#F8F9F9" }}
                                                            value={selectedPrize ? selectedPrize.name : ""}
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                    </div>

                                                </>
                                            )
                                        }
                                    })()}
                                </div>
                            </>
                        )
                    }
                })()}



                {(() => {
                    if (initialText1 == 2 && initialText18 == 3) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शहर :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="शहर"
                                            variant="outlined"
                                            margin='dense'
                                            style={{ backgroundColor: "#F8F9F9" }}
                                            value={initialText21}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>

                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="रक्कम"
                                            InputProps={{ inputProps: { min: 1 } }}
                                            style={{ width: "100px", backgroundColor: "#F8F9F9" }}
                                            value={initialText4}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>

                                </div>
                            </>
                        )
                    }
                    else if (initialText1 == 2 && initialText18 == 4) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>विभाग :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="विभाग"
                                            variant="outlined"
                                            margin='dense'
                                            style={{ backgroundColor: "#F8F9F9" }}
                                            value={initialText22}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>

                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="रक्कम"
                                            InputProps={{ inputProps: { min: 1 } }}
                                            style={{ width: "100px", backgroundColor: "#F8F9F9" }}
                                            value={initialText4}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                    else if (initialText1 == 1 || (initialText1 == 2 && initialText18 == 2 && initialText19 == 1)) {
                        if (newData.length != 0) {
                            return (
                                newData.filter((e, index) => e.etranNo == initialText13 && e.branchId !== 0).map((e, ind) => {
                                    return (
                                        <>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शाळा {ind + 1} :</label>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                autoComplete='off'
                                                style={{ width: "100px", marginLeft: "10px", backgroundColor: "#F8F9F9" }}
                                                size='small'
                                                value={e.branchId}
                                                id="outlined-basic"
                                                label="शाळा क्र."
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ readOnly: true }}
                                            />

                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                style={{ width: "400px", marginLeft: "8px", backgroundColor: "#F8F9F9" }}
                                                size='small'
                                                value={e.schoolNameMr}
                                                id="outlined-basic"
                                                label="शाळेचे नाव"
                                                variant="outlined"
                                                margin='dense'
                                                //readOnly={true}
                                                inputProps={{ readOnly: true }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />

                                            <label hidden={(initialText5 == 2 && initialText7 == 2 && initialText6 == 2) ? true : false} className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                            {/* <div className='col-sm-2'> */}
                                            <TextField
                                                hidden={(initialText5 == 2 && initialText7 == 2 && initialText6 == 2) ? true : false}
                                                fullWidth
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="रक्कम"
                                                InputProps={{ inputProps: { min: 1 } }}
                                                style={{ width: "100px", backgroundColor: "#F8F9F9" }}
                                                value={e.amountInPercent}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ readOnly: true }}
                                            />
                                        </>
                                    )
                                })
                            )
                        }
                    }
                    else if (initialText1 == 2 && initialText18 == 2 && initialText19 == 2) {
                        return (
                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >शाळा :</label>
                                <div className='col-sm-7'>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        id="combo-box-demo"
                                        options={schoolDataForEndow}
                                        fullWidth
                                        filterSelectedOptions
                                        disableCloseOnSelect
                                        getOptionDisabled={(option) => option.disableOption === true}
                                        getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                                        disableClearable
                                        readOnly
                                        value={text18}
                                        style={{ fontSize: '14px' }}
                                        renderInput={(params) => (
                                            <TextField {...params} margin='dense' label="शाळा" variant="outlined"
                                                required={(text18.length == 0) ? true : false}
                                                InputLabelProps={{ style: { fontSize: 14 } }} />
                                        )}
                                    />
                                </div>
                            </div>
                        )
                    }
                })()}


                <div className="row my-2" hidden={(initialText2 == 2 || initialText2 == 3) ? true : false}>
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >इयत्ता :</label>
                    <div className='col-sm-3'>
                        <TextField
                            required
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="इयत्ता"
                            variant="outlined"
                            margin='dense'
                            style={{ backgroundColor: "#F8F9F9" }}
                            value={initialText10}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ readOnly: true }}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >क्रमांक :</label>
                    <div className='col-sm-3'>
                        <TextField
                            required
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="क्रमांक"
                            variant="outlined"
                            margin='dense'
                            style={{ backgroundColor: "#F8F9F9" }}
                            value={initialText6}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ readOnly: true }}
                        />
                    </div>


                    <label className='col-sm-2' hidden={(initialText6 <= 1 || initialText6 >= 6) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिसाची विभागणी ( % ) :</label>
                    {(() => {
                        if (initialText16 != null) {
                            if (initialText6 == 2) {
                                return (
                                    <>
                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9" }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[0]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="1st टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[1]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="2nd टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </>
                                )
                            }
                            else if (initialText6 == 3) {
                                return (
                                    <>
                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9" }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[0]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="1st टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[1]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="2nd टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[2]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="3rd टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </>
                                )
                            }
                            else if (initialText6 == 4) {
                                return (
                                    <>
                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9" }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[0]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="1st टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[1]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="2nd टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[2]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="3rd टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[3]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="4th टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </>
                                )
                            }
                            else if (initialText6 == 5) {
                                return (
                                    <>
                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9" }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[0]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="1st टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[1]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="2nd टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[2]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="3rd टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[3]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="4th टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            required={(initialText6 > 1 || initialText6 <= 3) ? true : false}
                                            fullWidth
                                            type="number"
                                            style={{ width: "100px", backgroundColor: "#F8F9F9", marginLeft: '8px' }}
                                            onWheel={(e) => e.target.blur()}
                                            value={result[4]}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="5th टक्केवारी"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </>
                                )
                            }
                        }

                    })()}

                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >विषय प्रकार :</label>
                    <div className='col-sm-3'>
                        <TextField
                            required
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="विषय प्रकार"
                            variant="outlined"
                            margin='dense'
                            style={{ backgroundColor: "#F8F9F9" }}
                            value={selectedSubType ? selectedSubType.name : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ readOnly: true }}
                        />
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >विषय :</label>
                    <div className='col-sm-3'>
                        <TextField
                            required
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="विषय"
                            variant="outlined"
                            margin='dense'
                            style={{ backgroundColor: "#F8F9F9" }}
                            value={initialText11}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ readOnly: true }}
                        />
                    </div>
                </div>

                {
                    (() => {
                        if (initialText1 == 2) {
                            return (
                                <div className="row my-2">
                                    <label className="col-sm-3" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>संस्था पातळीवरील बक्षिसाची माहिती:</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            required={(initialText1 == 2) ? true : false}
                                            fullWidth
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="संस्था पातळीवरील बक्षिसाची माहिती"
                                            value={initialText17}
                                            style={{ backgroundColor: "#F8F9F9" }}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    })()
                }

                <div className="row my-2" hidden={(initialText2 == 2 || initialText2 == 3) ? true : false}>
                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >लिंग :</label>
                    <div className='col-sm-6 mt-2'>
                        <RadioGroup row name="customized-radios" value={initialText8} onChange={(e) => e.preventDefault()}>
                            <FormControlLabel value={1} control={<Radio color='primary' />} label="विद्यार्थी / विद्यार्थिनी" />
                            <FormControlLabel value={2} control={<Radio color='primary' />} label="विद्यार्थिनी" />
                            <FormControlLabel value={3} control={<Radio color='primary' />} label="विद्यार्थी" />
                        </RadioGroup>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SchoolEndowmentCondition
