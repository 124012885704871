import React, { useState } from 'react'
import { useSelector } from "react-redux";

const ApprovalStatusSelectBox = ({ approvalStatus, initialText, initialText1, ssForm, onChange }) => {
    const authUser = useSelector((state) => state.user.value);
    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);
    const [approvalStausData, setapprovalStausData] = useState(approvalStatus.find((e) => e.id == text));

    return (
        isEdit ?
            <td style={{ width: '170px', fontSize: "15px", fontWeight: '500' }}>
                <select value={text}
                    onChange={(e) => { setText(e.target.value); setapprovalStausData(approvalStatus.find((a) => a.id == e.target.value)); onChange(e.target.value) }}
                    className='form-select form-select-sm'
                    style={{ width: "130px", fontSize: "15px", fontWeight: '500' }}
                    onBlur={() => { setEdit(false) }}
                    // disabled={(initialText == 1 || initialText1 == "Unallocated") ? true : false}
                    // disabled={(initialText1 == "Unallocated") ? true : false}
                    disabled={(ssForm == 723) ? true : false}
                    autoFocus
                >
                    {approvalStatus.map((e) => {
                        return (
                            <option value={e.id} style={{ fontSize: "15px", fontWeight: '500' }}>
                                {e.name}
                            </option>
                        )
                    })}

                </select>
            </td>

            :
            <td style={{ width: '170px', fontSize: "15px", fontWeight: '500' }} onClick={() => setEdit(true)}>{approvalStausData.name}</td>
    )
}


export default ApprovalStatusSelectBox
