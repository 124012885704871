import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../CommonComponent/TitleLabel';
import Loader from '../../CommonComponent/Loader';
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import axios from 'axios';
import { getClassesBySectionAndBranch } from '../../Exam/Services/ExamMarkAllocationAPIURL';
import { getProcessExamDataAPIURL, saveProcessExamDataAPIURL } from './Services/ProcessExamDataAPIURL';
import moment from 'moment';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));

const ProcessExamData = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Process Exam Data"
    const [loaderOption, setLoaderOption] = useState(false);

    const [optionId, setOptionId] = useState(1);
    const [date, setDate] = useState(new Date().setFullYear(new Date().getFullYear() - 1));
    const [process, setProcess] = useState(false);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                    // setLoaderOption(false);
                });

            await axios(`${getProcessExamDataAPIURL}?year=${moment(date).format('YYYY')}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setLoaderOption(false);
                });
        }
    }

    const getProcessingData = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        await axios.post(`${saveProcessExamDataAPIURL}?option=${optionId}&classId=${(optionId == 2) ? classObj.id : 0}&year=${moment(date).format('YYYY')}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}`)
            .then(res => {
                if (res.data == "SAVED") {
                    setLoaderOption(false);
                    getData();
                    toast.success("Processing done successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Process exam data save err" + err);
            })
    }

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '500px' }}>
                        {/* Form Data start */}
                        <form onSubmit={getProcessingData}>
                            <div className='row my-1'>
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                <div className='col-sm-2'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={optionId}
                                            label="Select Option"
                                            onChange={(e) => {
                                                setOptionId(e.target.value);
                                                setProcess(false);
                                                setClassObj(null);
                                                setDate(new Date().setFullYear(new Date().getFullYear() - 1));
                                            }}
                                        >
                                            <MenuItem value={1}>All</MenuItem>
                                            <MenuItem value={2}>Selected Class</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Year :</label>
                                <div className='col-sm-2'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={['year']}
                                            label="Year"
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue);
                                                setProcess(false);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                            inputFormat="yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete="off"
                                                    size="small"
                                                // readOnly // Prevent typing
                                                // inputProps={{ ...params.inputProps, readOnly: true }} // Further restrict input
                                                />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                                </div>
                            </div>

                            <div className="row my-2">
                                {(() => {
                                    if (optionId == 2) {
                                        return (
                                            <>
                                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                                                <div className='col-sm-2'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={classData}
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        value={classObj}
                                                        onChange={(event, newValue) => {
                                                            setClassObj(newValue);
                                                            setDate(new Date().setFullYear(new Date().getFullYear() - 1));
                                                        }}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }
                                })()}
                            </div>

                            <div className="row mt-2">
                                <div className="col-sm-4 offset-sm-1">
                                    <button type='submit' className='btn btn-sm btn-primary'>Process</button>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div className='table-responsive col-sm-5 offset-sm-1 mt-4'>

                                <table className="table table-bordered">
                                    <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                        <tr>
                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Class Name</th>
                                            <th style={{ fontSize: "15px", fontWeight: "600" }}>Updated Date</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={index} className="">
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{index + 1}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.classMr : item.classEn}</td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.updatedDate}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default ProcessExamData
