const SansthaEndowmentJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/EndowmentDash/",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 701,
                SubPath: "/Home/m701/0",
                SubName: "Endowment Master"
            },
            {
                id: 715,
                SubPath: "/Home/m715/",
                SubName: "Subject Master"
            },
            {
                id: 718,
                SubPath: "/Home/m718/",
                SubName: "Region Master"
            },
            {
                id: 719,
                SubPath: "/Home/m719/",
                SubName: "Region To School Allocation"
            },
            {
                id: 720,
                SubPath: "/Home/m720/",
                SubName: "Dept. To School Allocation"
            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Transaction",
        LinkIcon: "account_balance_wallet",
        DropDownSubMenu: [
            {
                id: 706,
                SubPath: "/Home/et706/",
                SubName: "Endowment Interest Calculation"
            },
            {
                id: 724,
                SubPath: "/Home/et722/724",
                SubName: "Student To Sanstha Prize Allocation"
            },
        ]
    },
    // {
    //     id: 703,
    //     MenuType: "normal",
    //     PathName: "/Home/404/",
    //     Title: "Transaction",
    //     LinkIcon: "account_balance_wallet"
    // },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Reports",
        LinkIcon: "receipt_long",
        MegaMenuDropDown: [
            {
                HeaddingName: "Endowment Reports",
                MegaMenuSubItems: [
                    {
                        id: 704,
                        SubPath: "/Home/er704/",
                        SubName: "Endowment List"
                    },
                    {
                        id: 705,
                        SubPath: "/Home/er705/",
                        SubName: "Endowment Transactions"
                    },
                    {
                        id: 703,
                        SubPath: "/Home/er703/",
                        SubName: "Endowment Interest Report"
                    },
                    {
                        id: 708,
                        SubPath: "/Home/er708/",
                        SubName: "School Wise Endowment"
                    },
                    {
                        id: 709,
                        SubPath: "/Home/er709/",
                        SubName: "Sanstha Award Distribution"
                    },
                    {
                        id: 721,
                        SubPath: "/Home/er721/",
                        SubName: "StudentWise Award Distribution"
                    },
                ]

            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                   
                    {
                        id: 723,
                        SubPath: "/Home/er723/",
                        SubName: "SchoolWise Award Distribution"
                    },
                    {
                        id: 711,
                        SubPath: "/Home/er711/",
                        SubName: "Sanstha Award Distribution Summary"
                    },
                    {
                        id: 724,
                        SubPath: "/Home/er724/",
                        SubName: "Office Copy Report"
                    },
                    {
                        id: 710,
                        SubPath: "/Home/er710/",
                        SubName: "Sanstha Award Blank"
                    },
                    {
                        id: 712,
                        SubPath: "/Home/er712/",
                        SubName: "Classwise Endowment Report"
                    },
                    {
                        id: 713,
                        SubPath: "/Home/er713/",
                        SubName: "Subjectwise Endowment Report"
                    },
                   
                ]
            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                   
                    {
                        id: 714,
                        SubPath: "/Home/er714/",
                        SubName: "Genderwise Endowment Report"
                    },
                    {
                        id: 716,
                        SubPath: "/Home/er716/",
                        SubName: "Endowment Register"
                    },
                    {
                        id: 717,
                        SubPath: "/Home/er717/",
                        SubName: "Schoolwise Endowmentwise Detail Report"
                    },
                    {
                        id: 727,
                        SubPath: "/Home/er727/",
                        SubName: "Schoolwise Endowment List"
                    },
                    {
                        id: 722,
                        SubPath: "/Home/er722/",
                        SubName: "Allocated Prizes List"
                    },

                ]
            },

        ]
    },
    // {
    //     id: 704,
    //     MenuType: "normal",
    //     PathName: "/Home/404/",
    //     Title: "Reports",
    //     LinkIcon: "receipt_long"
    // },


]

export default SansthaEndowmentJSON;