import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { Autocomplete, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, TablePagination, TextField, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getAmountListForManualAPIURL, getEmployeeListForManualAPIURL, getEndowmentIdAPIURL, getPrizeDetailsData, getSchoolStudentListForManualAPIURL, getStudentApprovalAllocationData, getStudentListForAutomaticAPIURL, getStudentListForManualAPIURL, getStudentListForSantshaPrizeAllocForAutomaticAPIURL, saveSansthaStudentListForManualAPIURL, saveStudentListForManualAPIURL } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faBullseye, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConditionDetailsTable from './ConditionDetailsTable';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AddStudentSrNoTextBox from './AddStudentSrNoTextBox';
import { toast } from 'react-toastify';
import { GetYearAPIURL } from '../../../CommonServices/LoginAPIURL';
import AddStudentAmountTextBox from './AddStudentAmountTextBox';
import { ClassMasterGetMapping } from '../../../CommonServices/ClassMasterAPIURL';
import { getSubjectData } from '../../../Exam/Services/ExamReportAPIURL';
import { getClassesBySectionAndBranch } from '../../../Exam/Services/ExamMarkAllocationAPIURL';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));


const ConditionAddStudent = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Add Students"
    const [loaderOption, setLoaderOption] = useState(false);
    const { prId, etran, prFor, ssForm } = useParams();

    const location = useLocation();

    // Access paritoshikName from location state
    const prName = location.state?.paritoshikName;
    const praizeId = location.state?.pId

    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    // console.log(JSON.stringify(data));
    const [option, setOption] = useState(1);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [date, setDate] = useState(new Date().setFullYear(new Date().getFullYear() - 1));
    const [checkValue, setCheckValue] = useState([]);
    const [Val, setVal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [chkRegNum, setChkRegNum] = useState('');
    const [srNo, setSrNo] = useState('');

    const [tempData, setTempData] = useState([]);
    const [year, setYear] = useState([]);
    const [yearIndex, setYearIndex] = useState("");
    const [yearLabel, setYearLabel] = useState("");
    const [amtData, setAmtData] = useState([]);
    const [values, setValues] = useState('');
    const [newFilterData, setNewFilterData] = useState([]);
    const [automaticData, setAutomaticData] = useState([]);
    const [automaticFilterData, setAutomaticFilterData] = useState([]);
    const [automaticNewFilterData, setAutomaticNewFilterData] = useState([]);
    const [tempAutomaticData, setTempAutomaticData] = useState([]);
    const [amount, setAmount] = useState('');

    const [regNum, setRegNum] = useState('');
    const [updatedSrNo, setUpdatedSrNo] = useState(null);
    const [updatedAmount, setUpdatedAmount] = useState(null);
    const [updatedIsAllocated, setUpdatedIsAllocated] = useState(0);
    const [updatedObtainedMarks, setUpdatedObtainedMarks] = useState(null);

    const [autoRegNum, setAutoRegNum] = useState('');
    const [updatedAutoSrNo, setUpdatedAutoSrNo] = useState(null);
    const [updatedAutoAmount, setUpdatedAutoAmount] = useState(null);
    const [updatedAutoIsAllocated, setUpdatedAutoIsAllocated] = useState(0);


    const [endowId, setEndowId] = useState('');
    // console.log(endowId)

    useEffect(() => {
        const updatedData = data.map(item => ({
            ...item,
            // Set amount from amtData only if it has not been manually set
            amount: amtData[item.srNo] !== undefined && item.amount === 0 ? amtData[item.srNo] : item.amount,
        }));
        setFilteredData(updatedData);
        setData(updatedData);

        const updatedAutoData = automaticData.map(item => ({
            ...item,
            // Set amount from amtData only if it has not been manually set
            amount: amtData[item.srNo] !== undefined && item.amount === 0 ? amtData[item.srNo] : item.amount,
        }));
        setAutomaticFilterData(updatedAutoData);
        setAutomaticData(updatedAutoData);
    }, []);

    // console.log(JSON.stringify(newFilterData))

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `योजनेची माहिती`, key: 'approvalStatus' },
        { label: `Action`, key: 'action', isAction: true },
    ];

    // useEffect(() => {
    //     if (searchTerm == "") {
    //         console.log("dat=======" + JSON.stringify(tempData))
    //         setFilteredData([...tempData]);
    //     }
    // }, [searchTerm, tempData]);

    const handleCheckChange = (index) => {
        const updatedFilteredData = [...filteredData];
        updatedFilteredData[index].isAllocated = updatedFilteredData[index].isAllocated === 1 ? 0 : 1;
        setData(updatedFilteredData);
        // setFilteredData(updatedFilteredData);
        setRegNum(updatedFilteredData[index].regNo)

        if (prFor == 1) {
            const originalDataIndex = data.findIndex(item => item.regNo === updatedFilteredData[index].regNo);
            if (originalDataIndex !== -1) {
                const updatedData = [...data];
                updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
                setUpdatedIsAllocated(updatedFilteredData[index].isAllocated);
                if (searchTerm == "") {
                    setData(updatedData);
                    setFilteredData(updatedData);
                }
            }
        }
        else if (prFor == 2) {
            const originalDataIndex = data.findIndex(item => item.empId === updatedFilteredData[index].empId);
            if (originalDataIndex !== -1) {
                const updatedData = [...data];
                updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
                setUpdatedIsAllocated(updatedFilteredData[index].isAllocated);
                if (searchTerm == "") {
                    setData(updatedData);
                    setFilteredData(updatedData);
                }
            }
        }
    };

    const handleCheckAutoChange = (index) => {
        const updatedFilteredData = [...automaticFilterData];
        updatedFilteredData[index].isAllocated = updatedFilteredData[index].isAllocated === 1 ? 0 : 1;
        setAutomaticData(updatedFilteredData);
        // setAutomaticFilterData(updatedFilteredData);

        setAutoRegNum(updatedFilteredData[index].regNo)

        const originalDataIndex = automaticData.findIndex(item => item.regNo === updatedFilteredData[index].regNo);
        if (originalDataIndex !== -1) {
            const updatedData = [...automaticData];
            updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
            setUpdatedAutoIsAllocated(updatedFilteredData[index].isAllocated);
            if (searchTerm == "") {
                setAutomaticData(updatedData);
                setAutomaticFilterData(updatedData);
            }
        }
    };

    const handleDataInputChange = (index, field, value) => {
        const updatedFilteredData = [...filteredData];
        const updatedData = [...data]; // Work on the complete data set for consistency


        if (prFor == 1) {
            // Check if the field being updated is 'srNo'

            if (field === 'srNo') {
                const newSrNo = Number(value);
                updatedFilteredData[index][field] = newSrNo; // Update the srNo in filteredData for the specific record
                updatedData[data.findIndex(item => item.regNo === updatedFilteredData[index].regNo)].srNo = newSrNo; // Sync with data

                // Find all records with this new srNo across the complete data set
                const sameSrNoRecords = updatedData.filter(item => item.srNo === newSrNo);

                // Calculate total amount for this srNo from amtData
                const totalAmountForSrNo = amtData[newSrNo] !== undefined ? amtData[newSrNo] : 0;

                // Divide the amount among records with the same srNo
                const amountPerRecord = sameSrNoRecords.length > 0 ? totalAmountForSrNo / sameSrNoRecords.length : 0;

                // Update amounts for all records with the same srNo in both data and filteredData
                sameSrNoRecords.forEach(record => {
                    const recordIndexInData = updatedData.findIndex(item => item.regNo === record.regNo);
                    if (recordIndexInData !== -1) {
                        updatedData[recordIndexInData].amount = amountPerRecord; // Update in complete data set
                    }

                    const recordIndexInFiltered = updatedFilteredData.findIndex(item => item.regNo === record.regNo);
                    if (recordIndexInFiltered !== -1) {
                        updatedFilteredData[recordIndexInFiltered].amount = amountPerRecord; // Update in filtered data
                    }
                });
            } else {
                // Directly update other fields (e.g., amount)
                updatedFilteredData[index][field] = Number(value);
            }

            setData(updatedData); // Update the complete data state
            setFilteredData(updatedFilteredData); // Update the filtered data state

            setRegNum(updatedFilteredData[index].regNo)
            setUpdatedSrNo(updatedFilteredData[index].srNo);
            setUpdatedAmount(updatedFilteredData[index].amount);

            // Update original data using the unique identifier (regNo)
            // const originalDataIndex = data.findIndex(item => item.regNo === updatedFilteredData[index].regNo);
            // if (originalDataIndex !== -1) {
            //     const updatedData = [...data];
            //     updatedData[originalDataIndex][field] = field === 'srNo' ? updatedFilteredData[index].srNo : Number(value);
            //     // console.log(JSON.stringify(updatedData) + "========" + searchTerm)
            //     setData(updatedData)
            //     // Check if the search term is cleared
            //     if (searchTerm == "") {
            //         // If cleared, reset the filtered data to the original data
            //         setFilteredData(updatedData);

            //     }
            // }
        }
        else if (prFor == 2) {
            // Check if the field being updated is 'srNo'
            if (field === 'srNo') {
                const newSrNo = Number(value);
                updatedFilteredData[index][field] = newSrNo; // Update the srNo in filteredData for the specific record
                updatedData[data.findIndex(item => item.empId === updatedFilteredData[index].empId)].srNo = newSrNo; // Sync with data

                // Find all records with this new srNo across the complete data set
                const sameSrNoRecords = updatedData.filter(item => item.srNo === newSrNo);

                // Calculate total amount for this srNo from amtData
                const totalAmountForSrNo = amtData[newSrNo] !== undefined ? amtData[newSrNo] : 0;

                // Divide the amount among records with the same srNo
                const amountPerRecord = sameSrNoRecords.length > 0 ? totalAmountForSrNo / sameSrNoRecords.length : 0;

                // Update amounts for all records with the same srNo in both data and filteredData
                sameSrNoRecords.forEach(record => {
                    const recordIndexInData = updatedData.findIndex(item => item.empId === record.empId);
                    if (recordIndexInData !== -1) {
                        updatedData[recordIndexInData].amount = amountPerRecord; // Update in complete data set
                    }

                    const recordIndexInFiltered = updatedFilteredData.findIndex(item => item.empId === record.empId);
                    if (recordIndexInFiltered !== -1) {
                        updatedFilteredData[recordIndexInFiltered].amount = amountPerRecord; // Update in filtered data
                    }
                });
            } else {
                // Directly update other fields (e.g., amount)
                updatedFilteredData[index][field] = Number(value);
            }

            setData(updatedData); // Update the complete data state
            setFilteredData(updatedFilteredData); // Update the filtered data state

            setRegNum(updatedFilteredData[index].empId)
            setUpdatedSrNo(updatedFilteredData[index].srNo);
            setUpdatedAmount(updatedFilteredData[index].amount);

            // Update original data using the unique identifier (empId)
            // const originalDataIndex = data.findIndex(item => item.empId === updatedFilteredData[index].empId);
            // if (originalDataIndex !== -1) {
            //     const updatedData = [...data];
            //     updatedData[originalDataIndex][field] = field === 'srNo' ? updatedFilteredData[index].srNo : Number(value);
            //     setData(updatedData)
            //     // console.log(JSON.stringify(updatedData))
            //     // Check if the search term is cleared
            //     if (searchTerm == "") {
            //         // If cleared, reset the filtered data to the original data
            //         setFilteredData(updatedData);

            //     }
            // }
        }
    };

    const handleSansthaDataInputChange = (index, field, value) => {

        if (prFor == 1) {
            const updatedFilteredData = [...filteredData];
            // Check if the field being updated is 'srNo'
            // const updatedFilteredData = [...filteredData];
            updatedFilteredData[index] = { ...updatedFilteredData[index], [field]: value };

            // Step 2: Update the original data with the new value for the matching regNo
            const updatedData = data.map((item) =>
                item.regNo === updatedFilteredData[index].regNo
                    ? { ...item, [field]: value }
                    : item
            );

            // Step 3: Update the states
            setFilteredData(updatedFilteredData);
            setData(updatedData);

            setUpdatedObtainedMarks(updatedFilteredData[index].obtainMarks)

            // console.log(JSON.stringify(updatedData))
        }
        else if (prFor == 2) {
            const updatedFilteredData = [...filteredData];
            // Check if the field being updated is 'srNo'
            // const updatedFilteredData = [...filteredData];
            updatedFilteredData[index] = { ...updatedFilteredData[index], [field]: value };

            // Step 2: Update the original data with the new value for the matching regNo
            const updatedData = data.map((item) =>
                item.empId === updatedFilteredData[index].empId
                    ? { ...item, [field]: value }
                    : item
            );

            // Step 3: Update the states
            setFilteredData(updatedFilteredData);
            setData(updatedData);

            // console.log(JSON.stringify(updatedData))
            setUpdatedObtainedMarks(updatedFilteredData[index].obtainMarks)
        }
    };

    // useEffect(() => {

    // }, [searchTerm, ssForm]);

    useEffect(() => {
        if (ssForm == 723) {
            if (prFor == 1) {

                if (updatedObtainedMarks !== null) {
                    const updatedData = [...tempData];

                    const itemIndex = updatedData.findIndex(item => item.regNo === regNum);
                    if (itemIndex !== -1) {
                        updatedData[itemIndex].obtainMarks = updatedObtainedMarks;
                        updatedData[itemIndex].isAllocated = updatedIsAllocated;
                    }

                    setData(updatedData);
                    // setTempData(updatedData);
                    console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                    if (searchTerm == "") {
                        setFilteredData(updatedData);
                        console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                    }

                    // Reset state values after updating
                    setUpdatedObtainedMarks(null);
                    setUpdatedIsAllocated(0);
                }

            }
            else if (prFor == 2) {
                if (updatedObtainedMarks !== null) {
                    const updatedData = [...tempData];

                    const itemIndex = updatedData.findIndex(item => item.empId === regNum);
                    if (itemIndex !== -1) {
                        updatedData[itemIndex].obtainMarks = updatedObtainedMarks;
                        updatedData[itemIndex].isAllocated = updatedIsAllocated;
                    }

                    setData(updatedData);
                    // setTempData(updatedData);
                    // console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                    if (searchTerm == "") {
                        setFilteredData(updatedData);
                        // console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                    }

                    // Reset state values after updating
                    setUpdatedObtainedMarks(null);
                    setUpdatedIsAllocated(0);
                }
            }
        }
        else {
            if (prFor == 1) {
                if (option == 1) {
                    if (updatedSrNo !== null || updatedAmount !== null || updatedIsAllocated !== 0) {
                        const updatedData = [...tempData];

                        const itemIndex = updatedData.findIndex(item => item.regNo === regNum);
                        if (itemIndex !== -1) {
                            updatedData[itemIndex].srNo = updatedSrNo;
                            updatedData[itemIndex].amount = updatedAmount;
                            updatedData[itemIndex].isAllocated = updatedIsAllocated;
                        }

                        setData(updatedData);
                        // setTempData(updatedData);
                        // console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                        if (searchTerm == "") {
                            setFilteredData(updatedData);
                            // console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                        }

                        // Reset state values after updating
                        setUpdatedSrNo(null);
                        setUpdatedAmount(null);
                        setUpdatedIsAllocated(0);
                    }
                }
                else if (option == 2) {
                    if (updatedAutoSrNo !== null || updatedAutoAmount !== null || updatedAutoIsAllocated !== 0) {
                        const updatedData = [...tempAutomaticData];


                        const itemIndex = updatedData.findIndex(item => item.regNo === autoRegNum);
                        if (itemIndex !== -1) {
                            updatedData[itemIndex].srNo = updatedAutoSrNo;
                            updatedData[itemIndex].amount = updatedAutoAmount;
                            updatedData[itemIndex].isAllocated = updatedAutoIsAllocated;
                        }

                        setAutomaticData(updatedData);
                        // console.log(JSON.stringify(updatedData))
                        if (searchTerm == "") {
                            setAutomaticFilterData(updatedData);
                            // console.log(JSON.stringify(updatedData))
                        }

                        // Reset state values after updating
                        setUpdatedAutoSrNo(null);
                        setUpdatedAutoAmount(null);
                        setUpdatedAutoIsAllocated(0);
                    }
                }
            }
            else if (prFor == 2) {
                if (updatedSrNo !== null || updatedAmount !== null || updatedIsAllocated !== 0) {
                    const updatedData = [...tempData];

                    const itemIndex = updatedData.findIndex(item => item.empId === regNum);
                    if (itemIndex !== -1) {
                        updatedData[itemIndex].srNo = updatedSrNo;
                        updatedData[itemIndex].amount = updatedAmount;
                        updatedData[itemIndex].isAllocated = updatedIsAllocated;
                    }

                    setData(updatedData);
                    // setTempData(updatedData);
                    // console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                    if (searchTerm == "") {
                        setFilteredData(updatedData);
                        // console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                    }

                    // Reset state values after updating
                    setUpdatedSrNo(null);
                    setUpdatedAmount(null);
                    setUpdatedIsAllocated(0);
                }
            }
        }

    }, [searchTerm, ssForm]);

    // useEffect(() => {

    // }, [option, searchTerm, autoRegNum]);

    const handleAutoDataInputChange = (index, field, value) => {
        const updatedFilteredData = [...automaticFilterData];
        const updatedData = [...automaticData];

        if (field === 'srNo') {
            const newSrNo = Number(value);
            updatedFilteredData[index][field] = newSrNo; // Update the srNo in filteredData for the specific record
            updatedData[automaticData.findIndex(item => item.regNo === updatedFilteredData[index].regNo)].srNo = newSrNo; // Sync with data

            // Find all records with this new srNo across the complete data set
            const sameSrNoRecords = updatedData.filter(item => item.srNo === newSrNo);

            // Calculate total amount for this srNo from amtData
            const totalAmountForSrNo = amtData[newSrNo] !== undefined ? amtData[newSrNo] : 0;

            // Divide the amount among records with the same srNo
            const amountPerRecord = sameSrNoRecords.length > 0 ? totalAmountForSrNo / sameSrNoRecords.length : 0;

            // Update amounts for all records with the same srNo in both data and filteredData
            sameSrNoRecords.forEach(record => {
                const recordIndexInData = updatedData.findIndex(item => item.regNo === record.regNo);
                if (recordIndexInData !== -1) {
                    updatedData[recordIndexInData].amount = amountPerRecord; // Update in complete data set
                }

                const recordIndexInFiltered = updatedFilteredData.findIndex(item => item.regNo === record.regNo);
                if (recordIndexInFiltered !== -1) {
                    updatedFilteredData[recordIndexInFiltered].amount = amountPerRecord; // Update in filtered data
                }
            });
        } else {
            // Directly update other fields (e.g., amount)
            updatedFilteredData[index][field] = Number(value);
        }

        setAutomaticData(updatedData); // Update the complete data state
        setAutomaticFilterData(updatedFilteredData); // Update the filtered data state

        setAutoRegNum(updatedFilteredData[index].regNo)
        setUpdatedAutoSrNo(updatedFilteredData[index].srNo);
        setUpdatedAutoAmount(updatedFilteredData[index].amount);

        // Update original data using the unique identifier (regNo)
        const originalDataIndex = automaticData.findIndex(item => item.regNo === updatedFilteredData[index].regNo);
        if (originalDataIndex !== -1) {
            const updatedData = [...automaticData];
            updatedData[originalDataIndex][field] = field === 'srNo' ? updatedFilteredData[index].srNo : Number(value);
            // Check if the search term is cleared
            if (searchTerm == "") {
                // If cleared, reset the filtered data to the original data
                setAutomaticFilterData(updatedData);
                setAutomaticData(updatedData)
            }
        }
    };

    useEffect(() => {
        getData();
    }, [authUser, prFor, option,ssForm])

    const getData = async () => {
        setLoaderOption(true);
        console.log(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        if (authUser.sessionYear != "" && authUser.branchId != null && authUser.deptId != null) {
            // console.log(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setAmtData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })

            await axios(`${getEndowmentIdAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setEndowId(res.data);
                });
        }


        if (prFor == 1) {
            if (option == 1) {
                if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
                    if (ssForm == 722) {
                        await axios.get(`${getSchoolStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then(res => {
                                // console.log(JSON.stringify(newData)); // Log newData for debugging
                                setData(res.data);
                                setFilteredData(res.data);
                                setTempData(res.data);
                                // setNewCombineData(res.data);
                                setLoaderOption(false);
                            }).catch(err => {
                                setLoaderOption(false);
                                console.log("Student approval get err" + err);
                            })
                    }
                    else if (ssForm == 723) {
                        await axios.get(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then(res => {
                                // console.log(JSON.stringify(newData)); // Log newData for debugging
                                setData(res.data);
                                setFilteredData(res.data);
                                setTempData(res.data);
                                // setNewCombineData(res.data);
                                setLoaderOption(false);
                            }).catch(err => {
                                setLoaderOption(false);
                                console.log("Student approval get err" + err);
                            })
                    }

                }
            }


            await axios.get(GetYearAPIURL)
                .then((response) => {
                    // console.log(JSON.stringify(response.data))
                    setYear(response.data);
                    setYearIndex(0);

                    const matchingIndex = response.data.findIndex(
                        (item) => item.yearLabel == authUser.yearLabel
                    );

                    if (matchingIndex !== -1) {
                        setYearIndex(matchingIndex); // Set the index to the matching year
                        // console.log(matchingIndex)
                        setYearLabel(response.data[matchingIndex].id);
                    } else {
                        setYearIndex(0); // Default to first item if no match is found
                        setYearLabel(response.data[0].id);
                    }
                    setLoaderOption(false);
                })

            if (authUser.branchId != null && authUser.deptId != null) {
                await axios(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setClassData(res.data);
                    });
            }

            await axios.get(`${getSubjectData}`)
                .then((res) => {
                    setSubjectData(res.data);
                }).catch(error => {
                    console.log(error);
                })
        }
        else if (prFor == 2) {
            if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
                await axios.get(`${getEmployeeListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        // setNewCombineData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })

            }
        }



    }

    const getAutomaticData = async () => {
        setLoaderOption(true);
        if (ssForm == 723) {
            console.log(`${getStudentListForSantshaPrizeAllocForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getStudentListForSantshaPrizeAllocForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    // console.log(JSON.stringify(newData)); // Log newData for debugging
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setAutomaticData(res.data);
                        setAutomaticFilterData(res.data);
                        setTempAutomaticData(res.data);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
        else {
            console.log(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    // console.log(JSON.stringify(newData)); // Log newData for debugging
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setAutomaticData(res.data);
                        setAutomaticFilterData(res.data);
                        setTempAutomaticData(res.data);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
    }

    const handleYear = (index) => {
        setAutomaticData([]);
        setAutomaticFilterData([]);
        // console.log(year[index].id);
        setYearIndex(index);
        setYearLabel(year[index].id); // Use yearId instead of index
    };

    const getSTudentListForOptions = async (val) => {
        if (val == 1) {
            // console.log(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {

                if (ssForm == 722) {
                    await axios.get(`${getSchoolStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            // console.log(JSON.stringify(newData)); // Log newData for debugging
                            setData(res.data);
                            setFilteredData(res.data);
                            setTempData(res.data);
                            // setNewCombineData(res.data);
                            setLoaderOption(false);
                        }).catch(err => {
                            setLoaderOption(false);
                            console.log("Student approval get err" + err);
                        })
                }
                else if (ssForm == 723) {
                    await axios.get(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            setData(res.data);
                            setFilteredData(res.data);
                            setTempData(res.data);
                            setLoaderOption(false);
                        }).catch(err => {
                            setLoaderOption(false);
                            console.log("Student approval get err" + err);
                        })
                }

            }
        }
        else if (val == 2) {
            if (classObj != null && subjectObj != null) {
                console.log(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getStudentListForAutomaticAPIURL}?prizeCode=${prId}&etranNo=${etran}&examYear=${yearLabel}&classId=${classObj.id}&subId=${subjectObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setAutomaticData(res.data);
                        setAutomaticFilterData(res.data);
                        setTempAutomaticData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
        }
        else {
            setData([]);
            setFilteredData([]);
            setAutomaticData([]);
            setAutomaticFilterData([]);
        }
    }

    useEffect(() => {
        // if (searchTerm == "") {
        //     setFilteredData([]);
        //     setAutomaticFilterData([]);
        // }
        // else {
        if (prFor == 1) {
            if (option == 1) {
                setFilteredData([])
                let tempFilteredData = data.filter((item) =>
                    (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
                setFilteredData(tempFilteredData)
            }
            else if (option == 2) {
                setAutomaticFilterData([])
                let tempFilteredData = automaticData.filter((item) =>
                    (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.maxMark || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.obtainMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
                setAutomaticFilterData(tempFilteredData)
            }
        }
        else if (prFor == 2) {
            setFilteredData([])
            let tempFilteredData = data.filter((item) =>
                (item.empNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.empNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.empId || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredData(tempFilteredData)
        }
        // }
    }, [searchTerm, option, prFor])


    const handleInputChange = (e, key) => {
        const updatedValue = e.target.value;
        setAmtData({
            ...amtData,
            [key]: updatedValue, // Update the specific key's value
        });
    };

    const saveManualStudentList = (e) => {
        //e.preventDefault();
        setLoaderOption(true);
        const newData = tempData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: (prFor == 1) ? e.regNo : e.empId,
            srNo: e.srNo,
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })


        }


    }

    const saveAutomaticStudentList = (e) => {
        //e.preventDefault();
        setLoaderOption(true);
        const newData = tempAutomaticData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: e.regNo,
            srNo: e.srNo,
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount for ${srNo}st rank.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
    }

    const saveManualStudentForSanstha = () => {
        setLoaderOption(true);
        // {
        //     "prizeCode": 1329,
        //     "etranNo": 1,
        //     "regNo": 7799,
        //     "status": 0,
        //     "branchId": 1,
        //     "isAllocated": 1,
        //     "sessionYear": 2024,
        //     "obtainedMarks": 99
        // },

        const newData = filteredData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: (prFor == 1) ? e.regNo : e.empId,
            srNo: e.srNo,
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            obtainedMarks: e.obtainMarks
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveSansthaStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                obtainedMarks: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveSansthaStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })


        }
    }

    const saveSansthaAutomaticStudentList = (e) => {
        //e.preventDefault();
        setLoaderOption(true);
        const newData = tempAutomaticData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: e.regNo,
            srNo: e.srNo,
            branchId: endowId,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            obtainedMarks: e.obtainMarks,
            maxMark: e.maxMark
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveSansthaStudentListForManualAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount for ${srNo}st rank.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: endowId,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                obtainedMarks: 0,
                maxMark: 0
            }]
            console.log(JSON.stringify(saveObj));
            axios.post(`${saveSansthaStudentListForManualAPIURL}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setAutomaticData([]);
                        setAutomaticFilterData([]);
                        setTempData([]);
                        setOption(1);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
    }

    const saveManualStudentTeacherPrizeData = async (e) => {
        e.preventDefault();
        if (ssForm == 723) {
            saveManualStudentForSanstha();
        }
        else {
            saveManualStudentList();
        }
    }

    const saveAutoStudentTeacherPrizeData = async (e) => {
        e.preventDefault();
        if (ssForm == 723) {
            saveSansthaAutomaticStudentList();
        }
        else {
            saveAutomaticStudentList();
        }
    }

    // console.log(JSON.stringify(filteredData)); // Log combined data for debugging


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        <div className=''>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>Prize Name : <span style={{ color: '#fe3627', fontWeight: '600' }}>{prName}</span></span>
                        </div>
                        <hr />
                        {(() => {
                            if (prFor == 1) {
                                return (
                                    <>
                                        <div className="row my-2">
                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                            <div className="col-sm-3">
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Option</InputLabel>
                                                    <Select
                                                        MenuProps={{ classes: { paper: classes.select } }}
                                                        size='small'
                                                        fullWidth
                                                        //inputProps={{ readOnly: true }}
                                                        margin="dense"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={option}
                                                        label="Option"
                                                        onChange={(e) => {
                                                            setOption(e.target.value);
                                                            setData([]);
                                                            setFilteredData([]);
                                                            setAutomaticData([]);
                                                            setAutomaticFilterData([]);
                                                            setClassObj(null);
                                                            setSubjectObj(null);
                                                            getSTudentListForOptions(e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value={1}>Manual</MenuItem>
                                                        <MenuItem value={2}>Automatic</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            {/* {(() => {
                                                if (data.length == 0) {
                                                    return (
                                                        <>
                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                        </>
                                                    )
                                                }
                                            })()} */}
                                        </div>



                                        {(() => {
                                            if (option == 2) {
                                                return (
                                                    <>
                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                            <div className='col-sm-3'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={classData}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    value={classObj}
                                                                    onChange={(event, newValue) => {
                                                                        setClassObj(newValue);
                                                                        setSubjectObj(null);
                                                                        setAutomaticData([]);
                                                                        setAutomaticFilterData([]);
                                                                    }}
                                                                    style={{ fontSize: '14px' }}
                                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} margin='dense' label="Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                            <div className='col-sm-3'>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={subjectData}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                    )}
                                                                    value={subjectObj}
                                                                    onChange={(event, newValue) => {
                                                                        setSubjectObj(newValue);
                                                                        setAutomaticData([]);
                                                                        setAutomaticFilterData([]);
                                                                    }}
                                                                    style={{ fontSize: '14px' }}
                                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                    size="small"
                                                                    renderInput={params => (
                                                                        <TextField {...params} margin='dense' label="Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row my-2">
                                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Year :</label>
                                                            <div className='col-sm-3'>
                                                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        views={['year']}
                                                                        label="Year"
                                                                        value={date}
                                                                        onChange={(newValue) => {
                                                                            setDate(newValue);
                                                                        }}
                                                                        onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                                                        inputFormat="yyyy"
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                {...params}
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                required
                                                                                fullWidth
                                                                                autoComplete="off"
                                                                                size="small"

                                                                            />}
                                                                    />
                                                                </LocalizationProvider> */}
                                                                <FormControl fullWidth margin='dense'>
                                                                    <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>Select Option</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        required
                                                                        id="demo-simple-select"
                                                                        size='small'
                                                                        value={yearIndex}
                                                                        label="Select Option"
                                                                        onChange={(event) => { handleYear(event.target.value); }}
                                                                        onKeyPress={(e) => (e.key == "Enter") ? yearIndex : ""}
                                                                    >
                                                                        {
                                                                            year.map((e, index) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={index}>{e.yearLabel}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-sm-4 mt-3">
                                                                <button type='btn' className='btn btn-sm btn-warning' onClick={() => getAutomaticData()}>View</button>
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            }
                                        })()}

                                        <hr />

                                        <div className="row mt-3">
                                            <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                            {Object.entries(amtData).map(([key, value], index) => (
                                                <div className='' key={index} style={{ width: '100px' }}>
                                                    <TextField
                                                        label={`Value ${key}`} // Label for each text field
                                                        value={value}           // Set the initial value from the object
                                                        onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                        fullWidth
                                                        inputProps={{ readOnly: true }}
                                                        size='small'
                                                        margin='dense'
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        {(() => {
                                            if (option == 1) {
                                                if (data.length != 0 || filteredData.length != 0) {
                                                    return (
                                                        <>

                                                            <div>
                                                                <div className='row col-sm-4 offset-sm-5'>
                                                                    <div>
                                                                        <input
                                                                            className='form-control form-control-sm'
                                                                            type="text"
                                                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                                            placeholder="Search Here"
                                                                            onChange={(event) => { setSearchTerm(event.target.value); }}
                                                                            value={searchTerm}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <form onSubmit={saveManualStudentTeacherPrizeData}>
                                                                    <div className="row">
                                                                        <div className='table-responsive col-sm-8 offset-sm-1 mt-4' style={{ maxHeight: "330px" }}>
                                                                            <table className="table table-bordered">
                                                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                                    {(() => {
                                                                                        if (ssForm == 723) {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                                    </tr>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                                    </tr>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                </thead>
                                                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                                    {filteredData.map((item, index) => {

                                                                                        // console.log(item.amount)
                                                                                        if (ssForm == 723) {
                                                                                            return (
                                                                                                <>

                                                                                                    <tr key={index} className="table-sm">
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                                        <td style={{ width: "150px", height: "70px" }}>
                                                                                                            <input
                                                                                                                onWheel={(e) => e.target.blur}
                                                                                                                style={{ width: "120px", fontSize: "14px" }}
                                                                                                                type="number"
                                                                                                                className="form-control"
                                                                                                                value={item.obtainMarks}
                                                                                                                onChange={(e) => handleSansthaDataInputChange(index, 'obtainMarks', e.target.value)}
                                                                                                            />
                                                                                                        </td>


                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                                checked={item.isAllocated === 1}
                                                                                                                onChange={() => handleCheckChange(index)}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <tr key={index} className="table-sm">
                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>



                                                                                                    <td style={{ width: "100px", height: "70px" }}>
                                                                                                        <input
                                                                                                            onWheel={(e) => e.target.blur}
                                                                                                            style={{ width: "80px", fontSize: "14px" }}
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={item.srNo}
                                                                                                            onChange={(e) => handleDataInputChange(index, 'srNo', e.target.value)}
                                                                                                        />
                                                                                                    </td>


                                                                                                    <td style={{ width: "100px", height: "70px" }}>
                                                                                                        <input
                                                                                                            onWheel={(e) => e.target.blur}
                                                                                                            style={{ width: "80px", fontSize: "14px" }}
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={item.amount}
                                                                                                            onChange={(e) => handleDataInputChange(index, 'amount', e.target.value)}
                                                                                                        />
                                                                                                    </td>

                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                            checked={item.isAllocated === 1}
                                                                                                            onChange={() => handleCheckChange(index)}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>


                                                                    <div className="row mt-4">
                                                                        <div className="col-sm-4 offset-sm-1">
                                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            }
                                            else if (option == 2) {
                                                if (automaticData.length != 0 || automaticFilterData.length != 0) {
                                                    return (
                                                        <>

                                                            <div>
                                                                <div className='row col-sm-4 offset-sm-7'>
                                                                    <div>
                                                                        <input
                                                                            className='form-control form-control-sm'
                                                                            type="text"
                                                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                                            placeholder="Search Here"
                                                                            onChange={(event) => { setSearchTerm(event.target.value) }}
                                                                            value={searchTerm}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <form onSubmit={saveAutoStudentTeacherPrizeData}>
                                                                    <div className="row">
                                                                        <div className='table-responsive col-sm-10 offset-sm-1 mt-4' style={{ maxHeight: "330px" }}>
                                                                            <table className="table table-bordered">
                                                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                                    {(() => {
                                                                                        if (ssForm == 723) {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Max Marks</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                                    </tr>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Obtained Marks</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Max Marks</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                                                        {/* <th style={{ fontSize: "15px", fontWeight: "600" }}>{(ssForm == 723) ? 'Obtained Marks' : 'Amount'}</th> */}
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                                                    </tr>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })()}

                                                                                </thead>
                                                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                                    {automaticFilterData.map((item, index) => {

                                                                                        // console.log(item.amount)
                                                                                        if (ssForm == 723) {
                                                                                            return (
                                                                                                <>
                                                                                                    return (
                                                                                                    <tr key={index} className="table-sm">
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.obtainMarks}</td>
                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.maxMark}</td>

                                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                                checked={item.isAllocated === 1}
                                                                                                                onChange={() => handleCheckAutoChange(index)}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    )
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <tr key={index} className="table-sm">
                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.obtainMarks}</td>
                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.maxMark}</td>
                                                                                                    <td style={{ width: "100px", height: "70px" }} hidden={(ssForm == 723) ? true : false}>
                                                                                                        <input
                                                                                                            onWheel={(e) => e.target.blur}
                                                                                                            style={{ width: "80px", fontSize: "14px" }}
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={item.srNo}
                                                                                                            onChange={(e) => handleAutoDataInputChange(index, 'srNo', e.target.value)}
                                                                                                        />
                                                                                                    </td>
                                                                                                    {(() => {
                                                                                                        if (ssForm == 723) {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    {/* <td style={{ width: "150px", height: "70px" }}>
                                                                                                                        <input
                                                                                                                            onWheel={(e) => e.target.blur}
                                                                                                                            style={{ width: "120px", fontSize: "14px" }}
                                                                                                                            type="number"
                                                                                                                            className="form-control"
                                                                                                                            value={item.amount}
                                                                                                                        // onChange={(e) => handleAutoDataInputChange(index, 'amount', e.target.value)}
                                                                                                                        />
                                                                                                                    </td> */}
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                        else {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <td style={{ width: "100px", height: "70px" }}>
                                                                                                                        <input
                                                                                                                            onWheel={(e) => e.target.blur}
                                                                                                                            style={{ width: "80px", fontSize: "14px" }}
                                                                                                                            type="number"
                                                                                                                            className="form-control"
                                                                                                                            value={item.amount}
                                                                                                                            onChange={(e) => handleAutoDataInputChange(index, 'amount', e.target.value)}
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    })()}

                                                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                            checked={item.isAllocated === 1}
                                                                                                            onChange={() => handleCheckAutoChange(index)}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }

                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>


                                                                    <div className="row mt-4">
                                                                        <div className="col-sm-4 offset-sm-1">
                                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            }
                                        })()}
                                    </>
                                )
                            }
                            else if (prFor == 2) {
                                if (data.length != 0 || filteredData.length != 0) {
                                    return (
                                        <>

                                            <div>
                                                <div className="row mt-3">
                                                    <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                                    {Object.entries(amtData).map(([key, value], index) => (
                                                        <div className='' key={index} style={{ width: '100px' }}>
                                                            <TextField
                                                                label={`Value ${key}`} // Label for each text field
                                                                value={value}           // Set the initial value from the object
                                                                onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                                fullWidth
                                                                inputProps={{ readOnly: true }}
                                                                size='small'
                                                                margin='dense'
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className='row col-sm-4 offset-sm-4'>
                                                    <div>
                                                        <input
                                                            className='form-control form-control-sm'
                                                            type="text"
                                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                            placeholder="Search Here"
                                                            onChange={(event) => { setSearchTerm(event.target.value) }}
                                                            value={searchTerm}
                                                        />
                                                    </div>
                                                </div>
                                                <form onSubmit={saveManualStudentList}>
                                                    <div className="row">
                                                        <div className='table-responsive col-sm-8 mt-4' style={{ maxHeight: "330px" }}>
                                                            <table className="table table-bordered">
                                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                    <tr>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Emp. Id</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Employee Name</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                    {filteredData.map((item, index) => {

                                                                        // console.log(item.amount)
                                                                        return (
                                                                            <tr key={index} className="table-sm">
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.empId}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.empNameMr : item.empNameEn}</td>


                                                                                <td style={{ width: "100px", height: "70px" }}>
                                                                                    <input
                                                                                        onWheel={(e) => e.target.blur}
                                                                                        style={{ width: "80px", fontSize: "14px" }}
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        value={item.srNo}
                                                                                        onChange={(e) => handleDataInputChange(index, 'srNo', e.target.value)}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ width: "100px", height: "70px" }}>
                                                                                    <input
                                                                                        onWheel={(e) => e.target.blur}
                                                                                        style={{ width: "80px", fontSize: "14px" }}
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        value={item.amount}
                                                                                        onChange={(e) => handleDataInputChange(index, 'amount', e.target.value)}
                                                                                    />
                                                                                </td>

                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                    <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                        checked={item.isAllocated === 1}
                                                                                        onChange={() => handleCheckChange(index)}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>


                                                    <div className="row mt-4">
                                                        <div className="col-sm-4">
                                                            <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        })()}


                    </div>
                </div >
            }
        </>
    )
}


export default ConditionAddStudent
