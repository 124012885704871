import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { TablePagination, TextField, Tooltip } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getPrizeDetailsData, getPrizeDetailsDataForSanstha, getPrizeDetailsDataForSansthaSchoolLogin, getStudentApprovalAllocationData } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import ConditionDetailsTable from './ConditionDetailsTable';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const PrizeDetailsForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Prize Details"
    const [loaderOption, setLoaderOption] = useState(false);
    const { pId, ssForm } = useParams();

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [filteredData, setFilteredData] = useState([]);

    const [prizeCode, setPrizeCode] = useState('');
    const [paritoshikName, setParitoshikName] = useState("");
    const [yojanaInfo, setYojanaInfo] = useState("");

    const [approveStatusDetail, setApproveStatusDetail] = useState(0);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `योजनेची माहिती`, key: 'approvalStatus' },
        { label: `Action`, key: 'action', isAction: true },
    ];

    useEffect(() => {
        getData();
    }, [authUser, ssForm, pId])

    const getData = async () => {
        setLoaderOption(true);
        if (pId != null && pId != "" && authUser.sessionYear != '' && authUser.deptId != null && authUser.branchId != null) {
            if (ssForm == 724) {
                // console.log(`${getPrizeDetailsData}?id=${pId}&year=${authUser.sessionYear}`)
                await axios.get(`${getPrizeDetailsDataForSanstha}?id=${pId}&year=${authUser.sessionYear}`)
                    .then(res => {
                        setPrizeCode(res.data.prizeCode);
                        setParitoshikName(res.data.prizeName);
                        setYojanaInfo(res.data.schemeDescription);
                        setData(res.data.prizeDistribution);
                        setFilteredData(res.data.prizeDistribution);

                        // data.map(e => {
                        //     setApproveStatusDetail(e.allocationStatus)
                        // })
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
            else if (ssForm == 723) {
                console.log(`${getPrizeDetailsDataForSansthaSchoolLogin}?id=${pId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getPrizeDetailsDataForSansthaSchoolLogin}?id=${pId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setPrizeCode(res.data.prizeCode);
                        setParitoshikName(res.data.prizeName);
                        setYojanaInfo(res.data.schemeDescription);
                        setData(res.data.prizeDistribution);
                        setFilteredData(res.data.prizeDistribution);

                        // data.map(e => {
                        //     setApproveStatusDetail(e.allocationStatus)
                        // })
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
            else {
                // console.log(`${getPrizeDetailsData}?id=${pId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getPrizeDetailsData}?id=${pId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setPrizeCode(res.data.prizeCode);
                        setParitoshikName(res.data.prizeName);
                        setYojanaInfo(res.data.schemeDescription);
                        setData(res.data.prizeDistribution);
                        setFilteredData(res.data.prizeDistribution);

                        // data.map(e => {
                        //     setApproveStatusDetail(e.allocationStatus)
                        // })
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
        }

    }

    const getPrizeDetails = async (pId) => {
        // navigate(`/Home/et722pf/${pId}`)
    }


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >खाते क्र. :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    //required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="खाते क्र."
                                    value={prizeCode}
                                    onChange={(e) => setPrizeCode(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>पारितोषिकाचे नाव:</label>
                            <div className='col-sm-6'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="पारितोषिकाचे नाव"
                                    value={paritoshikName}
                                    onChange={(e) => setParitoshikName(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>योजनेची माहिती :</label>
                            <div className='col-sm-7'>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rows={3}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="योजनेची माहिती"
                                    value={yojanaInfo}
                                    onChange={(e) => setYojanaInfo(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>

                        <ConditionDetailsTable
                            data={data}
                            filteredData={filteredData}
                            setData={setData}
                            setFilteredData={setFilteredData}
                            approveStatusDetail={approveStatusDetail}
                            pId={pId}
                            getData={getData}
                            ssForm={ssForm}
                            paritoshikName={paritoshikName}
                            yojanaInfo={yojanaInfo}
                        />
                    </div>
                </div>
            }
        </>
    )
}


export default PrizeDetailsForm
