import { BaseIP, BaseURL, BaseURLMaster } from "../../CommonServices/APIURL";

//http://192.168.1.102:8080/institute/sss/
export const dBaseURL = "http://192.168.1.102:8080/institute/sss/";

// For get subject list
// localhost:8080/institute/sss/academics/exam/getFinalExamResultSubject?acYear=2021&sid=3&bid=1&classId=10&divId=39&termId=3
export const getSubjectList = BaseURL + "academics/exam/getFinalExamResultSubject";

//localhost:8080/institute/sss/academics/exam/saveFinalExamResultSubject
export const saveSubjectListForExam = BaseURL + "academics/exam/saveFinalExamResultSubject";

//Exam list APIURL
//localhost:8080/institute/sss/academics/exam/getFinalExamList
export const getFinalExamList = BaseURL + "academics/exam/getFinalExamList";

//localhost:8080/institute/sss/academics/exam/saveFinalExamList
export const saveFinalExamList = BaseURL + "academics/exam/saveFinalExamList";


// getAllExamGrades  
// localhost:8080/institute/sss/academics/exam/examGrades
export const getAllExamGrades = BaseURL + "academics/exam/examGrades";

// getExamGradeById
// localhost:8080/institute/sss/academics/exam/examGradesById?id=1

// saveExamGrade
// localhost:8080/institute/sss/academics/exam/saveExamGrade
export const saveExamGrade = BaseURL + "academics/exam/saveExamGrade";

// updateExamGrade
// localhost:8080/institute/sss/academics/exam/updateExamGrade
export const updateExamGrade = BaseURL + "academics/exam/updateExamGrade/";

// deleteExamGrade
// localhost:8080/institute/sss/academics/exam/deleteExamGrade?id=2
export const deleteExamGrade = BaseURL + "academics/exam/deleteExamGrade";


//group setting
//localhost:8081/institute-sss/institute/sss/academics/exam/saveExamSubjectGroup?allDivision=1
export const saveGroupSettingAPIURL = BaseURL + "academics/exam/saveExamSubjectGroup";

//localhost:8081/institute-sss/institute/sss/academics/exam/getExamSubjectGroup?classId=5&divId=17&year=2023&sid=3&branchMedium=1
export const getGroupSettingDataAPIURL = BaseURL + "academics/exam/getExamSubjectGroup";

//localhost:8081/institute-sss/institute/sss/academics/exam/deleteExamSubjectGroup?id=1&classId=5&divId=17&year=2023&sid=3&branchMedium=1&allDivision=1
export const deleteGroupSettingAPIURL = BaseURL + "academics/exam/deleteExamSubjectGroup";

//updateExamSubjectGroup
export const updateGroupSettingAPIURL = BaseURL + "academics/exam/updateExamSubjectGroup";

//localhost:8081/institute-sss/institute/sss/academics/exam/deleteExamSubjectGroupDetail?id=9
export const deleteGroupSubjectAPIURL = BaseURL + "academics/exam/deleteExamSubjectGroupDetail";


//Endowment Subject
//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/subjectSetting/getEndowmentSubjectSettingData?classId=9&year=2024
export const getEndowSubDataAPIURL = BaseURL + "endowment/subjectSetting/getEndowmentSubjectSettingData";

//http://192.168.29.102:8081/institute-sss/institute/sss/endowment/subjectSetting/saveEndowmentSubjectSettingData
export const saveEndowSubDataAPIURL = BaseURL + "endowment/subjectSetting/saveEndowmentSubjectSettingData";



