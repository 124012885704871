import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { Checkbox, TextField } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getAmountListForManualAPIURL, getEmployeeListForSantshaPrizeAllocAPIURL, getEndowmentIdAPIURL, getsansthaSchoolAllocListAPIURL, getStudentListForSantshaPrizeAllocAPIURL, saveStudentEmpListForSansthaAPIURL } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faBullseye, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));


const ConditionAddStudentForSanstha = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);
    const { prId, etran, prFor, ssForm } = useParams();

    let titleId = "";

    if (prFor == 1) {
        titleId = "Add Student"
    }
    else if (prFor == 2) {
        titleId = "Add Employee"
    }
    else if (prFor == 3) {
        titleId = "Add Institute"
    }

    const [loaderOption, setLoaderOption] = useState(false);

    const location = useLocation();

    // Access paritoshikName from location state
    const prName = location.state?.paritoshikName;
    const praizeId = location.state?.pId
    const yName = location.state?.yojanaInfo;

    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    // console.log(JSON.stringify(data));
    const [searchTerm, setSearchTerm] = useState("");
    const [chkRegNum, setChkRegNum] = useState('');
    const [srNo, setSrNo] = useState('');
    const [regNum, setRegNum] = useState('');
    const [tempData, setTempData] = useState([]);
    const [amtData, setAmtData] = useState([]);
    const [endowId, setEndowId] = useState('');

    const [updatedSrNo, setUpdatedSrNo] = useState(null);
    const [updatedAmount, setUpdatedAmount] = useState(null);
    const [updatedIsAllocated, setUpdatedIsAllocated] = useState(0);

    useEffect(() => {
        const updatedData = data.map(item => ({
            ...item,
            // Set amount from amtData only if it has not been manually set
            amount: amtData[item.srNo] !== undefined && item.amount === 0 ? amtData[item.srNo] : item.amount,
        }));
        setFilteredData(updatedData);
        setData(updatedData);

    }, []);

    // console.log(JSON.stringify(newFilterData))

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `योजनेची माहिती`, key: 'approvalStatus' },
        { label: `Action`, key: 'action', isAction: true },
    ];

    const handleCheckChange = (index) => {
        const updatedFilteredData = [...filteredData];
        updatedFilteredData[index].isAllocated = updatedFilteredData[index].isAllocated === 1 ? 0 : 1;
        setData(updatedFilteredData);
        // setFilteredData(updatedFilteredData);
        setRegNum(updatedFilteredData[index].regNo)

        if (prFor == 1 || prFor == 3) {
            const originalDataIndex = data.findIndex(item => item.regNo === updatedFilteredData[index].regNo);
            if (originalDataIndex !== -1) {
                const updatedData = [...data];
                updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
                setUpdatedIsAllocated(updatedFilteredData[index].isAllocated);
                if (searchTerm == "") {
                    setData(updatedData);
                    setFilteredData(updatedData);
                }
            }
        }
        else if (prFor == 2) {
            const originalDataIndex = data.findIndex(item => item.empId === updatedFilteredData[index].empId);
            if (originalDataIndex !== -1) {
                const updatedData = [...data];
                updatedData[originalDataIndex].isAllocated = updatedFilteredData[index].isAllocated;
                setUpdatedIsAllocated(updatedFilteredData[index].isAllocated);
                if (searchTerm == "") {
                    setData(updatedData);
                    setFilteredData(updatedData);
                }
            }
        }
    };

    const handleDataInputChange = (index, field, value) => {
        const updatedFilteredData = [...filteredData];
        const updatedData = [...data]; // Work on the complete data set for consistency

        // Update based on prFor (either 1 for students or 2 for employees)
        if (prFor == 1 || prFor == 3) {
            if (field === 'srNo') {
                const newSrNo = Number(value);
                const recordRegNo = updatedFilteredData[index].regNo;
                const prevSrNo = updatedFilteredData[index].srNo; // Track the previous srNo

                // Update the srNo for both filteredData and data
                updatedFilteredData[index][field] = newSrNo;
                const recordIndexInData = updatedData.findIndex(item => item.regNo === recordRegNo);
                updatedData[recordIndexInData].srNo = newSrNo;

                // Get the total amount allocated for the new srNo
                const totalAmountForSrNo = amtData[newSrNo] !== undefined ? amtData[newSrNo] : 0;

                // If the previous srNo is different from the new srNo, we need to redistribute amounts
                if (prevSrNo !== newSrNo) {
                    // Redistribute amounts for the previous srNo (remove record from old srNo)
                    const samePrevSrNoRecords = updatedData.filter(item => item.srNo === prevSrNo);
                    if (samePrevSrNoRecords.length > 0) {
                        const totalAmountForPrevSrNo = amtData[prevSrNo] !== undefined ? amtData[prevSrNo] : 0;
                        const amountPerRecordPrev = Math.floor(totalAmountForPrevSrNo / samePrevSrNoRecords.length);
                        const prevRemainder = totalAmountForPrevSrNo % samePrevSrNoRecords.length;

                        samePrevSrNoRecords.forEach((record, idx) => {
                            const recordIdxInData = updatedData.findIndex(item => item.regNo === record.regNo);
                            updatedData[recordIdxInData].amount = amountPerRecordPrev + (idx < prevRemainder ? 1 : 0); // Distribute remainder incrementally

                            const recordIdxInFiltered = updatedFilteredData.findIndex(item => item.regNo === record.regNo);
                            if (recordIdxInFiltered !== -1) {
                                updatedFilteredData[recordIdxInFiltered].amount = amountPerRecordPrev + (idx < prevRemainder ? 1 : 0); // Distribute remainder incrementally
                            }
                        });
                    }

                    // Redistribute amounts for the new srNo (add record to new srNo)
                    const sameSrNoRecords = updatedData.filter(item => item.srNo === newSrNo);
                    if (sameSrNoRecords.length > 0) {
                        const amountPerRecordNew = Math.floor(totalAmountForSrNo / sameSrNoRecords.length);
                        const remainder = totalAmountForSrNo % sameSrNoRecords.length;

                        sameSrNoRecords.forEach((record, idx) => {
                            const recordIdxInData = updatedData.findIndex(item => item.regNo === record.regNo);
                            updatedData[recordIdxInData].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally

                            const recordIdxInFiltered = updatedFilteredData.findIndex(item => item.regNo === record.regNo);
                            if (recordIdxInFiltered !== -1) {
                                updatedFilteredData[recordIdxInFiltered].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally
                            }
                        });
                    }
                } else {
                    // If the srNo didn't change, just update the amount for that record
                    const totalAmountForSrNo = amtData[newSrNo] !== undefined ? amtData[newSrNo] : 0;
                    const sameSrNoRecords = updatedData.filter(item => item.srNo === newSrNo);

                    if (sameSrNoRecords.length > 0) {
                        const amountPerRecordNew = Math.floor(totalAmountForSrNo / sameSrNoRecords.length);
                        const remainder = totalAmountForSrNo % sameSrNoRecords.length;

                        sameSrNoRecords.forEach((record, idx) => {
                            const recordIdxInData = updatedData.findIndex(item => item.regNo === record.regNo);
                            updatedData[recordIdxInData].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally

                            const recordIdxInFiltered = updatedFilteredData.findIndex(item => item.regNo === record.regNo);
                            if (recordIdxInFiltered !== -1) {
                                updatedFilteredData[recordIdxInFiltered].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally
                            }
                        });
                    }
                }
            } else {
                // Update values for other fields like amount or other numerical fields
                updatedFilteredData[index][field] = Number(value);
            }

            setData(updatedData); // Update the complete data state
            setFilteredData(updatedFilteredData); // Update the filtered data state

            setRegNum(updatedFilteredData[index].regNo);
            setUpdatedSrNo(updatedFilteredData[index].srNo);
            setUpdatedAmount(updatedFilteredData[index].amount);
        }
        else if (prFor == 2) {
            if (field === 'srNo') {
                const newSrNo = Number(value);
                const recordEmpId = updatedFilteredData[index].empId;
                const prevSrNo = updatedFilteredData[index].srNo; // Track the previous srNo

                // Update the srNo for both filteredData and data
                updatedFilteredData[index][field] = newSrNo;
                const recordIndexInData = updatedData.findIndex(item => item.empId === recordEmpId);
                updatedData[recordIndexInData].srNo = newSrNo;

                // Get the total amount allocated for the new srNo
                const totalAmountForSrNo = amtData[newSrNo] !== undefined ? amtData[newSrNo] : 0;

                // If the previous srNo is different from the new srNo, we need to redistribute amounts
                if (prevSrNo !== newSrNo) {
                    // Redistribute amounts for the previous srNo (remove record from old srNo)
                    const samePrevSrNoRecords = updatedData.filter(item => item.srNo === prevSrNo);
                    if (samePrevSrNoRecords.length > 0) {
                        const totalAmountForPrevSrNo = amtData[prevSrNo] !== undefined ? amtData[prevSrNo] : 0;
                        const amountPerRecordPrev = Math.floor(totalAmountForPrevSrNo / samePrevSrNoRecords.length);
                        const prevRemainder = totalAmountForPrevSrNo % samePrevSrNoRecords.length;

                        samePrevSrNoRecords.forEach((record, idx) => {
                            const recordIdxInData = updatedData.findIndex(item => item.empId === record.empId);
                            updatedData[recordIdxInData].amount = amountPerRecordPrev + (idx < prevRemainder ? 1 : 0); // Distribute remainder incrementally

                            const recordIdxInFiltered = updatedFilteredData.findIndex(item => item.empId === record.empId);
                            if (recordIdxInFiltered !== -1) {
                                updatedFilteredData[recordIdxInFiltered].amount = amountPerRecordPrev + (idx < prevRemainder ? 1 : 0); // Distribute remainder incrementally
                            }
                        });
                    }

                    // Redistribute amounts for the new srNo (add record to new srNo)
                    const sameSrNoRecords = updatedData.filter(item => item.srNo === newSrNo);
                    if (sameSrNoRecords.length > 0) {
                        const amountPerRecordNew = Math.floor(totalAmountForSrNo / sameSrNoRecords.length);
                        const remainder = totalAmountForSrNo % sameSrNoRecords.length;

                        sameSrNoRecords.forEach((record, idx) => {
                            const recordIdxInData = updatedData.findIndex(item => item.empId === record.empId);
                            updatedData[recordIdxInData].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally

                            const recordIdxInFiltered = updatedFilteredData.findIndex(item => item.empId === record.empId);
                            if (recordIdxInFiltered !== -1) {
                                updatedFilteredData[recordIdxInFiltered].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally
                            }
                        });
                    }
                } else {
                    // If the srNo didn't change, just update the amount for that record
                    const totalAmountForSrNo = amtData[newSrNo] !== undefined ? amtData[newSrNo] : 0;
                    const sameSrNoRecords = updatedData.filter(item => item.srNo === newSrNo);

                    if (sameSrNoRecords.length > 0) {
                        const amountPerRecordNew = Math.floor(totalAmountForSrNo / sameSrNoRecords.length);
                        const remainder = totalAmountForSrNo % sameSrNoRecords.length;

                        sameSrNoRecords.forEach((record, idx) => {
                            const recordIdxInData = updatedData.findIndex(item => item.empId === record.empId);
                            updatedData[recordIdxInData].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally

                            const recordIdxInFiltered = updatedFilteredData.findIndex(item => item.empId === record.empId);
                            if (recordIdxInFiltered !== -1) {
                                updatedFilteredData[recordIdxInFiltered].amount = amountPerRecordNew + (idx < remainder ? 1 : 0); // Distribute remainder incrementally
                            }
                        });
                    }
                }
            } else {
                // Update values for other fields like amount or other numerical fields
                updatedFilteredData[index][field] = Number(value);
            }

            setData(updatedData); // Update the complete data state
            setFilteredData(updatedFilteredData); // Update the filtered data state

            setRegNum(updatedFilteredData[index].empId);
            setUpdatedSrNo(updatedFilteredData[index].srNo);
            setUpdatedAmount(updatedFilteredData[index].amount);
        }
    };



    useEffect(() => {
        getData();
    }, [authUser, prFor])

    const getData = async () => {
        setLoaderOption(true);
        // console.log(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        if (authUser.sessionYear != "" && authUser.branchId != null && authUser.deptId != null) {
            // console.log(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
            await axios.get(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=0&bid=0`)
                .then(res => {
                    setAmtData(res.data);
                    // setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })

            await axios(`${getEndowmentIdAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setEndowId(res.data);
                });
        }



        if (authUser.sessionYear != "") {
            if (prFor == 1) {
                console.log(`${getStudentListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                await axios.get(`${getStudentListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        // setNewCombineData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
            else if (prFor == 2) {
                console.log(`${getEmployeeListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                await axios.get(`${getEmployeeListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        // setNewCombineData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
            else if (prFor == 3) {
                console.log(`${getsansthaSchoolAllocListAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                await axios.get(`${getsansthaSchoolAllocListAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                    .then(res => {
                        // console.log(JSON.stringify(newData)); // Log newData for debugging
                        setData(res.data);
                        setFilteredData(res.data);
                        setTempData(res.data);
                        // setNewCombineData(res.data);
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
        }

    }

    useEffect(() => {
        // if (searchTerm == "") {
        //     setFilteredData([]);
        //     setAutomaticFilterData([]);
        // }
        // else {
        if (prFor == 1 || prFor == 3) {
            setFilteredData([])
            let tempFilteredData = data.filter((item) =>
                (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredData(tempFilteredData)
        }
        else if (prFor == 2) {
            setFilteredData([])
            let tempFilteredData = data.filter((item) =>
                (item.empNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.empNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.amount || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.empId || '').toString().toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredData(tempFilteredData)
        }
        // }
    }, [searchTerm, prFor])

    useEffect(() => {

        if (prFor == 1 || prFor == 3) {

            if (updatedSrNo !== null || updatedAmount !== null || updatedIsAllocated !== 0) {
                const updatedData = [...tempData];

                const itemIndex = updatedData.findIndex(item => item.regNo === regNum);
                if (itemIndex !== -1) {
                    updatedData[itemIndex].srNo = updatedSrNo;
                    updatedData[itemIndex].amount = updatedAmount;
                    updatedData[itemIndex].isAllocated = updatedIsAllocated;
                }

                setData(updatedData);
                // setTempData(updatedData);
                // console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                if (searchTerm == "") {
                    setFilteredData(updatedData);
                    // console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                }

                // Reset state values after updating
                setUpdatedSrNo(null);
                setUpdatedAmount(null);
                setUpdatedIsAllocated(0);
            }
        }
        else if (prFor == 2) {
            if (updatedSrNo !== null || updatedAmount !== null || updatedIsAllocated !== 0) {
                const updatedData = [...tempData];

                const itemIndex = updatedData.findIndex(item => item.empId === regNum);
                if (itemIndex !== -1) {
                    updatedData[itemIndex].srNo = updatedSrNo;
                    updatedData[itemIndex].amount = updatedAmount;
                    updatedData[itemIndex].isAllocated = updatedIsAllocated;
                }

                setData(updatedData);
                // setTempData(updatedData);
                // console.log(JSON.stringify(updatedData) + "======" + searchTerm)
                if (searchTerm == "") {
                    setFilteredData(updatedData);
                    // console.log(JSON.stringify(updatedData) + "======" + searchTerm)

                }

                // Reset state values after updating
                setUpdatedSrNo(null);
                setUpdatedAmount(null);
                setUpdatedIsAllocated(0);
            }
        }

    }, [searchTerm]);

    const handleInputChange = (e, key) => {
        const updatedValue = e.target.value;
        setAmtData({
            ...amtData,
            [key]: updatedValue, // Update the specific key's value
        });
    };

    const saveManualStudentList = (e) => {
        // e.preventDefault();
        setLoaderOption(true);

        const newData = tempData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: (prFor == 1 || prFor == 3) ? e.regNo : e.empId,
            srNo: e.srNo,
            branchId: (prFor == 3) ? e.regNo : e.bid,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount,
            obtainedMarks: e.obtainMarks
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveStudentEmpListForSansthaAPIURL}`, (prFor == 1) ? newData : filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: 0,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0,
                obtainedMarks: 0
            }]
            console.log(JSON.stringify((prFor == 2) ? saveObj : newData));
            axios.post(`${saveStudentEmpListForSansthaAPIURL}`, (prFor == 2) ? saveObj : newData)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
    }

    // console.log(JSON.stringify(filteredData)); // Log combined data for debugging
    const saveSanthaStudentEmpList = e => {
        e.preventDefault();
        // if (prFor == 1) {
        saveManualStudentList();
        // }
        // else if (prFor == 2) {

        // }
    }


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        <div className=''>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>खाते क्र. : <span style={{ color: '#fe3627', fontWeight: '600' }}>{prId}</span></span>
                        </div>
                        <div className=''>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>पारितोषिकाचे नाव : <span style={{ color: '#fe3627', fontWeight: '600' }}>{prName}</span></span>
                        </div>
                        <div className='mt-1'>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>योजनेची माहिती : <span style={{ color: '#fe3627', fontWeight: '600' }}>{yName}</span></span>
                        </div>
                        <hr />
                        <div>
                            {
                                (data.length != 0 || filteredData.length != 0)
                                    ?
                                    <>
                                        <div className="row mt-3">
                                            <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                            {Object.entries(amtData).map(([key, value], index) => (
                                                <div className='' key={index} style={{ width: '100px' }}>
                                                    <TextField
                                                        label={`Value ${key}`} // Label for each text field
                                                        value={value}           // Set the initial value from the object
                                                        onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                        fullWidth
                                                        inputProps={{ readOnly: true }}
                                                        size='small'
                                                        margin='dense'
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        <div className='row'>
                                            <div>
                                                <input
                                                    className='form-control form-control-sm'
                                                    type="text"
                                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "4px", border: "1px solid #C2C1C1" }}
                                                    placeholder="Search Here"
                                                    onChange={(event) => { setSearchTerm(event.target.value) }}
                                                    value={searchTerm}
                                                />
                                            </div>
                                        </div>
                                        <form onSubmit={saveSanthaStudentEmpList}>
                                            {(() => {
                                                if (prFor == 1) {
                                                    return (
                                                        <>

                                                            <div className="row">
                                                                <div className='table-responsive col-sm-12 mt-2' style={{ maxHeight: "330px" }}>
                                                                    <table className="table table-bordered">
                                                                        <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                            <tr>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Reg No.</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>School Name</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Marks Obtained</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                            {filteredData.map((item, index) => {

                                                                                // console.log(item.amount)
                                                                                return (
                                                                                    <tr key={index} className="table-sm">
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{item.schoolName}</td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.obtainMarks}</td>

                                                                                        <td style={{ width: "100px", height: "70px" }}>
                                                                                            <input
                                                                                                onWheel={(e) => e.target.blur}
                                                                                                style={{ width: "80px", fontSize: "14px" }}
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={item.srNo}
                                                                                                onChange={(e) => handleDataInputChange(index, 'srNo', e.target.value)}
                                                                                            />
                                                                                        </td>

                                                                                        <td style={{ width: "100px", height: "70px" }}>
                                                                                            <input
                                                                                                onWheel={(e) => e.target.blur}
                                                                                                style={{ width: "80px", fontSize: "14px" }}
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={item.amount}
                                                                                                onChange={(e) => handleDataInputChange(index, 'amount', e.target.value)}
                                                                                            />
                                                                                        </td>

                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                checked={item.isAllocated === 1}
                                                                                                onChange={() => handleCheckChange(index)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>


                                                            <div className="row mt-4">
                                                                <div className="col-sm-4">
                                                                    <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                    <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }
                                                else if (prFor == 2) {
                                                    return (
                                                        <>

                                                            <div className="row">
                                                                <div className='table-responsive col-sm-12 mt-2' style={{ maxHeight: "330px" }}>
                                                                    <table className="table table-bordered">
                                                                        <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                            <tr>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Emp.Id</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Employee Name</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>School Name</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                            {filteredData.map((item, index) => {

                                                                                // console.log(item.amount)
                                                                                return (
                                                                                    <tr key={index} className="table-sm">
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.empId}</td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.empNameMr : item.empNameEn}</td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{item.schoolName}</td>
                                                                                        {/* <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.obtainMarks}</td> */}

                                                                                        <td style={{ width: "100px", height: "70px" }}>
                                                                                            <input
                                                                                                onWheel={(e) => e.target.blur}
                                                                                                style={{ width: "80px", fontSize: "14px" }}
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={item.srNo}
                                                                                                onChange={(e) => handleDataInputChange(index, 'srNo', e.target.value)}
                                                                                            />
                                                                                        </td>

                                                                                        <td style={{ width: "100px", height: "70px" }}>
                                                                                            <input
                                                                                                onWheel={(e) => e.target.blur}
                                                                                                style={{ width: "80px", fontSize: "14px" }}
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={item.amount}
                                                                                                onChange={(e) => handleDataInputChange(index, 'amount', e.target.value)}
                                                                                            />
                                                                                        </td>

                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                checked={item.isAllocated === 1}
                                                                                                onChange={() => handleCheckChange(index)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>


                                                            <div className="row mt-4">
                                                                <div className="col-sm-4">
                                                                    <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                    <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }
                                                else if (prFor == 3) {
                                                    return (
                                                        <>

                                                            <div className="row">
                                                                <div className='table-responsive col-sm-12 mt-2' style={{ maxHeight: "330px" }}>
                                                                    <table className="table table-bordered">
                                                                        <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                                            <tr>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Id</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Institute Name</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr No.</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Amount</th>
                                                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                                            {filteredData.map((item, index) => {

                                                                                // console.log(item.amount)
                                                                                return (
                                                                                    <tr key={index} className="table-sm">
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regNo}</td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>

                                                                                        <td style={{ width: "100px", height: "70px" }}>
                                                                                            <input
                                                                                                onWheel={(e) => e.target.blur}
                                                                                                style={{ width: "80px", fontSize: "14px" }}
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={item.srNo}
                                                                                                onChange={(e) => handleDataInputChange(index, 'srNo', e.target.value)}
                                                                                            />
                                                                                        </td>

                                                                                        <td style={{ width: "100px", height: "70px" }}>
                                                                                            <input
                                                                                                onWheel={(e) => e.target.blur}
                                                                                                style={{ width: "80px", fontSize: "14px" }}
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={item.amount}
                                                                                                onChange={(e) => handleDataInputChange(index, 'amount', e.target.value)}
                                                                                            />
                                                                                        </td>

                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >
                                                                                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                                                checked={item.isAllocated === 1}
                                                                                                onChange={() => handleCheckChange(index)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>


                                                            <div className="row mt-4">
                                                                <div className="col-sm-4">
                                                                    <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                    <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }
                                            })()}
                                        </form>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <h5>Data not found with this prize code.</h5>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-4">
                                                <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Back</button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div >
            }
        </>
    )
}


export default ConditionAddStudentForSanstha
