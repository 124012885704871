import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import { Checkbox, Grid, Paper, Table, TextField } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getAmountListForManualAPIURL, getEmployeeListForSantshaPrizeAllocAPIURL, getEndowmentIdAPIURL, getsansthaSchoolAllocListAPIURL, getStudentListForSantshaPrizeAllocAPIURL, saveStudentEmpListForSansthaAPIURL } from '../Services/StudentApprovalAPIURL';
import axios from 'axios';
import { faBullseye, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const ConditionAddStudEmpSansthaTransferList = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);
    const { prId, etran, prFor, ssForm } = useParams();

    const titleId = (prFor == 1) ? "Add Students" : "Add Employee"


    const location = useLocation();

    // Access paritoshikName from location state
    const prName = location.state?.paritoshikName;
    const praizeId = location.state?.pId

    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    // console.log(JSON.stringify(data));

    const [searchTerm, setSearchTerm] = useState("");
    const [chkRegNum, setChkRegNum] = useState('');
    const [srNo, setSrNo] = useState('');
    const [regNum, setRegNum] = useState('');
    const [tempData, setTempData] = useState([]);
    const [amtData, setAmtData] = useState([]);
    const [endowId, setEndowId] = useState('');

    const [updatedSrNo, setUpdatedSrNo] = useState(null);
    const [updatedAmount, setUpdatedAmount] = useState(null);
    const [updatedIsAllocated, setUpdatedIsAllocated] = useState(0);

    const [srNoVal, setSrNoVal] = useState(0)
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [leftFilteredData, setLeftFilteredData] = useState([]);
    const [leftSearchTerm, setLeftSearchTerm] = useState([]);
    const [right, setRight] = useState([]);
    const [rightFilteredData, setRightFilteredData] = useState([]);
    const [rightSearchTerm, setRightSearchTerm] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        const updatedData = data.map(item => ({
            ...item,
            // Set amount from amtData only if it has not been manually set
            amount: amtData[item.srNo] !== undefined && item.amount === 0 ? amtData[item.srNo] : item.amount,
        }));
        setFilteredData(updatedData);
        setData(updatedData);

    }, []);

    // console.log(JSON.stringify(newFilterData))

    const TableHeading = [
        { label: 'खाते क्रमांक', key: 'prizeCode' },
        { label: `पारितोषिकाचे नाव`, key: 'prizeName' },
        { label: `योजनेची माहिती`, key: 'approvalStatus' },
        { label: `Action`, key: 'action', isAction: true },
    ];



    useEffect(() => {
        getData();
    }, [authUser, prFor])

    const getData = async () => {
        setLoaderOption(true);
        // console.log(`${getStudentListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        if (authUser.sessionYear != "" && authUser.branchId != null && authUser.deptId != null) {
            // console.log(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
            await axios.get(`${getAmountListForManualAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}&sid=0&bid=0`)
                .then(res => {
                    setAmtData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })

            await axios(`${getEndowmentIdAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setEndowId(res.data);
                });
        }



        if (authUser.sessionYear != "") {
            if (prFor == 1) {
                console.log(`${getStudentListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                await axios.get(`${getStudentListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                    .then(res => {
                        // Separate the data based on the `isAllocated` field
                        const leftList = res.data.filter(student => student.isAllocated == 0);
                        const rightList = res.data.filter(student => student.isAllocated == 1);

                        // Set state for the left list with students who are not allocated
                        setLeft(leftList);
                        setLeftFilteredData(leftList);

                        // Set state for the right list with students who are allocated
                        setRight(rightList);
                        setRightFilteredData(rightList);

                        // Turn off the loader
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
            else if (prFor == 2) {
                console.log(`${getEmployeeListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                await axios.get(`${getEmployeeListForSantshaPrizeAllocAPIURL}?prizeCode=${prId}&etranNo=${etran}&year=${authUser.sessionYear}`)
                    .then(res => {
                        // Separate the data based on the `isAllocated` field
                        const leftList = res.data.filter(student => student.isAllocated == 0);
                        const rightList = res.data.filter(student => student.isAllocated == 1);

                        // Set state for the left list with students who are not allocated
                        setLeft(leftList);
                        setLeftFilteredData(leftList);

                        // Set state for the right list with students who are allocated
                        setRight(rightList);
                        setRightFilteredData(rightList);

                        // Turn off the loader
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Student approval get err" + err);
                    })
            }
            
        }

    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        // Remove selected students from the left list
        setLeft(not(left, leftChecked));
        setLeftFilteredData(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        setRightSearchTerm("");
        setLeftSearchTerm("");

        // Get the total amount for the selected srNo
        const totalAmount = amtData[srNoVal] ?? 0;
        const numberOfStudentsToAdd = leftChecked.length;

        if (numberOfStudentsToAdd === 0) {
            alert("Please select at least one student from the left list.");
            return;
        }

        // Check if the srNo is already in the right list
        const existingStudentsWithSrNo = right.filter(student => student.srNo === parseInt(srNoVal));
        const totalStudentsWithSrNo = existingStudentsWithSrNo.length + numberOfStudentsToAdd;

        // Calculate the equal amount per student
        const equalAmountPerStudent = Math.floor(totalAmount / totalStudentsWithSrNo);
        const remainder = totalAmount % totalStudentsWithSrNo;

        // Update existing students with the new calculated amount
        const updatedRightData = right.map((student, index) => {
            if (student.srNo === parseInt(srNoVal)) {
                student.amount = equalAmountPerStudent + (index < remainder ? 1 : 0);
            }
            return student;
        });

        // Add new students to the right list with the calculated amount
        leftChecked.forEach((student, index) => {
            updatedRightData.push({
                ...student,
                srNo: parseInt(srNoVal),
                isAllocated: 1,
                amount: equalAmountPerStudent + (existingStudentsWithSrNo.length + index < remainder ? 1 : 0)
            });
        });

        setRight(updatedRightData);
        setRightFilteredData(updatedRightData);
        setSrNoVal(0);
    };

    const handleCheckedLeft = () => {
        setRightSearchTerm("");
        setLeftSearchTerm("");
        // Move checked students from the right to the left
        setLeft(left.concat(rightChecked));
        setLeftFilteredData(left.concat(rightChecked));

        // Remove checked students from the right list
        setRight(not(right, rightChecked));
        setRightFilteredData(not(right, rightChecked));

        // Update checked students state
        setChecked(not(checked, rightChecked));

        // Handle removal and amount recalculation
        handleRemove(rightChecked);
        console.log(rightChecked)
    };

    const handleRemove = (removedStudents) => {
        // Remove the selected students from the right list
        // console.log(JSON.stringify(right))

        const updatedRightData = right.filter(student => !removedStudents.some(removed => removed.regNo === student.regNo));
        // console.log(JSON.stringify(updatedRightData))

        // Get the srNo from rightChecked (assuming all have the same srNo)
        const srNoToRemove = rightChecked.length > 0 ? rightChecked[0].srNo : null;

        if (!srNoToRemove) {
            console.error("No srNo found in rightChecked.");
            return;
        }

        // Find all remaining students with the same srNo
        const remainingStudents = updatedRightData.filter(student => student.srNo === srNoToRemove);

        // Recalculate the total amount for the srNo, defaulting to 0 if not found
        const totalAmount = amtData[srNoToRemove] ?? 0;

        if (remainingStudents.length > 0) {
            // Calculate the base amount per student and the remainder
            const baseAmountPerStudent = Math.floor(totalAmount / remainingStudents.length);
            const remainder = totalAmount % remainingStudents.length;

            // Update the amount for each remaining student with the distributed remainder
            const updatedRightDataWithNewAmounts = updatedRightData.map((student, index) => {
                if (student.srNo === srNoToRemove) {
                    student.amount = baseAmountPerStudent + (index < remainder ? 1 : 0);
                }
                return student;
            });

            // Update the right list state with the updated amounts
            setRight(updatedRightDataWithNewAmounts);
            setRightFilteredData(updatedRightDataWithNewAmounts);
        } else {
            // If no students remain with this srNo, just update the right list without recalculating amounts
            setRight(updatedRightData);
            setRightFilteredData(updatedRightData);
        }
    };

    const leftListData = (leftData) => (
        <Paper sx={{ width: { xs: '100%', sm: 450 }, height: 500, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", top: "0" }}>
                <input
                    className='form-control form-control-sm my-2'
                    type="text"
                    style={{ border: "1px solid #AFAFAE", float: "right", width: "90%", maxWidth: "300px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                    placeholder="Search Here"
                    value={leftSearchTerm}
                    onChange={e => setLeftSearchTerm(e.target.value)}
                />
            </div>

            <Table size="small" stickyHeader sx={{ border: '1px solid #AFAFAE' }}>
                <thead>
                    <tr>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}></th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>{(prFor == 2) ? "emp.Id" : "Reg No"}</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>{(prFor == 2) ? "Employee Name" : "Student Name"}</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>School Name</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }} hidden={(prFor == 2) ? true : false}>Marks Obtained</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (prFor == 1) {
                            return (
                                <>
                                    {leftData.sort((a, b) => a.regNo > b.regNo ? 1 : -1).map((value) => (
                                        <tr key={value.regNo}>
                                            <td style={{ border: '1px solid #AFAFAE', padding: '8px' }}>
                                                <Checkbox
                                                    checked={checked.indexOf(value) !== -1}
                                                    onChange={handleToggle(value)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': value.regNo,
                                                    }}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                />
                                            </td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{value.regNo}</td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{(authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', border: '1px solid #AFAFAE', padding: '8px' }} >{value.schoolName}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", border: '1px solid #AFAFAE', padding: '8px' }} >{value.obtainMarks}</td>
                                        </tr>
                                    ))}
                                </>
                            )
                        }
                        else if (prFor == 2) {
                            return (
                                <>
                                    {leftData.sort((a, b) => a.empId > b.empId ? 1 : -1).map((value) => (
                                        <tr key={value.empId}>
                                            <td style={{ border: '1px solid #AFAFAE', padding: '8px' }}>
                                                <Checkbox
                                                    checked={checked.indexOf(value) !== -1}
                                                    onChange={handleToggle(value)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': value.empId,
                                                    }}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                />
                                            </td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{value.empId}</td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{(authUser.branchMedium == 1) ? value.empNameMr : value.empNameEn}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', border: '1px solid #AFAFAE', padding: '8px' }} >{value.schoolName}</td>
                                        </tr>
                                    ))}
                                </>
                            )
                        }
                    })()}
                </tbody>
            </Table>
        </Paper>
    );

    const rightListData = (rightData) => (
        <Paper sx={{ width: { xs: '100%', sm: 450 }, height: 500, overflow: 'auto', boxShadow: "2px 5px 10px #7F8C8D" }}>
            <div style={{ backgroundColor: "white", position: "sticky", zIndex: "2", top: "0" }}>
                <input
                    className='form-control form-control-sm my-2'
                    type="text"
                    style={{ border: "1px solid #AFAFAE", float: "right", width: "90%", maxWidth: "300px", fontSize: "14px", backgroundColor: "#F0F3F4" }}
                    placeholder="Search Here"
                    value={rightSearchTerm}
                    onChange={e => setRightSearchTerm(e.target.value)}
                />
            </div>

            <Table size="small" stickyHeader sx={{ border: '1px solid #AFAFAE' }}>
                <thead>
                    <tr>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}></th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>{(prFor == 2) ? "emp.Id" : "Reg No"}</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>{(prFor == 2) ? "Employee Name" : "Student Name"}</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>School Name</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }} hidden={(prFor == 2) ? true : false}>Marks Obtained</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>Sr No</th>
                        <th style={{ fontSize: "14px", border: '1px solid #AFAFAE', padding: '8px' }}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (prFor == 1) {
                            return (
                                <>
                                    {rightData.map((value) => (
                                        <tr key={value.regNo}>
                                            <td style={{ border: '1px solid #AFAFAE', padding: '8px' }}>
                                                <Checkbox
                                                    checked={checked.indexOf(value) !== -1}
                                                    onChange={handleToggle(value)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': value.regNo,
                                                    }}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                />
                                            </td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{value.regNo}</td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{(authUser.branchMedium == 1) ? value.studentNameMr : value.studentNameEn}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', border: '1px solid #AFAFAE', padding: '8px' }} >{value.schoolName}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", border: '1px solid #AFAFAE', padding: '8px' }} >{value.obtainMarks}</td>
                                        </tr>
                                    ))}
                                </>
                            )
                        }
                        else if (prFor == 2) {
                            return (
                                <>
                                    {rightData.map((value) => (
                                        <tr key={value.empId}>
                                            <td style={{ border: '1px solid #AFAFAE', padding: '8px' }}>
                                                <Checkbox
                                                    checked={checked.indexOf(value) !== -1}
                                                    onChange={handleToggle(value)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': value.empId,
                                                    }}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                />
                                            </td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{value.empId}</td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{(authUser.branchMedium == 1) ? value.empNameMr : value.empNameEn}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5', border: '1px solid #AFAFAE', padding: '8px' }} >{value.schoolName}</td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{value.srNo}</td>
                                            <td style={{ fontSize: '14px', fontWeight: '500', border: '1px solid #AFAFAE', padding: '8px' }}>{value.amount}</td>
                                        </tr>
                                    ))}
                                </>
                            )
                        }
                    })()}
                </tbody>
            </Table>
        </Paper>
    );



    useEffect(() => {
        if (prFor == 1) {
            setLeftFilteredData(
                left.filter((item) =>
                    (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.obtainMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.schoolName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.regNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase())
                ))
        }
        else if (prFor == 2) {
            setLeftFilteredData(
                left.filter((item) =>
                    (item.empId || '').toString().toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                    (item.empNameMr || '').toLowerCase().includes(leftSearchTerm.toLowerCase()) ||
                    (item.empNameEn || '').toLowerCase().includes(leftSearchTerm.toLowerCase())
                ))
        }
    }, [leftSearchTerm, prFor])

    useEffect(() => {
        if (prFor == 1) {
            setRightFilteredData(
                right.filter((item) =>
                    (item.srNo || '').toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.regNo || '').toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.amount || '').toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.studentNameMr || '').toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.obtainMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.schoolName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (item.studentNameEn || '').toLowerCase().includes(rightSearchTerm.toLowerCase())
                ))
        }
        else if (prFor == 2) {
            setRightFilteredData(
                right.filter((item) =>
                    (item.srNo || '').toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.empId || '').toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.amount || '').toString().toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.empNameMr || '').toLowerCase().includes(rightSearchTerm.toLowerCase()) ||
                    (item.empNameEn || '').toLowerCase().includes(rightSearchTerm.toLowerCase())
                ))
        }
    }, [rightSearchTerm, prFor])  

    const handleInputChange = (e, key) => {
        const updatedValue = e.target.value;
        setAmtData({
            ...amtData,
            [key]: updatedValue, // Update the specific key's value
        });
    };

    const saveManualStudentList = (e) => {
        e.preventDefault();
        setLoaderOption(true);

        const newData = tempData.map(e => ({
            prizeCode: prId,
            etranNo: etran,
            regNo: (prFor == 1) ? e.regNo : e.empId,
            srNo: e.srNo,
            branchId: e.bid,
            isAllocated: e.isAllocated,
            sessionYear: authUser.sessionYear,
            amount: e.amount,
            obtainedMarks: e.obtainMarks
        }));

        const filterNewData = newData.filter(e => e.isAllocated == 1)
        console.log(JSON.stringify(filterNewData));
        if (filterNewData.length != 0) {

            axios.post(`${saveStudentEmpListForSansthaAPIURL}`, filterNewData)
                .then((response) => {
                    if (response.data == "AMTNOTMATCHED") {
                        setLoaderOption(false);
                        toast.warn(`${srNo} SrNo  amount does not match with prize amount.`);
                    }
                    else if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
        else {
            const saveObj = [{
                prizeCode: prId,
                etranNo: etran,
                regNo: 0,
                branchId: 0,
                isAllocated: 0,
                srNo: 0,
                sessionYear: authUser.sessionYear,
                amount: 0,
                obtainedMarks: 0
            }]
            console.log(JSON.stringify((prFor == 2) ? saveObj : newData));
            axios.post(`${saveStudentEmpListForSansthaAPIURL}`, (prFor == 2) ? saveObj : newData)
                .then((response) => {
                    if (response.data == "SAVED") {
                        navigate(`/Home/et722pf/${praizeId}/${ssForm}`)
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                    setLoaderOption(false);
                    toast.error("Something went wrong.");
                })
        }
    }

    // console.log(JSON.stringify(filteredData)); // Log combined data for debugging
    // const saveSanthaStudentEmpList = e => {
    //     e.preventDefault();
    //     // if (prFor == 1) {
    //     saveManualStudentList();
    //     // }
    //     // else if (prFor == 2) {

    //     // }
    // }


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        <div className=''>
                            <span style={{ color: '#000', fontWeight: '600', marginBottom: '5px' }}>Prize Name : <span style={{ color: '#fe3627', fontWeight: '600' }}>{prName}</span></span>
                        </div>
                        <hr />
                        <div>
                            {
                                (left.length != 0 || leftFilteredData.length != 0)
                                    ?
                                    <>
                                        <form onSubmit={saveManualStudentList}>
                                            {(() => {

                                                if (left.length != 0 || leftFilteredData.length != 0) {
                                                    return (
                                                        <>

                                                            <div className="row mt-3">
                                                                <label className="col-sm-1" hidden={(amtData.length == 0) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >Prize Amount :</label>
                                                                {Object.entries(amtData).map(([key, value], index) => (
                                                                    <div className='' key={index} style={{ width: '100px' }}>
                                                                        <TextField
                                                                            label={`Value ${key}`} // Label for each text field
                                                                            value={value}           // Set the initial value from the object
                                                                            onChange={(e) => handleInputChange(e, key)} // Handle changes
                                                                            fullWidth
                                                                            inputProps={{ readOnly: true }}
                                                                            size='small'
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div className="row mt-3">
                                                                <div className="col-sm-12">
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item>{leftListData(leftFilteredData)}</Grid>
                                                                        <Grid item>
                                                                            <Grid container direction="column" alignItems="center">
                                                                                {/* Sr No Label and Input on the Same Line */}
                                                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                                                    <label style={{ fontSize: '14px', fontWeight: '600', marginRight: '8px' }}>Sr No. :</label>
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="text"
                                                                                        style={{
                                                                                            width: "80px",
                                                                                            border: "1px solid #C2C1C1",
                                                                                            fontSize: "14px"
                                                                                        }}
                                                                                        placeholder="Sr No."
                                                                                        onChange={(event) => { setSrNoVal(event.target.value); }}
                                                                                        value={srNoVal}
                                                                                    />
                                                                                </div>

                                                                                <button
                                                                                    className="my-1"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    onClick={handleCheckedRight}
                                                                                    disabled={leftChecked.length === 0 || srNoVal <= 0}
                                                                                    aria-label="move selected right"
                                                                                    style={{ width: "30px" }}
                                                                                >
                                                                                    &gt;
                                                                                </button>
                                                                                <button
                                                                                    className="my-1"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    onClick={handleCheckedLeft}
                                                                                    disabled={rightChecked.length === 0}
                                                                                    aria-label="move selected left"
                                                                                    style={{ width: "30px" }}
                                                                                >
                                                                                    &lt;
                                                                                </button>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>{rightListData(rightFilteredData)}</Grid>
                                                                    </Grid>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col-sm-4">
                                                                    <button type='submit' className='btn btn-sm btn-primary mr-2'>Save</button>
                                                                    <button type='button' className='btn btn-sm btn-danger' onClick={() => navigate(`/Home/et722pf/${praizeId}/${ssForm}`)}>Cancel</button>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }

                                            })()}
                                        </form>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div >
            }
        </>
    )
}


export default ConditionAddStudEmpSansthaTransferList
