import { BaseURLMaster } from "../../../CommonServices/APIURL";


//http://192.168.29.102:8081/institute-sss/institute/sss/master/saveDepartmentToSchoolEndowmentAllocation
export const saveDeptToSChoolAPI = BaseURLMaster + "saveDepartmentToSchoolEndowmentAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/updateDepartmentToSchoolEndowmentAllocation
export const updateDeptToSChoolAPI = BaseURLMaster + "updateDepartmentToSchoolEndowmentAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/deleteDepartmentToSchoolEndowmentAllocation?id=1
export const deleteDeptToSChoolAPI = BaseURLMaster + "deleteDepartmentToSchoolEndowmentAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/master/getDepartmentToSchoolEndowmentAllocationList
export const getDeptToSChoolAPI = BaseURLMaster + "getDepartmentToSchoolEndowmentAllocationList";
