import { BaseURL } from "../../../CommonServices/APIURL";


//http://192.168.29.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/getEndowmentPrizeAllocationList?sid=3&bid=1
export const getStudentApprovalAllocationData = BaseURL + "endowment/prizeAllocation/getEndowmentPrizeAllocationList";

//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/getStudentListForEndowmentPrizeAllocation?prizeCode=1401&etranNo=1&year=2024&sid=3&bid=1
export const getSchoolStudentListForManualAPIURL = BaseURL + "endowment/prizeAllocation/getStudentListForEndowmentPrizeAllocation";

//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/sansthaPrizeAllocation/getSansthaSchoolAllocationList?prizeCode=444&etranNo=1&year=2024
export const getsansthaSchoolAllocListAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/getSansthaSchoolAllocationList";

//getSansthaEndowmentPrizeAllocationList
export const getStudentApprovalAllocationDataForSanstha = BaseURL + "endowment/sansthaPrizeAllocation/getSansthaEndowmentPrizeAllocationList";

//getAllSansthaEndowmentPrizeAllocation
export const getSanthaStudentApprovalAllocationData = BaseURL + "endowment/sansthaPrizeAllocation/getAllSansthaEndowmentPrizeAllocation";


//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/getEndowmentMasterDataByIdAndSectionIdAndBranchId?id=1332
export const getPrizeDetailsData = BaseURL + "endowment/prizeAllocation/getEndowmentMasterDataByIdAndSectionIdAndBranchId";

export const getPrizeDetailsDataForSanstha = BaseURL + "endowment/prizeAllocation/getEndowmentMasterDataById";

//getEndowmentMasterDataByIdAndSectionIdAndBranchIdForSansthaPrize
export const getPrizeDetailsDataForSansthaSchoolLogin = BaseURL + "endowment/sansthaPrizeAllocation/getEndowmentMasterDataByIdAndSectionIdAndBranchIdForSansthaPrize";



//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/sansthaPrizeAllocation/getStudentListForSansthaEndowmentPrizeAllocation?prizeCode=1412&etranNo=1&year=2024&sid=3&bid=1
export const getStudentListForManualAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/getStudentListForSansthaEndowmentPrizeAllocation";

//http://192.168.29.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/saveStudentListForEndowmentPrizeAllocation
export const saveStudentListForManualAPIURL = BaseURL + "endowment/prizeAllocation/saveStudentListForEndowmentPrizeAllocation";

//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/getAmountByPrizeAndEtranNo?prizeCode=1362&etranNo=1
export const getAmountListForManualAPIURL = BaseURL + "endowment/prizeAllocation/getAmountByPrizeAndEtranNo";

//Santha add stud api 
// /getSansthaEndowmentSchoolAllocationList
export const getStudentListForSantshaPrizeAllocAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/getSansthaEndowmentSchoolAllocationList";

//getSansthaEmployeeEndowmentAllocationList
export const getEmployeeListForSantshaPrizeAllocAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/getSansthaEmployeeEndowmentAllocationList";

//approveSansthaEndowmentPrizeAllocationData
export const saveStudentEmpListForSansthaAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/approveSansthaEndowmentPrizeAllocationData";


//saveSansthaManualStudentAPIURL
//http://192.168.29.102:8081/institute-sss/institute/sss/endowment/sansthaPrizeAllocation/saveSansthaEndowmentPrizeAllocationData
export const saveSansthaStudentListForManualAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/saveSansthaEndowmentPrizeAllocationData";

//getStudentListForSansthaEndowmentByClassIdAndSubjectId
export const getStudentListForSantshaPrizeAllocForAutomaticAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/getStudentListForSansthaEndowmentByClassIdAndSubjectId";


//getStatusApproval api
//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/updateStatusByPrizeAndEtranNo?prizeCode=1411&etranNo=1&status=2&year=2024
export const saveStatusApprovalAPIURL = BaseURL + "endowment/prizeAllocation/updateStatusByPrizeAndEtranNo";

// /192.168.195.102:8081/institute-sss/institute/sss/endowment/sansthaPrizeAllocation/updateSansthaPrizeStatusByPrizeAndEtranNo?prizeCode=159&etranNo=1&status=0&year=2024
export const updateStatusApprovalForSansthaAPIURL = BaseURL + "endowment/sansthaPrizeAllocation/updateSansthaPrizeStatusByPrizeAndEtranNo";



//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/getStudentListForEndowmentPrizeAllocationByClassAndSubject?prizeCode=1400&etranNo=1&classId=9&subId=45&examYear=2023&year=2024&sid=3&bid=1
export const getStudentListForAutomaticAPIURL = BaseURL + "endowment/prizeAllocation/getStudentListForEndowmentPrizeAllocationByClassAndSubject";

//http://192.168.195.102:8081/institute-sss/institute/sss/endowment/prizeAllocation/getEmployeeListForEndowmentPrizeAllocation?prizeCode=1412&etranNo=1&year=2024&sid=3&bid=1
export const getEmployeeListForManualAPIURL = BaseURL + "endowment/prizeAllocation/getEmployeeListForEndowmentPrizeAllocation";

//http://192.168.195.102:8081/institute-sss/institute/sss/system/getEndowmentId?sid=3&bid=1
export const getEndowmentIdAPIURL = BaseURL + "system/getEndowmentId";



//Santsha get apiurl
// /getStudentListForSansthaEndowmentPrizeAllocation
// getSansthaEndowmentPrizeAllocationList
// getAllSansthaEndowmentPrizeAllocation





