import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import { getSchoolNameByEndowmentId } from '../../Services/EndowmentMasterAPIURL';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SchoolEndowmentTextBox from '../SchoolEndowmentTextBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { deletePrizeDistributionData } from '../../Services/EndowmentMasterAPIURL';
import NoOfStudTextfield1 from '../EndowMasterTextfields/NoOfStudTextfield1';
import NoOfStudTextfield2 from '../EndowMasterTextfields/NoOfStudTextfield2';
import NoOfStudTextfield3 from '../EndowMasterTextfields/NoOfStudTextfield3';
import NoOfStudTextfield4 from '../EndowMasterTextfields/NoOfStudTextfield4';
import NoOfStudTextfield5 from '../EndowMasterTextfields/NoOfStudTextfield5';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getSchoolListForEndowment } from '../../Services/EndowmentMasterAPIURL';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const EditConditionsTextBox = ({ classData, meritData, subjectData, initialText1, initialText2, initialText3,
    initialText4, initialText5, initialText6, initialText7, initialText8, initialText9, initialText10,
    initialText11, initialText12, initialText13, initialText14, initialText15, initialText16, initialText17,
    initialText18, initialText19, setData, data, i, getData, newData, item, setPrizeId, setEtran,
    schoolDataForEndow, myRef, setUpdateBtnDisabled, setNewData, schoolEndowmentData, setSubjectData, setCheckZeroAmount,
    setEndowmentSubData, getSubjectData, getSubjectEndowment, endowSubdata, onChange, classes, newSchooldata, setNewUpdatedData, regionData, deptData,
    initialText20, initialText21, initialText22 }) => {


    const authUser = useSelector((state) => state.user.value);

    //prizeFrom
    const [text1, setText1] = useState(initialText1);
    //prizeFor
    const [text2, setText2] = useState(initialText2);
    //school dropdown
    const [text3, setText3] = useState('');
    const [text9, setText9] = useState(null);
    //amountInPercent
    const [text4, setText4] = useState('');
    //Class
    const [text5, setText5] = useState(initialText5);
    const [text10, setText10] = useState(initialText10);
    //noOfStudents
    const [text6, setText6] = useState(initialText6);
    const [text12, setText12] = useState(initialText12);
    const [text14, setText14] = useState(initialText16);
    //subject
    const [text7, setText7] = useState(initialText7);
    const [text11, setText11] = useState(initialText11);
    //gender
    const [text8, setText8] = useState(initialText8);
    //subject Type
    const [text13, setText13] = useState(initialText15);
    //id
    const [id, setId] = useState('');
    //sanstha info
    const [text15, setText15] = useState(initialText17);
    //branchType
    const [text16, setText16] = useState(initialText18);
    //forBranch
    const [text17, setText17] = useState(initialText19);
    //multiple school dropdown
    const [text18, setText18] = useState(newSchooldata);
    const [text19, setText19] = useState([]);

    // console.log(JSON.stringify(regionData.find(e => e.regionId == initialText20)))
    // const region = regionData.find(e => e.regionId == initialText20);
    // const regionName = region ? region.regionName : null;  // Safely access regionName if region is found
    // console.log(text10);  // This will log "Sangli"

    const dept = deptData.find(e => e.deptId == initialText20);
    const deptName = dept ? dept.deptNameMr : null;  // Safely access deptName if dept is found
    // console.log(deptName);  // This will log "Sangli"


    const [text20, setText20] = useState(null);
    const [text21, setText21] = useState(null);
    const [text22, setText22] = useState(initialText4)

    const [text23, setText23] = useState(initialText21);
    const [text24, setText24] = useState(initialText22);
    // console.log(text23)

    const [text25, setText25] = useState(0);

    const HandleAllSubCheckbox = (e) => {
        setText25(e.target.checked);
        setText11(null);
        setText7('');
        if (e.target.checked == true) {
            setText25(1);
        }
        else {
            setText25(0);
        }
    }


    const [result1, setResult1] = useState([]);

    useEffect(() => {
        if (text1 == 2) {
            if (text16 == 3) {
                setText20(initialText20)
                // console.log(regionData.find(e => e.regionId == initialText20))
            }
            else if (text16 == 4) {
                setText21(initialText20)
                // console.log(deptData.find(e => e.departmentId == initialText20))
            }
        }

    }, [text1, text16])

    useEffect(() => {
        data.filter(e => {
            if (e.branchType == 2 && e.forBranch == 2 && e.etranNo == initialText13) {
                setResult1(e.schoolDataList);
            }
        })
    }, [])

    //filter schoolEndowment dropdown list 
    let res2 = schoolDataForEndow.filter((el1) => {
        if (newData.filter(e => e.etranNo == initialText13).some((el2) => el2.branchId === el1.endowmentId) == false) {
            return el1
        }
    })

    let res = schoolDataForEndow.filter((el1) => {
        if (result1.some((el2) => el2.endowmentId === el1.endowmentId) == false) {
            return el1
        }
    })

    //add school in dropdown if it remove from autocomplete textfield
    const [newSchoolDataForEndow, setNewSchoolDataForEndow] = useState(res);

    //No of students code
    var result = {};
    //divideStudLength = text14.split(",").length;
    if (text14 !== null) {
        text14.split(',').forEach((e, i) => {
            result[i] = e;
        });
    }

    //get subject by subject type
    const getSubjectListById = async (sId) => {
        if (sId == 1) {
            await axios.get(`${getSubjectData}`)
                .then((res) => {
                    setSubjectData(res.data);
                    //setSubjectObj(res.data[0])
                }).catch(error => {
                    console.log(error);
                })
        }
        else if (sId == 2) {
            await axios.get(`${getSubjectEndowment}`)
                .then((res) => {
                    setEndowmentSubData(res.data);
                    //setSubjectObj(res.data[0])
                }).catch(error => {
                    console.log(error);
                })
        }
        else {
            setSubjectData([]);
        }
    }

    //get school by endowment id
    const getSchoolNameById = async (eId) => {
        //console.log(eId);
        if (eId <= 0) {
            setText3("");
            toast.warn("School no should not be less than or equal to zero.")
        }
        else if (eId !== "") {
            axios.get(`${getSchoolNameByEndowmentId}?endowmentId=${eId}`)
                .then((response) => {
                    //console.log(response.data);
                    if (response.data == "") {
                        toast.warn(`School endowment no ${eId} is not allocated to any school.`)
                    }
                    else {
                        setText9(response.data);
                        //setText14(response.data);
                    }
                })
        }
    }

    //console.log(newData);

    //to check school data is exist or not for make field mandatory
    let removeData = newData.filter((e) => e.etranNo == initialText13 && e.branchId !== 0)

    //remove schooldata by etranNo at onchange for updation
    const romoveDataByEtran = () => {
        let dataByEtran = newData.filter(e => e.etranNo !== initialText13)
        console.log(dataByEtran)
        setNewData(dataByEtran)
    }

    // console.log(JSON.stringify(newData));

    //add multiple school in one condition
    const addSchool = () => {
        // console.log(text4);
        setText3('');
        setText4('');
        setText9(null);
        let updatedData = []; // Initialize as an array

        if (newData.length === 0) {
            // If no existing data, add the first item
            updatedData.push({
                id: 1,
                schoolNameMr: text9?.schoolName || '',
                branchId: text3,
                etranNo: initialText13,
                amountInPercent: ((text1 === 2 && text16 === 1) || (text1 === 2 && text16 === 2 && text17 === 2)) ? "100" : text4,
            });
        } else {
            // Iterate over existing data and add new items
            newData.forEach((e, index) => {
                let incrementedId = newData[newData.length - 1].id + 1;
                console.log(e.etranNo + "=========" + initialText13);

                if (e.etranNo !== initialText13) {
                    updatedData.push({
                        ...e,
                        id: incrementedId,
                        schoolNameMr: text9?.schoolName || '',
                        branchId: text3,
                        etranNo: initialText13,
                        amountInPercent: ((text1 === 2 && text16 === 1) || (text1 === 2 && text16 === 2 && text17 === 2)) ? "100" : text4,
                    });
                } else {
                    updatedData.push({
                        ...e,
                        id: incrementedId,
                        schoolNameMr: text9?.schoolName || '',
                        branchId: text3,
                        amountInPercent: ((text1 === 2 && text16 === 1) || (text1 === 2 && text16 === 2 && text17 === 2)) ? "100" : text4,
                    });
                }
            });
        }

        let totalAmtInPercent = newData.concat(updatedData).reduce((prev, next) => prev + parseFloat(next.amountInPercent), 0);
        // console.log("totalAmtInPercent=" + totalAmtInPercent);

        if (totalAmtInPercent <= 100) {
            setNewData(newData.concat(updatedData));
        } else {
            toast.error('Total amount in percent should not be greater than 100.');
        }
    };


    return (
        <>
            <div className="row py-2 mb-2 mt-4" style={{ backgroundColor: "#FBFBDB", border: "1px solid #922B21" }}>

                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                <div className="row my-3">
                    <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस कोणाकडे :</label>
                    <div className='col-sm-3'>
                        <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>बक्षिस कोणाकडे</InputLabel>
                            <Select
                                MenuProps={{ classes: { paper: classes.select } }}
                                size='small'
                                fullWidth
                                //inputProps={{ readOnly: true }}
                                margin="dense"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={text1}
                                label="बक्षिस कोणाकडे"
                                onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                onChange={(e) => {
                                    setText1(e.target.value);
                                    setText2(1);
                                    setText3('');
                                    setText9(null);
                                    setText4('');
                                    setText10(null);
                                    setText11(null);
                                    setText12(null);
                                    setText13(null);
                                    setText15("");
                                    setText16('');
                                    setText17('');
                                    setText18([]);
                                    setText23(null);
                                    setText24(null);
                                    setText22('');
                                    romoveDataByEtran();
                                    if (text2 == 1) {
                                        setText8(1);
                                    }

                                }}
                            >
                                <MenuItem value={1}>School</MenuItem>
                                <MenuItem value={2}>Sanstha</MenuItem>

                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस कोणाला :</label>
                    <div className='col-sm-3'>
                        <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>बक्षिस कोणाला</InputLabel>
                            <Select
                                MenuProps={{ classes: { paper: classes.select } }}
                                size='small'
                                fullWidth
                                //inputProps={{ readOnly: true }}
                                margin="dense"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={text2}
                                label="बक्षिस कोणाला"
                                onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                onChange={(e) => {
                                    setText2(e.target.value);
                                    setText3('');
                                    setText9(null);
                                    setText4('');
                                    setText10(null);
                                    setText11(null);
                                    setText12(null);
                                    setText13(null);
                                    setText15("");
                                    setText16('');
                                    setText17('');
                                    setText18([]);
                                    setText23(null);
                                    setText24(null);
                                    setText22('');
                                    romoveDataByEtran();
                                    if (text2 == 1) {
                                        setText8(1);
                                    }
                                }}
                            >
                                <MenuItem value={1}>Student</MenuItem>
                                <MenuItem value={2}>Teacher</MenuItem>
                                <MenuItem hidden={(text1 == 1) ? true : false} value={3}>Institute</MenuItem>

                            </Select>
                        </FormControl>
                    </div>
                </div>

                {(() => {
                    if (text1 == 2) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस वितरण :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>बक्षिस वितरण</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                                size='small'
                                                //inputProps={{ readOnly: true }}
                                                fullWidth
                                                margin="dense"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={text16}
                                                label="बक्षिस वितरण"
                                                onChange={(e) => {
                                                    setText16(e.target.value);
                                                    setText9(null);
                                                    setText4('');
                                                    setText10(null);
                                                    setText11(null);
                                                    setText12(null);
                                                    setText13(null);
                                                    setText15("");
                                                    setText17('');
                                                    setText18([]);
                                                    setText23(null);
                                                    setText24(null);
                                                    setText22('');
                                                    romoveDataByEtran();
                                                    if (text2 == 1) {
                                                        setText8(1);
                                                    }
                                                }}
                                            >
                                                <MenuItem value={1}>All Schools</MenuItem>
                                                <MenuItem value={2}>School And Department Wise</MenuItem>
                                                <MenuItem value={3}>Region Wise</MenuItem>
                                                <MenuItem value={4}>Department Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {(() => {
                                        if (text16 == 2) {
                                            return (
                                                <>

                                                    {/* <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस वितरण :</label> */}
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth>
                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>बक्षिसाचे वाटप</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                                                size='small'
                                                                fullWidth
                                                                //inputProps={{ readOnly: true }}
                                                                margin="dense"
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={text17}
                                                                label="बक्षिसाचे वाटप"
                                                                onChange={(e) => {
                                                                    setText17(e.target.value);
                                                                    setText9(null);
                                                                    setText4('');
                                                                    setText10(null);
                                                                    setText11(null);
                                                                    setText12(null);
                                                                    setText13(null);
                                                                    setText15("");
                                                                    setText18([]);
                                                                    setText23(null);
                                                                    setText24(null);
                                                                    setText22('');
                                                                    romoveDataByEtran();
                                                                    if (text2 == 1) {
                                                                        setText8(1);
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={1}>प्रत्येकी</MenuItem>
                                                                <MenuItem value={2}>यापैकी</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}
                                </div>
                            </>
                        )
                    }
                })()}

                {(() => {
                    if (text1 == 2 && text16 == 3) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शहर :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={regionData}
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={text23}
                                            onChange={(event, newValue) => {
                                                setText23(newValue);
                                                setText22('');
                                                if (newValue != null) {
                                                    setText20(newValue.id);
                                                }
                                                else {
                                                    setText20(0);
                                                }
                                            }}
                                            inputValue={text23 || ''}
                                            onInputChange={(event, newInputValue) => {
                                                //console.log(newInputValue);
                                                setText23(newInputValue || '');
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                                    return option.regionName
                                                }
                                                // Regular option
                                                return option.regionName
                                            }

                                            }
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="शहर" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                            )}
                                        />
                                    </div>

                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required={(removeData.length == 0) ? true : false}
                                            fullWidth
                                            type="number"
                                            //style={{ width: "100px" }}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            onWheel={(e) => e.target.blur()}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="रक्कम"
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            value={text22}
                                            onChange={(e) => setText22(e.target.value)}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                </div>
                            </>
                        )
                    }
                    else if (text1 == 2 && text16 == 4) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>विभाग :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={deptData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={text24}
                                            onChange={(event, newValue) => {
                                                setText24(newValue);
                                                setText22('');
                                                if (newValue != null) {
                                                    setText21(newValue.departmentId);
                                                }
                                                else {
                                                    setText21(0);
                                                }
                                            }}
                                            inputValue={text24 || ''}
                                            onInputChange={(event, newInputValue) => {
                                                //console.log(newInputValue);
                                                setText24(newInputValue || '');
                                            }}
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            style={{ fontSize: '14px' }}
                                            // getOptionLabel={option => (authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}
                                            getOptionLabel={option => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                                    return (authUser.branchMedium == 1) ? (option.deptNameMr || '') : (option.deptNameEn || '')
                                                }
                                                // Regular option
                                                return (authUser.branchMedium == 1) ? (option.deptNameMr || '') : (option.deptNameEn || '')
                                            }

                                            }
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="विभाग" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                            )}
                                        />
                                    </div>

                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required={(removeData.length == 0) ? true : false}
                                            fullWidth
                                            type="number"
                                            //style={{ width: "100px" }}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            onWheel={(e) => e.target.blur()}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="रक्कम"
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            value={text22}
                                            onChange={(e) => setText22(e.target.value)}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                    else if (text1 == 1 || (text1 == 2 && text16 == 2 && text17 == 1)) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शाळा :</label>
                                    <div className='col-sm-5'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={res2}
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={text9}
                                            filterSelectedOptions
                                            onChange={(event, newValue) => {
                                                setText9(newValue);
                                                setText4('');
                                                if (newValue !== null) {
                                                    setText3(newValue.endowmentId)
                                                }
                                                else if (newValue == null) {
                                                    setText4('');
                                                }
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="शाळा" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required={(removeData.length == 0) ? true : false}
                                                />
                                            )}
                                        />
                                    </div>

                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required={(removeData.length == 0) ? true : false}
                                            fullWidth
                                            type="number"
                                            //style={{ width: "100px" }}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            onWheel={(e) => e.target.blur()}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="रक्कम"
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            value={text4}
                                            onChange={(e) => setText4(e.target.value)}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                    <div className="col-sm-1" hidden={(text9 == '' || text9 == null || text4 == '') ? true : false}>
                                        <button type='button' className='btn p-0 mt-3' onClick={() => addSchool()}>
                                            <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                        </button>
                                    </div>
                                </div>
                                {
                                    (() => {
                                        if (newData.length !== 0) {
                                            return (
                                                <span hidden={(newData.length == 0 || removeData.length == 0) ? true : false} className='container' style={{ backgroundColor: "#FBEEE6", borderTop: "2px solid #F9EBEA", borderBottom: "2px solid #F9EBEA" }}>

                                                    {
                                                        newData.filter((e, index) => e.etranNo == initialText13 && e.branchId !== 0).map((e, index) => {
                                                            return (
                                                                <>

                                                                    <SchoolEndowmentTextBox
                                                                        initialText1={e.branchId}
                                                                        initialText2={e.amountInPercent}
                                                                        initialText3={(authUser.branchMedium == 1) ? e.schoolNameMr : e.schoolNameEn}
                                                                        initialText4={e.etranNo}
                                                                        setUpdateBtnDisabled={setUpdateBtnDisabled}
                                                                        schoolIndex={index}
                                                                        id={e.id}
                                                                        key={e.id}
                                                                        isLast={index === newData.length - 1}
                                                                        newData={newData}
                                                                        setNewData={setNewData}
                                                                        myRef={myRef}
                                                                        getSchoolNameByEndowmentId={getSchoolNameByEndowmentId}
                                                                        onChange={(value1, value2) => {
                                                                            let updatedData = newData;
                                                                            //console.log(JSON.stringify(updatedData));                                                                         
                                                                            newData.map((item, index) => {
                                                                                if (item.id == e.id) {
                                                                                    updatedData[index] = ({
                                                                                        ...e,
                                                                                        branchId: value1,
                                                                                        amountInPercent: value2
                                                                                    });
                                                                                }
                                                                            })

                                                                            setNewData(updatedData)
                                                                            let totalAmtInPercent = updatedData.reduce((total, obj) => {
                                                                                if (obj.etranNo === e.etranNo) {
                                                                                    return parseInt(total) + parseInt(obj.amountInPercent);
                                                                                }
                                                                                return parseInt(total);
                                                                            }, 0);
                                                                            console.log("totalAmtInPercent=" + totalAmtInPercent)
                                                                            setNewUpdatedData(totalAmtInPercent)
                                                                            console.log(JSON.stringify(updatedData))

                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            )
                                        }
                                    })()
                                }
                            </>
                        )
                    }
                    else if (text1 == 2 && text16 == 2 && text17 == 2) {
                        return (
                            <>
                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शाळा :</label>
                                    <div className='col-sm-7'>
                                        <Autocomplete
                                            multiple
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            id="combo-box-demo"
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            options={res.concat(newSchoolDataForEndow)}
                                            fullWidth
                                            filterSelectedOptions
                                            disableCloseOnSelect
                                            getOptionDisabled={(option) => option.disableOption === true}
                                            getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                                            disableClearable
                                            value={text18}
                                            onChange={(e, newValue, reason, details) => {
                                                setText19(newValue.map(e => e.endowmentId).join(','));
                                                //console.log((item.schoolDataList).find(e=>e.endowmentId == text19))
                                                setText18(newValue);
                                                if (reason === "removeOption") {
                                                    // console.log((item.schoolDataList).filter(e => e.endowmentId == details.option.endowmentId))
                                                    let removedSchool = (item.schoolDataList).filter(e => e.endowmentId == details.option.endowmentId)
                                                    setNewSchoolDataForEndow(newSchoolDataForEndow.concat(removedSchool))
                                                    let filteredData = (item.schoolDataList).filter(e => e.endowmentId != details.option.endowmentId)
                                                    console.log(filteredData)
                                                    onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, filteredData)
                                                }
                                            }}
                                            // renderOption={(props, option, { selected }) => (
                                            //     <li {...props}>
                                            //         <Checkbox
                                            //             icon={icon}
                                            //             checkedIcon={checkedIcon}
                                            //             style={{ marginRight: 8 }}
                                            //             checked={selected}
                                            //         />
                                            //         {option.endowmentId + " . " + option.schoolName}
                                            //     </li>
                                            // )}
                                            style={{ fontSize: '14px' }}
                                            renderInput={(params) => (
                                                <TextField {...params} margin='dense' label="शाळा" variant="outlined"
                                                    required={(text18.length == 0) ? true : false}
                                                    InputLabelProps={{ style: { fontSize: 14 } }} />
                                            )}
                                        />

                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >रक्कम ( % ) :</label>
                                    <div className='col-sm-2'>
                                        <TextField
                                            required={(removeData.length == 0) ? true : false}
                                            fullWidth
                                            type="number"
                                            //style={{ width: "100px" }}
                                            InputProps={{ inputProps: { min: 1 } }}
                                            onWheel={(e) => e.target.blur()}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="रक्कम"
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            value={text22}
                                            onChange={(e) => setText22(e.target.value)}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>


                            </>
                        )
                    }
                })()}

                <div className="row my-2" hidden={(text2 == 2 || text2 == 3) ? true : false}>
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>इयत्ता :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo"
                            options={classData}
                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={text10}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                    setText5(newValue.id);
                                }
                                else {
                                    setText5(0);
                                }
                                setText10(newValue);

                            }}
                            inputValue={text10 || ''}
                            onInputChange={(event, newInputValue) => {
                                //console.log(newInputValue);
                                setText10(newInputValue || '');
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                    return (authUser.branchMedium == 1) ? (option.nameMr || '') : (option.nameEn || '')
                                }
                                // Regular option
                                return (authUser.branchMedium == 1) ? (option.nameMr || '') : (option.nameEn || '')
                            }

                            }
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="इयत्ता" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                            )}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >क्रमांक :</label>
                    <div className='col-sm-2'>
                        <Autocomplete
                            id="combo-box-demo"
                            options={meritData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            style={{ fontSize: '14px' }}
                            onBlur={() => {
                                onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22);
                            }}
                            value={text12}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                    setText6(newValue.id);
                                } else {
                                    setText6(0);
                                }
                                setText12(newValue);
                                setText14(['', '', '', '', ''].join(''));
                            }}
                            inputValue={text12 || ''}
                            onInputChange={(event, newInputValue) => {
                                // Handle empty string or null explicitly
                                setText12(newInputValue || '');
                            }}
                            getOptionLabel={option => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option && option.meritNo) {
                                    return option.meritNo;
                                }
                                return ''; // Return empty string if option or meritNo is not defined
                            }}
                            size="small"
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="क्रमांक"
                                    autoComplete="off"
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    required
                                />
                            )}
                        />

                    </div>

                    <label className='col-sm-2' hidden={(text12 <= 1 || text12 >= 6) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिसाची विभागणी ( % ) :</label>
                    {(() => {
                        if (text14 !== null) {
                            if (text12 == 2) {
                                return (
                                    <NoOfStudTextfield2
                                        initialVal1={result[0]}
                                        initialVal2={result[1]}
                                        text12={text12}
                                        onInputChange={onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22)}
                                        onChange={(value1, value2) => {
                                            let updated1 = result[0]
                                            updated1 = value1
                                            let updated2 = result[1]
                                            updated2 = value2
                                            setText14([updated1, updated2].join(','))
                                            console.log([updated1, updated2].join(','))
                                        }}
                                    />
                                )
                            }
                            else if (text12 == 3) {
                                return (
                                    <NoOfStudTextfield3
                                        initialVal1={result[0]}
                                        initialVal2={result[1]}
                                        initialVal3={result[2]}
                                        text12={text12}
                                        onInputChange={onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22)}
                                        onChange={(value1, value2, value3) => {
                                            let updated1 = result[0]
                                            updated1 = value1
                                            let updated2 = result[1]
                                            updated2 = value2
                                            let updated3 = result[2]
                                            updated3 = value3
                                            setText14([updated1, updated2, updated3].join(','))
                                            //console.log([updated1, updated2].join(','))
                                        }}
                                    />
                                )
                            }
                            else if (text12 == 4) {
                                return (
                                    <NoOfStudTextfield4
                                        initialVal1={result[0]}
                                        initialVal2={result[1]}
                                        initialVal3={result[2]}
                                        initialVal4={result[3]}
                                        text12={text12}
                                        onInputChange={onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22)}
                                        onChange={(value1, value2, value3, value4) => {
                                            let updated1 = result[0]
                                            updated1 = value1
                                            let updated2 = result[1]
                                            updated2 = value2
                                            let updated3 = result[2]
                                            updated3 = value3
                                            let updated4 = result[3]
                                            updated4 = value4
                                            setText14([updated1, updated2, updated3, updated4].join(','))
                                            //console.log([updated1, updated2].join(','))
                                        }}
                                    />
                                )
                            }
                            else if (text12 == 5) {
                                return (
                                    <NoOfStudTextfield5
                                        initialVal1={result[0]}
                                        initialVal2={result[1]}
                                        initialVal3={result[2]}
                                        initialVal4={result[3]}
                                        initialVal5={result[4]}
                                        text12={text12}
                                        onInputChange={onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22)}
                                        onChange={(value1, value2, value3, value4, value5) => {
                                            let updated1 = result[0]
                                            updated1 = value1
                                            let updated2 = result[1]
                                            updated2 = value2
                                            let updated3 = result[2]
                                            updated3 = value3
                                            let updated4 = result[3]
                                            updated4 = value4
                                            let updated5 = result[4]
                                            updated5 = value5
                                            setText14([updated1, updated2, updated3, updated4, updated5].join(','))
                                            //console.log([updated1, updated2].join(','))
                                        }} />
                                )
                            }
                        }

                    })()}
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >विषय प्रकार :</label>
                    <div className='col-sm-3'>
                        <FormControl fullWidth
                            margin="dense">
                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>विषय प्रकार</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                value={text13}
                                label="विषय प्रकार"
                                onChange={(e) => {
                                    setText13(e.target.value);
                                    getSubjectListById(e.target.value);
                                    setText11(null);
                                }}
                            >
                                <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Academic</MenuItem>
                                <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Non-Academic</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>विषय :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo"
                            options={(text13 == 1) ? subjectData : endowSubdata}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                            value={text11}
                            onChange={(event, newValue) => {
                                setText11(newValue);
                                if (newValue != null) {
                                    setText7(newValue.id);
                                }
                                else {
                                    setText7(0);
                                }
                            }}
                            inputValue={text11 || ''}
                            onInputChange={(event, newInputValue) => {
                                //console.log(newInputValue);
                                setText11(newInputValue || '');
                            }}
                            style={{ fontSize: '14px' }}
                            disabled={(text25 == 1) ? true : false}
                            getOptionLabel={option => {

                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                    return (authUser.branchMedium == 1) ? (option.nameMr || '') : (option.nameEn || '')
                                }
                                // Regular option
                                return (authUser.branchMedium == 1) ? (option.nameMr || '') : (option.nameEn || '')
                            }

                            }
                            size="small"
                            renderInput={params => (
                                <TextField {...params}

                                    margin='dense' label="विषय" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                            )}
                        />
                    </div>

                    <div className='col-sm-2 mt-2'>
                        <FormControlLabel control={<Checkbox checked={text25} onChange={HandleAllSubCheckbox} />} label="All Subjects" />
                    </div>
                </div>

                {/* Get the textboxes on No Of Students */}
                {/* <div className="row py-2 mb-4 mt-4 ml-1 mr-1" style={{ backgroundColor: "#FFE6E6", border: "1px solid #2C3E50", borderRadius: "2px" }}>
                    <div className='' style={{ width: "150px" }} hidden={(text2 == 2 || text2 == 3) ? true : false}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={classData}
                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18,text20,text21) }}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            // value={classObj}
                            // onChange={(event, newValue) => {
                            //     setClassObj(newValue);
                            // }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            renderInput={params => (
                                <TextField {...params}
                                    style={{ backgroundColor: "#F4F6F7" }}
                                    margin='dense' label="इयत्ता" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                //required={(percentageData.length == 0) ? true : false}
                                />
                            )}
                        />
                    </div>
                    <div className='' style={{ width: "160px" }}>
                        <FormControl fullWidth
                            margin="dense">
                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>विषय प्रकार</InputLabel>
                            <Select
                                //required={(percentageData.length == 0) ? true : false}
                                MenuProps={{ classes: { paper: classes.select } }}
                                onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18,text20,text21) }}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select1"
                                style={{ backgroundColor: "#F4F6F7" }}
                                label="विषय प्रकार"
                                sx={{
                                    '& #demo-simple-select1': {
                                        fontSize: '14px',
                                        fontWeight: "500"
                                    }
                                }}
                            // value={subjectType}
                            // onChange={(e) => {
                            //     setSubjectType(e.target.value);
                            //     setSubjectObj(null);
                            //     getSubjectListById(e.target.value);
                            // }}
                            >
                                <MenuItem value={1}>Academic</MenuItem>
                                <MenuItem value={2}>Non-Academic</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className='' style={{ width: "210px" }}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={subjectData}
                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18,text20,text21) }}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            // value={subjectObj}
                            // onChange={(event, newValue) => {
                            //     setSubjectObj(newValue);
                            // }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            size="small"
                            renderInput={params => (
                                <TextField {...params}
                                    style={{ backgroundColor: "#F4F6F7" }}
                                    margin='dense' label="विषय" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                //required={(percentageData.length == 0) ? true : false} 
                                />
                            )}
                        />
                    </div>

                    <div className='' style={{ width: "120px" }}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={meritData}
                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18,text20,text21) }}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            style={{ fontSize: '14px' }}
                            // value={meritObj}
                            // onChange={(event, newValue) => {
                            //     setMeritObj(newValue);
                            //     // setM1('');
                            //     // setM2('');
                            //     // setM3('');
                            //     // setM4('');
                            //     // setM5('');
                            // }}
                            getOptionLabel={option => (option.meritNo || '')}
                            size="small"
                            renderInput={params => (
                                <TextField {...params}
                                    style={{ backgroundColor: "#F4F6F7" }}
                                    margin='dense' label="क्रमांक" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                //required={(percentageData.length == 0) ? true : false} 
                                />
                            )}
                        />
                    </div>

                    <div className='' hidden={(text2 == 2 || text2 == 3) ? true : false} style={{ width: "180px" }}>
                        <FormControl fullWidth
                            margin="dense">
                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>लिंग</InputLabel>
                            <Select
                                //required={(percentageData.length == 0) ? true : false}
                                MenuProps={{ classes: { paper: classes.select } }}
                                onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18,text20,text21) }}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select2"
                                sx={{
                                    '& #demo-simple-select2': {
                                        fontSize: '14px',
                                        fontWeight: "500"
                                    }
                                }}
                                style={{ backgroundColor: "#F4F6F7" }}
                                // value={gender}
                                // onChange={(e) => setGender(e.target.value)}
                                label="लिंग"
                            >
                                <MenuItem value={1}>विद्यार्थी / विद्यार्थिनी</MenuItem>
                                <MenuItem value={2}>विद्यार्थिनी</MenuItem>
                                <MenuItem value={3}>विद्यार्थी</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="" style={{ width: "120px" }}>
                        <TextField
                            //required={(percentageData.length == 0) ? true : false}
                            fullWidth
                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18,text20,text21) }}
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="टक्केवारी"
                            style={{ backgroundColor: "#F4F6F7" }}
                            //style={{ width: "120px", marginLeft: "15px", backgroundColor: "#fff" }}
                            // value={m1}
                            // onChange={(e) => setM1(e.target.value)}
                            variant="outlined"
                            margin='dense'
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />
                    </div>

                    <div style={{ width: "130px" }} className="mt-2">
                        <button type='button' className="btn btn-sm mt-2 p-0"
                        // onClick={() => addPercentageData()}
                        // disabled={(subjectType == '' || subjectObj == null || classObj == null || gender == '' || m1 == '' || meritObj == null) ? true : false}
                        >
                            <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                        </button>
                        <button type='button' className={updateBtnPer}
                        //disabled={(subjectType == '' || subjectObj == null || classObj == null || gender == '' || m1 == '' || meritObj == null) ? true : false}
                        >
                            <CheckCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                        </button>
                        <button type="button" className='btn p-0 mt-2'
                        //onClick={() => clearPerSectionValues()}
                        >
                            <CancelIcon fontSize='medium' style={{ color: "#FA4A3F" }} />
                        </button>
                    </div>

                    <div className="table-responsive mt-4 mb-2" style={{ maxHeight: "300px" }}>
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th hidden={(text2 == 2 || text2 == 3) ? true : false} style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>इयत्ता</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>विषय प्रकार</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>विषय</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>क्रमांक</th>
                                    <th hidden={(text2 == 2 || text2 == 3) ? true : false} style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>लिंग</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>टक्केवारी</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", minWidth: "20px" }}>Edit</th>
                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    if (percentageData.length != 0) {
                                        return (
                                            percentageData.map((item, index) => {
                                                //console.log(item.validityDate)
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} hidden={(text2 == 2 || text2 == 3) ? true : false}>
                                                            {
                                                                classData.map(e => {
                                                                    return (e.id == item.classId) ? e.nameMr : ''
                                                                })
                                                            }
                                                        </td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                            {
                                                                (item.subjectCategory == 1)
                                                                    ?
                                                                    "Academic"
                                                                    :
                                                                    "Non-Academic"
                                                            }
                                                        </td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                            {
                                                                subjectData.map(e => {
                                                                    return (e.id == item.subjectId) ? e.nameMr : ''
                                                                })
                                                            }
                                                        </td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.noOfStudent}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} hidden={(text2 == 2 || text2 == 3) ? true : false}>
                                                            {(() => {
                                                                if (item.genderId == 1) {
                                                                    return "विद्यार्थी / विद्यार्थिनी"
                                                                }
                                                                else if (item.genderId == 2) {
                                                                    return "विद्यार्थिनी"
                                                                }
                                                                else {
                                                                    return "विद्यार्थी"
                                                                }
                                                            })()}
                                                        </td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.amountDivideInStudent}</td>
                                                        <td hidden={(EditFlag == 1) ? true : false}>
                                                            <Tooltip title="Edit">
                                                                <button type='button' className='btn btn-sm' onClick={() => getDataForUpdate(item)}>
                                                                    <EditIcon fontSize='medium' style={{ color: '#587FF8' }} />
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                        <td >
                                                            <Tooltip title="Delete">
                                                                <button type='button' className='btn btn-sm'>
                                                                    <DeleteIcon fontSize='medium' style={{ color: '#D73016' }} />
                                                                </button>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                })()}

                            </tbody>
                        </table>
                    </div>
                </div> */}

                {
                    (() => {
                        if (text1 == 2) {
                            return (
                                <div className="row my-2">
                                    <label className="col-sm-3" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>संस्था पातळीवरील बक्षिसाची माहिती:</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            required={(text1 == 2) ? true : false}
                                            fullWidth
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }}
                                            label="संस्था पातळीवरील बक्षिसाची माहिती"
                                            value={text15}
                                            onChange={(e) => setText15(e.target.value)}
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    })()
                }

                <div className="row my-2" hidden={(text2 == 2 || text2 == 3) ? true : false}>
                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >लिंग :</label>
                    <div className='col-sm-6 mt-2'>
                        <RadioGroup row name="customized-radios" value={text8} onBlur={() => { onChange(text1, text2, text3, text4, text5, text6, text7, text8, text13, text14, text15, text16, text17, text19, text18, text20, text21, text22) }} onChange={(e) => setText8(e.target.value)}>
                            <FormControlLabel value={1} control={<Radio color='primary' />} label="विद्यार्थी / विद्यार्थिनी" />
                            <FormControlLabel value={2} control={<Radio color='primary' />} label="विद्यार्थिनी" />
                            <FormControlLabel value={3} control={<Radio color='primary' />} label="विद्यार्थी" />
                        </RadioGroup>
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-sm-12 offset-sm-2">
                        <button type='button' className='btn btn-sm mb-3' onClick={() => { setPrizeId(initialText14); setEtran(initialText13) }}
                            data-bs-toggle="modal" data-bs-target="#conditionModal"
                            style={{ backgroundColor: "#F9543A", color: "#fff" }}>
                            {/* <DeleteIcon fontSize="medium" /> */}
                            Remove Condition</button>
                    </div>
                </div>
            </div>

            {/* <div className="modal fade" id="conditionModal" tabIndex="-1" aria-labelledby="conditionModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mt-3">
                            <h4 className="modal-title" id="conditionModalLabel">Are you sure you want to delete this condition?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" onClick={() => DeleteData()} className="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default EditConditionsTextBox